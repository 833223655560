import { isNumber } from 'lodash-es'

const currencyFormatter = Intl.NumberFormat(
    'en-GB',
    {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })

export function currency(valueInPounds?: number) {

    return isNumber(valueInPounds) ? currencyFormatter.format(valueInPounds).replace('.00', '') : undefined
}

export function currencyFromPence(valueInPence?: number) {
    return isNumber(valueInPence) ? currency(penceToPounds(valueInPence)) : undefined
}

export function poundsToPence(numberOfPounds?: number) {
    return isNumber(numberOfPounds) ? Math.round(numberOfPounds * 100) : undefined
}

export function penceToPounds(numberOfPence?: number) {
    return isNumber(numberOfPence) ? numberOfPence / 100 : undefined
}

export function asPoundsAndPence(valueInPence?: number) {
    const pounds = valueInPence ? Math.floor(valueInPence / 100) : undefined
    const pence = valueInPence ? (valueInPence - (pounds * 100)) : undefined
    return [pounds, pence]
}

export function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function stringToCurrencyNumber(value?: string) {
    if (value) {
        const digitsAndDecimalPointsOnly = value.replace(/[^0-9.]/g, '')
        const firstDecimalPointIndex = digitsAndDecimalPointsOnly.indexOf('.')
        
        let currencyStr = digitsAndDecimalPointsOnly
        if (firstDecimalPointIndex > 0) {
            const everythingBeforeTheFirstDecimal = digitsAndDecimalPointsOnly.substring(0, firstDecimalPointIndex)
            const everythingAfterThefirstDecimal = digitsAndDecimalPointsOnly.substring(firstDecimalPointIndex + 1)
            const digitsOnlyAfterTheFirstDecimal = everythingAfterThefirstDecimal.replace(/\./g, '')
            const truncatedDigitsOnlyAfterTheFirstDecimal = digitsOnlyAfterTheFirstDecimal.substring(0, 2) 
            currencyStr = `${everythingBeforeTheFirstDecimal}.${truncatedDigitsOnlyAfterTheFirstDecimal}`
        }

        const asNumber = Number(currencyStr)
        return isFinite(asNumber) ? asNumber : undefined
    }
}