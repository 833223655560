import styles from './Dashboard.module.css'
import {MainNav} from '../MainNav/MainNav'
import {Header} from '../Header/Header'
import {MainPane} from '../MainPane/MainPane'
import {IamModal} from '../../Iam/IamModal'
import {Show, createContext, createSignal, useContext} from 'solid-js'
import {useIamService} from '../../controllers/DashboardServiceController'


const NavContext = createContext<NavState>()
export const useNav = () => useContext(NavContext)

class NavState {
    private shownSignal = createSignal(true)
    readonly shown = this.shownSignal[0]
    private setShown = this.shownSignal[1]

    toggle() {
        this.setShown(prev => !prev)
    }
    
    hide() {
        this.setShown(false)
    }

}

export function Dashboard() {

    

    return (
        <div class={styles.Dashboard}>
            <NavContext.Provider value={new NavState()}>
                <DashboardContent/>    
            </NavContext.Provider>
        </div>
    )
}


function DashboardContent() {
    const iamService = useIamService()
    const nav = useNav()

    return <>
        <Header/>
        <Show when={iamService?.isInState('SignedIn')}>
            <Show when={nav.shown()}>
                <MainNav/>
            </Show>
            <MainPane/>
        </Show>
        <IamModal/>
    </>
}