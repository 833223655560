import {parseISO} from 'date-fns'
import {SerialisedAscentiAppointment, AscentiAppointment, AscentiInjury} from '@peachy/ascenti-client'
import {Optional} from '@peachy/utility-kit-pure'
import {
    RegisteredGp,
    Enquiry,
    QuestionOption,
    Question,
    EnquiryDefinitionId,
    GenderPreferenceOptionId,
    newInjuryOption,
    VideoOrPhoneOptionId,
    yesOption
} from '@peachy/repo-domain'
import {QuestionIds} from './types'
import {EnquiryDefinitionCommon} from './definition/EnquiryDefinitionCommon'


export interface ClaimActivityEnquiryReader {
    extractBenefitIdFrom(enquiry: Enquiry): string
    extractBenefitOptionTextFrom(enquiry: Enquiry): string
    extractTreatmentFrom(enquiry: Enquiry): string
    extractTreatmentReceiverLifeIdFrom(enquiry: Enquiry): string
    extractCostInPenceFrom(enquiry: Enquiry): Optional<number>
    extractTreatmentDateFrom(enquiry: Enquiry): Optional<Date>
    extractSymptomsOnsetDateFrom(enquiry: Enquiry): Optional<Date>
    getSubmitQuestionId(): string
}

export interface MakeClaimEnquiryReader extends ClaimActivityEnquiryReader {
    extractClaimingOnAnotherPolicyFrom(enquiry: Enquiry): boolean
    extractBankDetailsFrom(enquiry: Enquiry): { accountNumber: string, sortCode: string, name: string}
    extractPaymentReceiverNameFrom(question: Question): string
}

export class EnquiryReader {

    static forClaimActivity(enquiry: Enquiry): ClaimActivityEnquiryReader {
        return isClaimActivityEnquiry(enquiry.definition) ? claimActivityEnquiryReaders[enquiry.definition] : undefined
    }

    static forPhysioBooking() {
        return physioBookingEnquiryReader
    }

    static forVgpBooking() {
        return vgpBookingEnquiryReader
    }

    static async getWhoForIdLifeNameIfNotPrimaryLife(enquiry: Enquiry) {
        // bit ugly but we don't really care about the context of what type of enquiry because they all have different question ids so this will only return one match
        const whoForLifeId = enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-claiming-for'])?.id ??
            enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-checking-cover-for'])?.id ??
            enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-booking-for'])?.id ??
            enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-booking-physio-for'])?.id
        return whoForLifeId
    }
}

function isClaimActivityEnquiry(definition: EnquiryDefinitionId): definition is 'make-claim' | 'cover-check' {
    return ['make-claim', 'cover-check'].includes(definition)
}

const physioBookingEnquiryReader = {
    extractClinicIdFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<number>(QuestionIds['what-physio-clinic-id'])
    },

    extractTherapistIdFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<number>(QuestionIds['what-physio-therapist_id'])
    },

    extractGenderPreferenceFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-physio-gender-preference'])!.id as GenderPreferenceOptionId
    },

    extractChosenAppointmentFromQuestion(whatDateAndTimeQuestion?: Question): AscentiAppointment | undefined {
        const serialisedAppointment = whatDateAndTimeQuestion?.getFirstAnswer<QuestionOption>()?.getInMetadata<SerialisedAscentiAppointment>('appointment')
        // should move this to a common mapper
        return serialisedAppointment ? {...serialisedAppointment, DateTime: parseISO(serialisedAppointment.DateTime)} : undefined
    },

    extractChosenAppointmentFrom(enquiry: Enquiry): AscentiAppointment | undefined {
        return physioBookingEnquiryReader.extractChosenAppointmentFromQuestion(enquiry.getQuestion(QuestionIds['what-date-and-time-physio'])!)
    },

    extractWhoForIdFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-booking-physio-for'])?.id || enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-is-the-app-owner'])!.id
    },

    extractInjuryFrom(enquiry: Enquiry): AscentiInjury {
        return {
            IsNew: enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['new-or-existing-injury-physio'])!.id === newInjuryOption.id,
            Area: enquiry.getFirstAnswerTo<string>(QuestionIds['injury-location-physio'])!,
            AdditionalInfo: enquiry.getFirstAnswerTo<string>(QuestionIds['anything-else-physio'])!
        }
    }
}

const vgpBookingEnquiryReader = {
    extractAppointmentIdFromQuestion(whatDateAndTimeQuestion: Question) {
        return whatDateAndTimeQuestion.getFirstAnswer<QuestionOption>().id
    },

    extractAppointmentIdFrom(enquiry: Enquiry) {
        return vgpBookingEnquiryReader.extractAppointmentIdFromQuestion(enquiry.getQuestion(QuestionIds['what-date-and-time'])!)
    },

    extractGenderPreferenceFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-gp-gender-preference'])!.id as GenderPreferenceOptionId
    },

    extractContactMethodFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-gp-contact-method'])!.id as VideoOrPhoneOptionId
    },

    extractWhoForIdFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-booking-for'])?.id  || enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-is-the-app-owner'])!.id
    },

    extractNewRegisteredGpFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['new-registered-gp'])?.getInMetadata('nhsGp', RegisteredGp)
    },

    extractWhereWillYouBeFrom(enquiry: Enquiry) {
        const answer = enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['where-will-you-be'])
        const sessionToken = answer?.getInMetadata<string>('sessionToken')
        return answer ? {id: answer.id, sessionToken} : undefined
    }
}

const coverCheckEnquiryReader: ClaimActivityEnquiryReader = {
    extractBenefitIdFrom(enquiry: Enquiry): string {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-benefit-are-you-checking'])?.getInMetadata('benefitId')
    },

    extractBenefitOptionTextFrom(enquiry: Enquiry): string {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-benefit-are-you-checking'])?.text
    },

    extractTreatmentFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-type-of-therapist-do-you-need'])?.text ||
            enquiry.getFirstAnswerTo<string>(QuestionIds['what-other-mental-health-care-do-you-need']) ||
            enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-type-of-mental-health-care-do-you-need'])?.text ||
            enquiry.getFirstAnswerTo<string>(QuestionIds['what-care-do-you-need']) ||
            enquiry.getFirstAnswerTo<string>(QuestionIds['what-treatment-or-tests-do-you-need'])
    },

    extractTreatmentReceiverLifeIdFrom(enquiry: Enquiry) {
        //should try to refactor to see if we can we do this without asking whoIsTheAppOwner by defaulting the answer to whoAreYouCheckingCoverFor to the only option if there is only one?
        const coverBeingCheckedForLifeId = enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-checking-cover-for'])?.id
        return coverBeingCheckedForLifeId ?? enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-is-the-app-owner'])?.id
    },

    extractCostInPenceFrom(enquiry: Enquiry) {
        const cost = enquiry.getFirstAnswerTo<string>(QuestionIds['what-is-the-total-cost'])
        return cost ? Number(cost) * 100 : undefined
    },

    extractTreatmentDateFrom(enquiry: Enquiry): Optional<Date> {
        return undefined
    },

    getSubmitQuestionId() {
        return QuestionIds['thanks-all-done']
    },

    extractSymptomsOnsetDateFrom(enquiry: Enquiry) {
        const onsetDate = enquiry.getFirstAnswerTo<string>(QuestionIds['when-did-symptoms-start'])
        return onsetDate ? parseISO(onsetDate) : undefined
    }

}

const claimEnquiryReader: MakeClaimEnquiryReader = {
    extractBenefitIdFrom(enquiry: Enquiry): string {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-are-you-claiming-for'])?.getInMetadata('benefitId')
    },

    extractBenefitOptionTextFrom(enquiry: Enquiry): string {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-are-you-claiming-for'])?.text
    },

    extractTreatmentFrom(enquiry: Enquiry) {
        return enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['what-type-of-therapist-did-you-see'])?.text
    },

    extractTreatmentReceiverLifeIdFrom(enquiry: Enquiry) {
        const treatmentReceiverLifeId = enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-are-you-claiming-for'])?.id
        return treatmentReceiverLifeId ?? enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['who-is-the-app-owner'])?.id
    },

    extractCostInPenceFrom(enquiry: Enquiry): Optional<number> {
        return undefined
    },

    extractTreatmentDateFrom(enquiry: Enquiry): Optional<Date> {
        const treatmentDate = enquiry.getFirstAnswerTo<string>(QuestionIds['when-treated'])
        return treatmentDate ? parseISO(treatmentDate) : undefined
    },

    getSubmitQuestionId() {
        return QuestionIds['submit-claim']
    },

    extractClaimingOnAnotherPolicyFrom(enquiry: Enquiry) {
        return yesOption.id === enquiry.getFirstAnswerTo<QuestionOption>(QuestionIds['claiming-on-another-policy'])?.id
    },

    extractBankDetailsFrom(enquiry: Enquiry) {
        const question = enquiry.getQuestion(QuestionIds['collect-bank-account-details'])
        const answer = question.getFirstAnswer<{accountNumber: string, sortCode: string}>()
        return {
            accountNumber: answer.accountNumber,
            sortCode: answer.sortCode,
            name: this.extractPaymentReceiverNameFrom(question)
        }
    },

    extractPaymentReceiverNameFrom(question: Question) {
        return question.tags.filter(it => it.startsWith(EnquiryDefinitionCommon.accHolderNameTag))?.[0]?.substring(EnquiryDefinitionCommon.accHolderNameTag.length)
    },
    
    extractSymptomsOnsetDateFrom(enquiry: Enquiry) {
        return undefined
    }
    
}

const claimActivityEnquiryReaders: Pick<Record<EnquiryDefinitionId, ClaimActivityEnquiryReader>, 'make-claim' | 'cover-check'> = {
    'cover-check': coverCheckEnquiryReader,
    'make-claim': claimEnquiryReader,
}
