import {isFunction} from 'lodash-es'
import { Optional } from './utility-types'

export function groupBy<T, K>(a: T[], keyFunction: (e: T) => K): Map<K, T[]> {
    const map = new Map<K, T[]>()
    a.forEach((e) => {
        const key = keyFunction(e)
        let group = map.get(key)
        if (!group) {
            group = []
            map.set(key, group)
        }
        group.push(e)
    })
    return map
}

export function mapGroupBy<T, V, K extends string | number>(items: T[], groupAndMapFn: (item: T) => [key: K, value: V]) {
    return items.reduce((collector, item) => {
        const [key, value] = groupAndMapFn(item)
        collector[key] = collector[key] ?? []
        collector[key].push(value)
        return collector
    }, {} as Record<K, V[]>)
}

export function mapGroupDistinctBy<T, V, K extends string | number>(items: T[], groupAndMapFn: (item: T) => [key: K, value: V]) {
    return items.reduce((collector, item) => {
        const [key, value] = groupAndMapFn(item)
        collector[key] = value
        return collector
    }, {} as Record<K, V>)
}

export function groupDistinctBy<T, K extends string | number>(items: T[], groupFn: (item: T) => K) {
    return items.reduce((collector, item) => {
        const key = groupFn(item)
        collector[key] = item
        return collector
    }, {} as Record<K, T>)
}

export type NestByPathFn<T> = (item: T) => any[]
type ValueReducer<T> = (existing: T) => T

// not my proudest type!
type NestedMap<T, Depth extends number> = 
    Depth extends 1 ? Map<any, T> : 
    Depth extends 2 ? Map<any, NestedMap<T, 1>> :
    Depth extends 3 ? Map<any, NestedMap<T, 2>> :
    Depth extends 4 ? Map<any, NestedMap<T, 3>> :
    Depth extends 5 ? Map<any, NestedMap<T, 4>> :
    Map<any, any>

export function groupByNested<T, Depth extends number = any>(items: T[], nestByPathFn: NestByPathFn<T>) {
    const groupingReducer = (acc: T[], it: T) => [...acc, it]
    return groupReduceByNested<T, T[], Depth>(items, nestByPathFn, groupingReducer, [])
}

export function groupReduceByNested<T, R, Depth extends number = any>(items: T[], nestByPathFn: NestByPathFn<T>, reducer: (acc: Optional<R>, item: T) => R, init?: R): NestedMap<R, Depth> {
    return items.reduce((accumulator, it) => {
        const keyPath = nestByPathFn(it)
        putNested<R, Depth>(keyPath, existing => reducer(existing ?? init, it), accumulator)
        return accumulator
    }, new Map() as NestedMap<R, Depth>)
}

export function putNested<T, Depth extends number = any>(keys: any[], valueOrValueReducer: T | ValueReducer<T>, map: NestedMap<T, Depth> = new Map() as NestedMap<T, Depth>): NestedMap<T, Depth> {
    const keysExist = keys.length > 0
    const isLeaf = keys.length === 1
    const key = keys.shift() ?? 'undefined'
    if (keysExist) {
        const existingLeafValue = isLeaf && map.get(key) as T
        const leafValue = isLeaf && (isFunction(valueOrValueReducer) ? (valueOrValueReducer as ValueReducer<T>)(existingLeafValue) : valueOrValueReducer as T)
        map.set(key, isLeaf ? leafValue : putNested(keys, valueOrValueReducer, map.get(key) ?? new Map()))    
    }
    return map
}


export const mapBy = groupDistinctBy
