import {NavLink} from '@solidjs/router'
import {Show} from 'solid-js'
import styles from './IamLink.module.css'
import {useIamService} from '../../controllers/DashboardServiceController'

export type IamLinkProps = {
    path: string
    groups?: string | string[]
    children: any
}

export function IamLink(props: IamLinkProps) {

    const iamService = useIamService()

    let groups: string[]
    if (props.groups?.length) {
        groups = typeof props.groups === 'string' ? [props.groups]: props.groups
    }

    return (
        <Show when={!groups || iamService.isInUserGroups(...groups)}>
            <NavLink href={props.path} class={styles.IamLink} activeClass={styles.active}>
                {props.children}
            </NavLink>
        </Show>
    )
}


