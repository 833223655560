import { KeyMapped } from '../../some-types'
import { EmailData } from './EmailData'
import { Plan } from '../Plan'
import { ChangedLife } from '../../../event/models/ChangedLife'

export type SmeEmployerPolicyDocChangesEmailData = EmailData & {
    plans: KeyMapped<Plan>
    addedLives?: ChangedLife[]
    removedLives?: ChangedLife[]
}
