import {createContext, createResource, For, onMount, Show, useContext} from 'solid-js'
import {AiHarnessState, getRelevantQuestions} from './AiHarnessState'
import {useNav} from '../../page-components/Dashboard/Dashboard'
import {DomainMappings, Question, QuestionOption} from '@peachy/repo-domain'
import {AiHarnessContextProvider} from './AiHarnessContextProvider'
import {template} from 'lodash-es'
import styles from './AiHarness.module.css'
import {Flex} from '../../components/deprecated/Flex/Flex'
import {getId} from './TestData'
import {
    ClaimCheckResult,
    ClaimsCheckIds,
    ClaimsChecks,
    CoverCheckEvidence, DiagnosisTest, FollowUpQuestion, InferredValue,
    PatientProfile, PotentialDiagnosis, SupportingEvidence, TerminologyItemExtraction
} from '@peachy/claims-activity-pure'
import {ModalOpener, useModalControl} from '../../components/ModalOpener/ModalOpener'
import {Card} from '../../components/Card/Card'
import {useBean, useCurrentCustomerId} from '../../controllers/CustomerApplicationContextProvider'
import {groupBy, ukStyleDate, yearsSince} from '@peachy/utility-kit-pure'


const AiHarnessContext = createContext<AiHarnessState>()

function useAiApi() {
    return useContext(AiHarnessContext)
}

export function AiHarnessContextAware() {
    return <AiHarnessContextProvider>
        <AiHarness/>
    </AiHarnessContextProvider>
}

//TODO:
// - Fetch and render AI results (including checklist and profile modals)
// - Include results summary

//TODO: V1 limitations:
// - Only one referral/invoice per covercheck/claim
// - Fixed customer address for now
// - No ability to set assessed items or decisions for existing cover checks
// - Only saving to file for now - would be good to maintain an 'official' list of cases that can be loaded/saved

export function AiHarness() {
    useNav().hide()

    const store = new AiHarnessState()

    store.initValues()

    return (
        <AiHarnessContext.Provider value={store}>
            <div className={styles.Main}>
                <Flex row justifyContentSpaceBetween>
                    <Flex row alignItemsCenter>
                        <div className={styles.RadioInput}>
                            <input type={'radio'} name='mode' id='covercheck' value='covercheck' checked={!store.isClaim()}/>
                            <label onClick={() => {store.changePageMode('covercheck')}} htmlFor='covercheck'><span>Cover Check</span></label>
                        </div>
                        <div className={styles.RadioInput}>
                            <input type={'radio'} name='mode' id='claim' value='claim' checked={store.isClaim()}/>
                            <label onClick={() => {store.changePageMode('claim')}} htmlFor='claim'><span>Claim</span></label>
                        </div>
                    </Flex>
                    <Flex row alignItemsCenter>
                        <label style={{padding: '0.5em'}}><span>Name:</span></label>
                        <input style={{width: '10em'}} type={'text'} value={store.getCustomerName()} onChange={el => {store.changeCustomerName(el.currentTarget.value)}}/>
                        <label style={{padding: '0.5em'}}><span>Age:</span></label>
                        <input style={{width: '4em'}} type={'number'} value={store.getCustomerAge()} onInput={el => {store.changeCustomerAge(+el.currentTarget.value)}}/>
                        <label style={{padding: '0.5em'}}><span>Gender:</span></label>
                        <input style={{width: '6em'}} type={'text'} value={store.getCustomerGender()} onInput={el => {store.changeCustomerGender(el.currentTarget.value)}}/>
                    </Flex>
                    <Flex row alignItemsCenter>
                        <label style={{padding: '0.5em'}}><span>Filename:</span></label>
                        <input style={{width: '8em'}} type={'text'} value={store.getFilename()} onInput={el => {store.changeFilename(el.currentTarget.value)}}/>
                        <button onClick={() => store.downloadToFile()}>Save To File</button>
                    </Flex>
                </Flex>
                <br/>
                <Show when={!store.isClaim() && !!store.getCoverCheck()}>
                    <Flex row justifyContentCenter>
                        <div className={styles.Panel}>
                            <CoverCheck coverCheck={store.getCoverCheck()}/>
                        </div>
                        <div className={styles.Panel}>
                            <Results/>
                        </div>
                    </Flex>
                </Show>
                <Show when={store.isClaim()}>
                    <Flex row justifyContentCenter>
                        <div className={styles.Panel}>
                            <Flex row alignItemsStart>
                                <h5>Cover Checks</h5>
                                <i style={{padding: '0.2em'}} className={'fa-solid fa-square-plus fa-button'} onClick={() => {store.addCoverCheck()}}></i>
                            </Flex>
                            <Flex row justifyContentSpaceEvenly>
                                <For each={store.getClaimCoverChecks()}>
                                    {coverCheck => <CoverCheck coverCheck={coverCheck}/>}
                                </For>
                            </Flex>
                        </div>
                        <div className={styles.Panel}>
                            <h5>Claim</h5>
                            <Show when={!!store.getClaim()}>
                                <Claim/>
                            </Show>
                        </div>
                        <div className={styles.Panel}>
                            <Results/>
                        </div>
                    </Flex>
                </Show>
            </div>
        </AiHarnessContext.Provider>
    )
}

function CoverCheck({coverCheck}: {coverCheck: CoverCheckEvidence}) {
    const api = useAiApi()
    const getCoverCheck = () => api.isClaim() ? coverCheck : api.getCoverCheck()
    return <div className={styles.Enquiry}>
        <Flex row justifyContentSpaceBetween>
            <div>
                <Flex row alignItemsCenter>
                    <label><b>Date Submitted:</b></label>
                    <input style={{width: '10em'}} type={'date'} value={api.getCoverCheckSubmissionDate(coverCheck)} onInput={el => {api.setCoverCheckSubmissionDate(coverCheck, el.currentTarget.value)}}/>
                </Flex>
                <For each={getRelevantQuestions(getCoverCheck().enquiry)}>
                    {question => <CoverCheckQuestion coverCheck={coverCheck} question={question}/>}
                </For>
                <Show when={api.referralToHand(getCoverCheck())}>
                    <br/>
                    <label className={styles.Label}><b>Referral:</b>
                        <textarea cols={60} rows={10} value={api.getReferralText(coverCheck)} onInput={el => {api.setReferralText(coverCheck, el.currentTarget.value)}}></textarea>
                    </label>
                </Show>
            </div>
            <Show when={api.isClaim()}>
                <div>
                    <i className="fas fa-trash" onClick={() => {api.deleteCoverCheck(coverCheck)}}/>
                </div>
            </Show>
        </Flex>
    </div>
}

function Claim(){
    const api = useAiApi()
    return <div className={styles.Enquiry}>
        <Flex row alignItemsCenter>
            <label><b>Date Submitted:</b></label>
            <input style={{width: '10em'}} type={'date'} value={api.getClaimSubmissionDate()} onInput={el => {api.setClaimSubmissionDate(el.currentTarget.value)}}/>
        </Flex>
        <For each={getRelevantQuestions(api.getClaim().enquiry)}>
            {question => <ClaimQuestion question={question}/>}
        </For>
        <br/>
        <label className={styles.Label}><b>Invoice:</b>
            <Invoice/>
        </label>
    </div>
}

function CoverCheckQuestion({coverCheck, question}: {coverCheck: CoverCheckEvidence, question: Question}) {
    const api = useAiApi()
    const questionId = getQuestionId(coverCheck, question)
    return <div>
        <p>{template(question.text, {imports: question.dataModel})}</p>
        {question.type == 'DATE_DAY_MONTH_YEAR' && <input type={'date'} name={questionId} value={question.answers[0]} onInput={el => {api.setCoverCheckAnswer(coverCheck, question.id, el.currentTarget.value)}}></input>}
        {(question.type == 'FREETEXT' || question.type == 'NUMERIC') && <input type={'text'} name={questionId} value={question.answers[0]} onInput={el => {api.setCoverCheckAnswer(coverCheck, question.id, el.currentTarget.value)}}></input>}
        {question.type == 'OPTION' && <For each={question.optionsDefinition?.embeddedOptions}>
            {option =>
                <div className={styles.RadioInput}>
                    <input type={'radio'} name={questionId} id={getOptionId(questionId, option)} value={option.id} checked={question.answers[0] == option}/>
                    <label onClick={() => {api.setCoverCheckAnswer(coverCheck, question.id, option)}} for={getOptionId(questionId, option)}><span>{option.text}</span></label>
                </div>
            }
        </For>}
    </div>
}

function ClaimQuestion({question}: {question: Question}) {
    const api = useAiApi()
    return <div>
        <p>{template(question.text, {imports: question.dataModel})}</p>
        {question.type == 'DATE_DAY_MONTH_YEAR' && <input type={'date'} name={question.id} value={question.answers[0]} onInput={el => {api.setClaimAnswer(question.id, el.currentTarget.value)}}></input>}
        {(question.type == 'FREETEXT' || question.type == 'NUMERIC') && <input type={'text'} name={question.id} value={question.answers[0]} onInput={el => {api.setClaimAnswer(question.id, el.currentTarget.value)}}></input>}
        {(question.type == 'VIDEO') && <input type={'text'} name={question.id} value={api.getVideoAnswer(question.id)} onInput={el => {api.setVideoAnswer(question.id, el.currentTarget.value)}}></input>}
        {question.type == 'OPTION' && <For each={question.optionsDefinition?.embeddedOptions}>
            {option =>
                <div className={styles.RadioInput}>
                    <input type={'radio'} name={question.id} id={getOptionId(question.id, option)} value={option.id} checked={question.answers[0] == option}/>
                    <label onClick={() => {api.setClaimAnswer(question.id, option)}} for={getOptionId(question.id, option)}><span>{option.text}</span></label>
                </div>
            }
        </For>}
    </div>
}

function Invoice() {
    const api = useAiApi()
    return <div>
        <table className={styles.Table}>
            <thead>
                <tr>
                    <th>Item <i style={{padding: '0.2em'}} className={'fa-solid fa-square-plus fa-button'} onClick={() => {api.addInvoiceLineItemRow()}}></i></th>
                    <th>Qty</th>
                    <th>Unit</th>
                    <th>Total</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <For each={api.getInvoiceLineItems()}>
                    {(lineItem, i) => <tr>
                        <td><LineItemInput lineItem={lineItem} index={i()} fieldName={'ITEM'} width={10}/></td>
                        <td><LineItemInput lineItem={lineItem} index={i()} fieldName={'QUANTITY'} width={3}/></td>
                        <td><LineItemInput lineItem={lineItem} index={i()} fieldName={'UNIT_PRICE'} width={3}/></td>
                        <td><LineItemInput lineItem={lineItem} index={i()} fieldName={'PRICE'} width={3}/></td>
                        <td><i className="fas fa-trash" onClick={() => {api.removeInvoiceLineItemRow(i())}}/></td>
                    </tr>}
                </For>
            </tbody>
        </table>
        <InvoiceField name={'TOTAL'} label={'Total'}/>
        <InvoiceField name={'INVOICE_RECEIPT_DATE'} label={'Date'}/>
        <InvoiceField name={'RECEIVER_NAME'} label={'Cust Name'}/>
        <InvoiceField name={'RECEIVER_ADDRESS'} label={'Cust Address'}/>
        <InvoiceField name={'VENDOR_NAME'} label={'Comp Name'}/>
        <InvoiceField name={'VENDOR_ADDRESS'} label={'Comp Address'}/>
        <InvoiceField name={'ZIP_CODE'} label={'Postcode'}/>
        <InvoiceField name={'FREE_TEXT'} label={'Free Text'}/>
    </div>
}

function Results() {
    const api = useAiApi()
    return <div>
        <p><b>Expected Outcome:</b></p>
        <label className={styles.Label}>
            <textarea style={{width: '50em', height: '8em'}} value={api.getExpectedOutcome()} onInput={el => api.setExpectedOutcome(el.currentTarget.value)} placeholder={'What is the expected outcome of the test...'}/>
        </label>
        <br/>
        <br/>
        <p><b>Results:</b></p>
        <Flex row justifyContentSpaceBetween>
            <Flex row justifyContentEnd>
                <button disabled={api.isLoading()} onClick={() => api.getNewResults()}>{api.isLoading() ? 'Loading...' : 'Get Results'}</button>
                <Show when={api.getResults()}>
                    <button onClick={() => api.clearResults()}>Clear Results</button>
                </Show>
            </Flex>
            <Show when={api.getResults()}>
                <Flex row justifyContentEnd>
                    <ModalOpener trigger={<button style={{"background-color": 'var(--peachy-purple)'}}>Checklist</button>}>
                        <Card>
                            <ChecklistModal/>
                        </Card>
                    </ModalOpener>
                    <Show when={!!api.getResults().patientProfile}>
                        <ModalOpener trigger={<button style={{"background-color": 'var(--peachy-purple)'}}>Patient Profile</button>}>
                            <Card>
                                <PatientProfileModal/>
                            </Card>
                        </ModalOpener>
                    </Show>
                </Flex>
            </Show>
        </Flex>
    </div>
}

function LineItemInput({lineItem, index, fieldName, width}: {lineItem: any, index: number, fieldName: string, width: number}) {
    const api = useAiApi()
    return <input style={{width: `${width}em`}} name={fieldName} value={lineItem[fieldName]} onChange={el => api.setInvoiceLineItemValue(index, fieldName, el.currentTarget.value)}/>
}

function InvoiceField({name, label}: {name: string, label: string}) {
    const api = useAiApi()
    return <Flex row justifyContentSpaceBetween>
        <label style={{width: '20em'}}><span>{label}</span></label>
        <input type={'text'} name={name} value={api.getInvoiceValue(name)} onInput={el => {api.setInvoiceValue(name, el.currentTarget.value)}}></input>
    </Flex>
}

function getQuestionId(coverCheck: CoverCheckEvidence, question: Question) {
    return `${getId(coverCheck)}-${question.id}`
}

function getOptionId(questionId: string, option: QuestionOption) {
    return `${questionId}-${option.id}`
}





function ChecklistModal() {
    const api = useAiApi()
    const orderedCheckResults = () => Object.values(ClaimsCheckIds)
        .map(id => ({...api.results().checks[id], id}))
        .filter(result => !!result.status && result.status != 'N/A')
    const decisionColour = (result) => result.status == 'PASS' ? 'var(--peachy-green)' : result.status == 'WARN' ? 'var(--warning)' : 'var(--peachy-pink)'
    const decisionClass = (result) => result.status == 'PASS' ? 'fa-solid fa-circle-check' : result.status == 'WARN' ? 'fa-solid fa-triangle-exclamation' : 'fa-solid fa-square-xmark'
    const inferredCoverCheckIds = () => api.results().inferredValues.inferredLinkedClaimActivityIds ?? []
    const inferredPaymentDate = () => api.results().inferredValues.inferredInvoiceLineItems?.find(it => !!it.treatmentPaymentDate)?.treatmentPaymentDate
    const inferredOnsetDate = () => api.results().inferredValues.inferredSubmissionReasons?.find(it => !!it.onsetDate)?.onsetDate
    const inferredTreatmentLocation = () => api.results().inferredValues.inferredInvoiceLineItems?.find(it => !!it.treatmentAddress)?.treatmentAddress
    const inferredSymptoms = () => api.results().inferredValues.inferredSubmissionReasons?.flatMap(it => it.symptoms) ?? []
    const inferredDisorders = () => api.results().inferredValues.inferredSubmissionReasons?.map(it => it.disorder).filter(it => !!it) ?? []
    const inferredTreatments = () => !!api.results().inferredValues.inferredInvoiceLineItems ?
        api.results().inferredValues.inferredInvoiceLineItems?.map(it => it.procedure) ?? [] :
        api.results().inferredValues.inferredRequestedTreatments?.map(it => it.procedure) ?? []
    return <div>
        <table>
            <tbody>
            <For each={orderedCheckResults()}>{(result: ClaimCheckResult) =>
                <tr>
                    <td>
                        <ModalOpener trigger={<i style={{color: decisionColour(result), 'font-size': '30px', padding: '0.1em'}} className={decisionClass(result)}></i>}>
                            <Card style={{'max-width': '100ch', 'padding': '2em'}}>
                                <h3 style={{color: decisionColour(result)}}>Check {result.status == 'PASS' ? 'passed' : result.status == 'FAIL' ? 'failed' : 'warning'}</h3>
                                {result.message.split('\n').map(it => <p>{it}</p>)}
                                {result.supportingEvidence && <div style={{'border-top': '1px solid black'}}/>}
                                <SupportingEvidenceSection supportingEvidence={result.supportingEvidence}/>
                            </Card>
                        </ModalOpener>
                    </td>
                    <td style={{'padding': '0.5rem'}}>
                        <b>{ClaimsChecks[result.id]}</b>
                    </td>
                </tr>
            }
            </For>
            </tbody>
        </table>
        <div>
            <h3>Inferred Values</h3>
            {inferredCoverCheckIds().length && <p><b>Linked Cover Checks:</b> {inferredCoverCheckIds().map((it, i) => [i > 0 && ", ", DomainMappings.peachifyUuid(it.value)])}</p>}
            {inferredPaymentDate() && <p><b>Payment Date:</b> <InferredValueLink inferredValue={inferredPaymentDate()} text={ukStyleDate(new Date(inferredPaymentDate().value))}/></p>}
            {inferredOnsetDate() && <p><b>Symptom Onset Date:</b> <InferredValueLink inferredValue={inferredOnsetDate()} text={ukStyleDate(new Date(inferredOnsetDate().value))}/></p>}
            {inferredTreatmentLocation() && <p><b>Treatment Location:</b> <InferredValueLink inferredValue={inferredTreatmentLocation()} text={inferredTreatmentLocation().value.postcode}/></p>}
            {inferredSymptoms().length && <p><b>Symptoms:</b> {inferredSymptoms().map((snomed, i) => [i > 0 && ", ", <InferredSnomedLink snomed={snomed}/>])}</p>}
            {inferredDisorders().length && <p><b>Disorders:</b> {inferredDisorders().map((snomed, i) => [i > 0 && ", ", <InferredSnomedLink snomed={snomed}/>])}</p>}
            {inferredTreatments().length && <p><b>Treatments:</b> {inferredTreatments().map((snomed, i) => [i > 0 && ", ", <InferredSnomedLink snomed={snomed}/>])}</p>}
        </div>
    </div>
}

function PatientProfileModal() {
    const api = useAiApi()
    const patientProfile = () => api.getResults().patientProfile
    const groupedNotes = groupBy(patientProfile().medicalNotes.sort((a, b) => a.date.localeCompare(b.date)), note => note.date)
    return <div>
        <h3>Patient Profile</h3>
        <div style={{'max-width': '100em', 'font-size': '12pt'}}>
            <br/>
            <p><b>Name:</b> {patientProfile().name}</p>
            <p><b>DOB:</b> {ukStyleDate(new Date(patientProfile().dob))} (age: {yearsSince(new Date(patientProfile().dob))})</p>
            <p><b>Gender:</b> {patientProfile().gender}</p>
            <br/>
            <p><b>Medical Notes:</b></p>
            <table style={{'font-family': 'Courier'}}>
                <tbody>
                <For each={Array.from(groupedNotes.keys())}>{(date) =>
                    <tr>
                        <td style={{width: '8em'}}>{date}</td>
                        <td>{groupedNotes.get(date).map(note => <p>{note.note}</p>)}</td>
                    </tr>
                }
                </For>
                </tbody>
            </table>
            <br/>
            <p><b>Potential Diagnosis:</b></p>
            <For each={patientProfile().potentialDiagnoses}>{(potentialDiagnosis: PotentialDiagnosis) =>
                <p><PotentialDiagnosisLink potentialDiagnosis={potentialDiagnosis}/> (confidence: {potentialDiagnosis.confidence})</p>
            }
            </For>
            <br/>
            <p><b>Follow-up Questions:</b></p>
            <For each={patientProfile().followUpQuestions}>{(followUpQuestion: FollowUpQuestion) =>
                <div>
                    <p>{followUpQuestion.question}</p>
                    <p style={{color: 'grey', 'font-style': 'Italic'}}> - {followUpQuestion.reason}</p>
                </div>
            }
            </For>
        </div>
    </div>
}

function PotentialDiagnosisLink({potentialDiagnosis}: {potentialDiagnosis: PotentialDiagnosis}) {
    const pink = {color: 'var(--peachy-pink)'}
    return <ModalOpener trigger={<span style={pink}>{potentialDiagnosis.name}</span>}>
        <Card style={{"max-width": '100ch', 'padding': '2em'}}>
            <h3>{potentialDiagnosis.name}</h3>
            <p><b>Snomed:</b> <InferredSnomedLink snomed={{value: potentialDiagnosis.snomed}}/></p>
            <p><b>Description:</b> {potentialDiagnosis.description}</p>
            <p><b>Differentials:</b> {potentialDiagnosis.differentials}</p>
            <p><b>Demographics:</b> {potentialDiagnosis.demographics}</p>
            <p><b>Symptoms:</b> {potentialDiagnosis.symptoms}</p>
            <p><b>Indicators:</b> {potentialDiagnosis.indicators}</p>
            <p><b>Contraindicators:</b> {potentialDiagnosis.contraindicators}</p>
            <p><b>Prognosis:</b> {potentialDiagnosis.prognosis}</p>
            <p><b>Treatments:</b> {potentialDiagnosis.treatments}</p>
            <p><b>Tests:</b></p>
            <For each={potentialDiagnosis.tests}>{(test: DiagnosisTest) =>
                <p><InferredSnomedLink snomed={{value: test.snomed}}/> <b>£{test.priceRange.low} - £{test.priceRange.high}</b> ({test.reason})</p>
            }
            </For>
            <p><b>Confidence:</b> {potentialDiagnosis.confidence}</p>
        </Card>
    </ModalOpener>
}

function InferredValueLink({inferredValue, text}: {inferredValue: InferredValue<any>, text: string}) {
    return <Show when={inferredValue.evidence?.length} fallback={<span>{text}</span>}>
        <ModalOpener trigger={<span style={{color: 'var(--peachy-pink)'}}>{text}</span>}>
            <Card style={{"max-width": '100ch', 'padding': '2em'}}>
                <SupportingEvidenceSection supportingEvidence={inferredValue.evidence}/>
            </Card>
        </ModalOpener>
    </Show>
}

function InferredSnomedLink({snomed}: {snomed: InferredValue<TerminologyItemExtraction>}) {
    const pink = {color: 'var(--peachy-pink)'}
    return <ModalOpener trigger={<span style={pink}>{snomed.value.display}</span>}>
        <Card style={{"max-width": '100ch', 'padding': '2em'}}>
            <h3>Snomed Concept</h3>
            <p><b>Name:</b> {snomed.value.display}</p>
            <p><b>Code:</b> {snomed.value.code}</p>
            <p><b>Type:</b> {snomed.value.snomedType.toUpperCase()}</p>
            <div style={{'border-top': '1px solid black'}}/>
            <SupportingEvidenceSection supportingEvidence={snomed.evidence}/>
        </Card>
    </ModalOpener>
}

function SupportingEvidenceSection({supportingEvidence}: {supportingEvidence: SupportingEvidence[]}) {
    return <Show when={supportingEvidence?.length}>
        <div>
            <p><b>SUPPORTING EVIDENCE</b></p>
        </div>
        <For each={supportingEvidence}>{(evidence: SupportingEvidence) =>
            <p>Customer <b>{evidence.description}</b> {evidence.type == 'image' ? 'showed' : 'included'} "{evidence.text}"</p>
        }</For>
    </Show>
}
