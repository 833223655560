
import { Life, Policy, toClass } from '@peachy/core-domain-pure'
import { currencyFromPence, displayableId, ukStyleDate } from '@peachy/utility-kit-pure'
import { Link } from '@solidjs/router'
import { Component, createMemo, createSignal, For, Show } from 'solid-js'
import { getSubscriptionURL } from '../../../service/subscription/SubscriptionService'
import { DetailsField } from './DetailsField'
import { getMemberId, Members } from './Members'
import { SubscriptionProvider, useAccountSubscription } from './SubscriptionProvider'
import styles from './CancelSubscription.module.css'
import { CancelPolicyModal } from './CancelModal/CancelPolicyModal'
import { createToggleSignal } from '@peachy/client-kit'

const CancelPolicy = () => {
    return (
        <SubscriptionProvider>
            <Show when={useAccountSubscription()}>
                <CancelPolicyPage />
            </Show>
        </SubscriptionProvider>
    )
}

const CancelPolicyPage = () => {
    const accountSub = useAccountSubscription()
    const policy = accountSub.subscription.policies.find(p => p.lives[getMemberId()])

    const [isOpen, toggleIsOpen] = createToggleSignal(false)
    
    return (
        <Show when={policy} fallback={<h3>No policy found for life</h3>}>
            <div class={styles.container}>
                <h3>Policy:</h3>
                <PolicyDetails policy={policy} />
                
                <button onClick={toggleIsOpen}>Cancel policy/member</button>
                <CancelPolicyModal isOpen={isOpen()} onDismiss={toggleIsOpen}/>
                
                <Benefits policy={policy} />
                <h3>Other family members:</h3>
                <Members />
            </div>
        </Show>
    )
}

export const PolicyDetails: Component<{ policy: Policy }> = (props) => {
    const accountSub = useAccountSubscription()
    const account = accountSub.account
    const subId = accountSub.subscription.id
    const policy = props.policy

    const life = createMemo(() => {
        return toClass(policy.lives[getMemberId()], Life)
    })

    return <>
        <div class={styles.details}>
            <DetailsField key='Name' value={life().fullName()} />
            <DetailsField key='Ref' value={displayableId(policy.id)} />
            <DetailsField key='Start Date' value={ukStyleDate(new Date(policy?.startDate))} />
            <DetailsField key='Status' value={policy.status} />
            <DetailsField key='Customer type' value={account.type} />
            <DetailsField key='Member type' value={life().type} />
            <DetailsField key='Email' value={life().email} />
            <DetailsField key='Postcode' value={life().address.postcode} />
            <DetailsField key='DoB' value={ukStyleDate(new Date(life().dateOfBirth))} />
        </div>
        <p>This member is part of <Link href={getSubscriptionURL(account.id, subId)}>{accountSub.subscription.contactName}'s</Link> plan</p>
    </>
}

const Benefits: Component<{ policy: Policy }> = (props) => {
    const life = props.policy.lives[getMemberId()]

    return (
        <table class={styles.table}>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Start date</th>
                    <th>Premium</th>
                </tr>
            </thead>
            <tbody>
                <For each={life.benefits}>
                    {(benefit) => <tr>
                        <td>{benefit.type}</td>
                        <td>{ukStyleDate(new Date(benefit.startDate))}</td>
                        <td>{currencyFromPence(benefit.premium.total)}</td>
                    </tr>}
                </For>
            </tbody>
        </table >
    )
}

export default CancelPolicy