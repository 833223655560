import {Router} from '@solidjs/router'
import {Dashboard} from './page-components/Dashboard/Dashboard'
import {InfraController} from './controllers/InfraController'
import {DashboardServiceController} from './controllers/DashboardServiceController'
import {DashboardServiceController2} from './controllers/DashboardServiceController2'
import {KeyboardController} from '@peachy/client-kit'

export function App() {
    return (
        <Router>
            <InfraController>
                    <DashboardServiceController>
                        <DashboardServiceController2>
                            <KeyboardController>
                                <Dashboard/>
                            </KeyboardController>
                        </DashboardServiceController2>
                    </DashboardServiceController>
            </InfraController>
        </Router>
    )
}
