import {createResource, Show, For} from 'solid-js'
import {useBean, useCurrentCustomerId} from '../../controllers/CustomerApplicationContextProvider'
import {prettyPrintBenefit, Plan, PlanYear} from '@peachy/repo-domain'
import {Card} from '../../components/Card/Card'
import {Address} from '../../components/business/Address/Address'
import {currencyFromPence, isEmpty, readableIsoDatePartOnlyVerbose, ukStyleDate} from '@peachy/utility-kit-pure'
import {format} from 'date-fns'
import {ClaimActivityTable} from './Claim/ClaimActivityTable'

export function CustomerDashboard() {

    const policyService = useBean('policyService')
    const claimsSearchService = useBean('claimsSearchService')
    const peachyClient = useBean('peachyClient')

    const [claims] = createResource(() => claimsSearchService.listAllSubmittedClaimsActivities())
    const [policy] = createResource(() => policyService.getPolicy())

    const [intercomUri] = createResource(useCurrentCustomerId, async sub => peachyClient.getThirdPartyUriFor(sub, 'intercomContact'))
    const [stripeUri] = createResource(useCurrentCustomerId, async sub => peachyClient.getThirdPartyUriFor(sub, 'stripeCustomer'))
    const primaryLife = () => policy().primaryLife
    const plan = () => policy().primaryLifePlan


    return (
        <Show when={!policy.loading && !claims.loading}>
            <Card>
                <div style={{"min-width": "100ch"}}>
                    <h2>
                        {primaryLife().fullName} 
                        <Show when={primaryLife().isCancelled}>
                            <span style={{color: 'red'}}> CANCELLED: {ukStyleDate(primaryLife().dateCancelled)}</span>
                        </Show>
                    </h2>
                    <h3>Policy {policy().longReferenceNumber}</h3>
                    <p style={{"font-size": "small"}}>{policy().id}</p>
                    <p>
                        <Show when={!stripeUri.loading}><a href={stripeUri().uri}>Stripe</a></Show>
                        <Show when={!stripeUri.loading && !intercomUri.loading}> | </Show>
                        <Show when={!intercomUri.loading}><a href={intercomUri().uri}>Intercom</a></Show>
                    </p>
                    
                    <br/>
                    <Address address={primaryLife().address}/>

                    <ul>
                        <li><strong>DoB:</strong>&nbsp;&nbsp;{readableIsoDatePartOnlyVerbose(primaryLife().dateOfBirth)}</li>
                        <li><strong>Email:</strong>&nbsp;&nbsp;{primaryLife().email}</li>
                        <li><strong>Phone:</strong>&nbsp;&nbsp;{primaryLife().phoneNumber}</li>
                    </ul>

                    <PlanDetails plan={plan()}/>

                    <h3>Claim Activity</h3>
                    <ClaimActivityTable claims={claims().filter(it => it.treatmentReceiver.is(primaryLife()))} customerAwsSub={primaryLife().awsSub}/>
                    
                    <Show when={!isEmpty(policy().dependentLives)}>
                        <hr/>
                        <br/>
                        <h2>Dependents</h2>
                        <For each={policy().dependentLives}>{ dependent => <>
                            <br/>
                            <h3>{dependent.fullName}</h3>
                            <p><strong>DoB:</strong>&nbsp;&nbsp;{readableIsoDatePartOnlyVerbose(dependent.dateOfBirth)}</p>

                            <PlanDetails plan={policy().getPlanByLife(dependent)}/>

                            <h3>Claim Activity</h3>
                            <ClaimActivityTable claims={claims().filter(it => it.treatmentReceiver.is(dependent))} customerAwsSub={primaryLife().awsSub}/>
                            <hr/>
                        </>}</For>
                    </Show>
                </div>

            </Card>

        </Show>
    )
}

function PlanDetails({plan}: {plan: Plan}) {
    return <div>
            <br/>
            <h3>Plan Details</h3>
            <p>Since: {ukStyleDate(plan.startDate)}</p>
            <Show when={plan.life.isCancelled}>
                <p style={{color: 'red'}}>CANCELLED: {ukStyleDate(plan.life.dateCancelled)}</p>
            </Show>
            <For each={plan.planYearsLatestFirst}>{ planYear => 
                <div>
                    <br/>
                    <strong style={{'text-decoration': 'underline'}}>Plan year {readablePlanYear(planYear)}</strong>
                    <table style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th>Benefit</th>
                                <th>Since</th>
                                <th>Cancelled</th>
                                <th>Usage</th>
                                <th>Limit</th>
                                <th>Remaining</th>
                            </tr>
                        </thead>
                        <tbody>
                            <For each={planYear.benefits}>{ benefit =>
                                <tr>
                                    <td>{prettyPrintBenefit(benefit)}</td>
                                    <td>{ukStyleDate(benefit.startDate)}</td>
                                    <td>{ukStyleDate(benefit.endDate)}</td>
                                    <td>{currencyFromPence(benefit.coverUsedInPence)}</td>
                                    <td>{currencyFromPence(benefit.coverAmountInPence)}</td>
                                    <td>{currencyFromPence(benefit.coverRemainingInPence)}</td>
                                </tr>
                            }</For>
                        </tbody>
                    </table>
                    <Show when={planYear.excess}>
                        <br/>
                        <table style={{width: "100%"}}>
                            <thead>
                                <tr>
                                    <th>Excess</th>
                                    <th>Usage</th>
                                    <th>Limit</th>
                                    <th>Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <For each={planYear.excess.benefitTypes}>{ benefit =>
                                            <p>{prettyPrintBenefit(benefit)}</p>
                                        }</For>
                                    </td>
                                    <td style={{'vertical-align': 'middle'}}>{currencyFromPence(planYear.excess.usageInPence)}</td>
                                    <td style={{'vertical-align': 'middle'}}>{currencyFromPence(planYear.excess.amountInPence)}</td>
                                    <td style={{'vertical-align': 'middle'}}>{currencyFromPence(planYear.excess.amountOutstandingInPence)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Show>
                    <hr/>
                    <br/>
                </div>
            }</For>
            <br/>
    </div>
}

function readablePlanYear(planYear: PlanYear) {
    return `${format(planYear.start, 'dd MMM yyyy/')}${format(planYear.end, 'yy')}`
}