import {enumerate} from '@peachy/utility-kit-pure'
import {KeyMap, keysOf} from './type-kit'
import {defineApi, getOf, postOf} from '@peachy/core-domain-pure'

export const DashboardUserGroups = [
    'AdminUsers',
    'InsurerUsers'
] as const

export type DashboardUserGroup = typeof DashboardUserGroups[number]


export const UserAdminApiName = 'UserAdminApi'

export const AdminUserGroup = 'AdminUsers'
export const InsurerUserGroup = 'InsurerUsers'

export const DashboardGroups = [AdminUserGroup, InsurerUserGroup] as const


export type UserAdminApi = {
    newDashUserPassword: (email: string, password?: string) => Promise<void>
    newCustomerPassword: (email: string, password?: string) => Promise<void>
}

export type UserAdminAction = keyof UserAdminApi
export const UserAdminActions = keysOf<UserAdminApi>()

export type UserAdminRoutes = KeyMap<UserAdminApi, string>

class GetThirdPartyUriRequestParams {
    sub: string
    endpoint: string
    modelPairs?: string
}
export class GetThirdPartyUriResponse {
    uri: string
}

class NewPasswordRequestBody {
    email: string
    password?: string
}

export const UserAdminApiDefinition = defineApi(UserAdminApiName)
    .forGroups(DashboardGroups)
    .withActions({
        newDashUserPassword: postOf(NewPasswordRequestBody).fromGroups(AdminUserGroup).willReturn(Object), //todo how to state it returns void?
        newCustomerPassword: postOf(NewPasswordRequestBody).fromGroups(AdminUserGroup).willReturn(Object), //todo how to state it returns void?
        getThirdPartyUri: getOf(GetThirdPartyUriRequestParams).fromGroups(AdminUserGroup).willReturn(GetThirdPartyUriResponse)
    })

export const CognitoUserAdminActions = enumerate([
    'ListUsers',
    'AdminCreateUser',
    'AdminGetUser',
    'AdminEnableUser',
    'AdminDisableUser',
    'AdminDeleteUser',
    'AdminUpdateUserAttributes',
    'AdminSetUserPassword'
] as const)

export type CognitoUserAdminAction = keyof typeof CognitoUserAdminActions
