import { Route } from '@solidjs/router'
import {Component, Show} from 'solid-js'
import {useIamService} from '../../controllers/DashboardServiceController'

export type IamRouteProps = {
    path: string
    groups?: string | string[]
    component: Component
}

export function IamRoute(props: IamRouteProps) {
    const iamService = useIamService()

    let groups: string[]
    if (props.groups?.length) {
        groups = typeof props.groups === 'string' ? [props.groups]: props.groups
    }

    return (
        <Show when={!groups || iamService.isInUserGroups(...groups)}>
            <Route path={props.path} component={props.component}/>
        </Show>
    )
}