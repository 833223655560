import {createContext, createResource, ParentProps, Show, useContext} from 'solid-js'
import {BeanFactory, BeanName} from '@peachy/service'
import {Spinner} from '../../components/Spinner/Spinner'
import {WebCustomerApplicationContext} from '../../service/customer-context/WebCustomerApplicationContext'

type AiHarnessContext = BeanFactory

const Context = createContext<AiHarnessContext>()

async function syncedAppContextForCustomer(id: string) {
    return  WebCustomerApplicationContext.for(id)
}

export function AiHarnessContextProvider(props: ParentProps<any>) {

    const [webContext] = createResource('123', id => syncedAppContextForCustomer(id))

    const context: AiHarnessContext = {
        getBean<N extends BeanName>(beanName: N) {
            return webContext()?.getBean(beanName)
        },
        getBeans(...beanNames: BeanName[]) {
            return webContext()?.getBeans(...beanNames)
        }
    }

    return (
        <Show when={!webContext.loading} fallback={<Spinner isOpen/>}>
            <Context.Provider value={context}>
                {props.children}
            </Context.Provider>
        </Show>
    )
}

export function useAiHarnessContext() {
    return useContext(Context)
}

export function useBean<N extends BeanName>(beanName: N) {
    return useAiHarnessContext().getBean(beanName)
}