import { AuthDetails, AuthProvider, NupaHttpClient, OptionalRequestConfig } from "@peachy/utility-kit-pure"
import {API} from '@aws-amplify/api'

export type TerminologyItem = {
    system: string
    code: string
    display: string
}

export type TerminologySearchResponse = {
    expansion: {
        contains: TerminologyItem[]
    }
}

export type LookupParameter = {
    name: string
    valueCode?: string
    valueString?: string
    valueUri?: string
    part: LookupParameter[]
}

export type TerminologyLookupResponse = {
    parameter: LookupParameter[]
}


type GetFn<T extends object = any> = (path: string) => Promise<T>

export class NhsTerminologyServerClient {
    
    private readonly SNOMED_CODE_SYSTEM = 'http://snomed.info/sct'
    private static readonly basePath = '/production1/fhir'

    public static terminologyIn = (response: TerminologySearchResponse): TerminologyItem[] => {
        return response?.expansion?.contains ?? []
    }

    public static usingFetch(domain: string, authProvider?: AuthProvider) {
        const http = new NupaHttpClient().withHeaders({'Access-Control-Allow-Origin': '*'})
        const getFn = async (path: string) => {
            const url = `https://${domain}${this.basePath}${path}`
            return http.GET(url, fetchRequestAuth(await authProvider?.getOrRefetchAuth()))
        }
        return new NhsTerminologyServerClient(getFn)
    }

    public static usingAmplifyApi(apiName: string, api: typeof API) {
        const getFn = async (path: string) => {
           return  api.get(apiName, `${this.basePath}${path}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
        return new NhsTerminologyServerClient(getFn)
    }

    private constructor(private readonly GET: GetFn) { }

    async lookupSnomedConcept(code: string): Promise<TerminologyLookupResponse> {
        return this.GET(`/CodeSystem/$lookup?system=${this.SNOMED_CODE_SYSTEM}&code=${code}&_format=json`)
    }

    async searchSnomedIsA(stem: string, isACode: string): Promise<TerminologySearchResponse> {
        return this.GET(`/ValueSet/$expand?filter=${stem}&_format=json&url=${this.isASnomed(isACode)}`)
    }

    private isASnomed(conceptId: string) {
        return `${this.SNOMED_CODE_SYSTEM}?fhir_vs=isa/${conceptId}`
    }
}

function fetchRequestAuth(auth?: AuthDetails): OptionalRequestConfig {
    return { 
        auth: auth ? {type: auth.token_type, token: auth.access_token} : undefined
    }
}