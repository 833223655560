import {BenefitType, defaultBenefitLimits, mandatoryBenefits, PlanBenefit} from './PlanBenefit'
import {toClass} from '../validation/validation-core'
import { Excess } from './Excess'

export class Plan {
    id: string
    name: string
    benefits: PlanBenefit[]
    excess?: Excess
    configId?: string

    findBenefit?(type: BenefitType) {
        return this.benefits.find(b => b.type === type)
    }
    
    hasBenefit?(type: BenefitType) {
        return this.benefits.some(b => b.type === type)
    }

    getBenefitsWithExcess?(): BenefitType[] {
        const benefitTypes = this.benefits.map(b => b.type)
        return benefitTypes.filter(benefitType => this.excess?.benefitTypes.includes(benefitType))
    }

    hasExcess() {
        return !!this.excess?.amountInPence
    }
}


export function planKey(planId: string) {
    return `peachy/plan/${planId}`
}

// todo Ugh. This needs to be moved to some proper product config somewhere - bex / parker to analyse
export function ensureMandatoryBenefits(plan: Plan) {
    const planClass = toClass(plan, Plan)
    mandatoryBenefits.forEach(mandatoryBenefit => {
        if (!planClass.hasBenefit(mandatoryBenefit)) {
            plan.benefits.push({type: mandatoryBenefit})
        }
    })
    plan.benefits.forEach(b => {
        if (b.type === 'DENTAL' && !planClass.hasBenefit('OPTICAL')) {
            plan.benefits.push({
                type: 'OPTICAL',
                limit: defaultBenefitLimits['OPTICAL']
            })
        }
        if (b.type === 'OPTICAL' && !planClass.hasBenefit('DENTAL')) {
            plan.benefits.push({
                type: 'DENTAL',
                limit: defaultBenefitLimits['DENTAL']
            })
        }
        // @ts-ignore why?
        b.limit = b.limit ?? defaultBenefitLimits[b.type]
    })
    return plan
}