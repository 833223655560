import {For, Signal} from "solid-js";
import {classList} from "@peachy/utility-kit-pure";


export type RadioOption<K extends string> = {
    key: K,
    label: string
}


export type RadioGroupProps<K extends string> = {
    name: string
    options: RadioOption<K>[]
    selection: K
    onSelect: (selection: K) => void
    class: string
}


export function RadioGroup<K extends string>(props: RadioGroupProps<K>) {
    return (
        <div class={props.class}>
            <For each={props.options}>{(o: RadioOption<K>) =>
                <label>
                    <input
                        type={'radio'}
                        name={props.name}
                        value={o.key}
                        checked={o.key === props.selection}
                        onChange={(evt) => {
                            if (evt.currentTarget.checked) {
                                props.onSelect(o.key)
                            }
                        }}/>
                    <span>{o.label}</span>
                </label>
            }</For>
        </div>
    )
}