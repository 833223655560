export * from './src/subscription-api'
export * from './src/sme-subscription-api'
        import * as me from '@peachy/subscription-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/comp-kit/subscription/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/subscription-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    