import { Plan } from '../domain/models/Plan'
import { Policy } from '../domain/models/Policy'
import { Account } from '../domain/models/Account'
import { Subscription } from '../domain/models/Subscription'

export type SubscriptionDto = Omit<Subscription, 'paymentIds' | 'planIds' | 'policyIds'> & {
    plans: Plan[]
    policies: Policy[]
}

export type AccountDto = Omit<Account, 'subscriptions'>

export class AccountSubscriptionDto {
    public account: AccountDto
    public subscription: SubscriptionDto
}
