import {createSignal, ParentProps, Show} from 'solid-js'

import styles from './BenefitSelector.module.css'
import {BenefitType} from '@peachy/core-domain-pure'
import {Expander} from '@peachy/client-kit'
import {classList, currencyFromPence} from '@peachy/utility-kit-pure'
import ListSelector from '@peachy/client-kit/src/components/ListSelector/ListSelector'
import {useGroupSubscriptionRequestStore} from '../../GroupSubscriptionPanel'
import {availableBenefitLimits} from '@peachy/core-domain-pure'

export function BenefitSelector() {
    return (<div class={styles.BenefitPanel}>
        <h6>Modules</h6>
        <div class={styles.BenefitSelector}>
            <Benefit type={'HOSPITAL_CARE'} availableLimits={availableBenefitLimits.HOSPITAL_CARE}>
                <h3>Hospital<br/> Care</h3>
            </Benefit>

            <Benefit type={'CONSULTATIONS_DIAGNOSTICS'} availableLimits={availableBenefitLimits.CONSULTATIONS_DIAGNOSTICS}>
                <h3>Consultations <br/>& Diagnostics</h3>
            </Benefit>

            <Benefit type={'THERAPIES'}>
                <h3>Therapies</h3>
            </Benefit>

            <Benefit type={'DENTAL'}>
                <h3>Dental <br/>& Optical</h3>
            </Benefit>
        </div>
    </div>
    )
}


type BenefitProps = ParentProps & {
    type: BenefitType
    availableLimits?: number[]
}

function Benefit(props: BenefitProps) {
    const store = useGroupSubscriptionRequestStore()

    const isSelected = () => store.hasBenefit(props.type)
    const toggleSelected = () => store.toggleBenefit(props.type)
    const hasLimit = () => store.hasBenefitLimit(props.type)

    return (
        <label>
            <input type={'checkbox'} checked={isSelected()} onChange={toggleSelected}/>
            <article>
                {props.children}
                <Show when={hasLimit()}>
                    <LimitBox type={props.type} availableLimits={props.availableLimits}/>
                </Show>
            </article>
        </label>
    )
}


type LimitBoxProps = {
    type: BenefitType
    availableLimits: number[]
}

function LimitBox(props: LimitBoxProps) {

    const store = useGroupSubscriptionRequestStore()


    const [isOpen, setIsOpen] = createSignal(false)
    const isSelected = () => store.hasBenefit(props.type)

    const updateLimit = (limit: number) => store.updateBenefitLimit(props.type, limit)


    const availableLimits = () => props.availableLimits.map(currencyFromPence)

    let handled = false

    const onSelect = (display: string, limitIndex: number) => {
        const limit = props.availableLimits[limitIndex]
        setIsOpen(false)
        updateLimit(limit)
        handled = true
    }

    const hasLimit = () => store.hasBenefitLimit(props.type)
    const showLimitSelector = () => hasLimit() && isOpen() && isSelected()

    const classes = () => classList(styles.LimitBox, !isSelected() && styles.dormant)

    return (
        <label
            onclick={() => {
                if (!handled && hasLimit() && isSelected()) {
                    setIsOpen(true)
                } else {
                    handled = false
                }
            }}
            class={classes()}>

            <input type={'number'} value={store.getBenefitLimit(props.type)}/>
            <span>{currencyFromPence(store.getBenefitLimit(props.type))}</span>

            <Show when={showLimitSelector()}>
                <div class={styles.ListPane} onClick={(e) => {
                    handled = true
                    setIsOpen(false)
                }}></div>
            </Show>


            <Expander expanded={showLimitSelector()} tag={'aside'}>
                <ListSelector list={availableLimits()}
                              selection={currencyFromPence(store.getBenefitLimit(props.type))}
                              onSelect={onSelect}
                              onDismiss={() => setIsOpen(false)}
                              enabled={showLimitSelector()}
                />
            </Expander>
        </label>
    )

}
