export * from './src/claims-activity-api'
export * from './src/ai/ClaimChecklistModel'
export * from './src/ai/InferredClaimAssessmentValues'
        import * as me from '@peachy/claims-activity-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/comp-kit/claims-activity/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/claims-activity-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    