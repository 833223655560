import {Accessor, createSignal, Setter} from 'solid-js'
import {EnquiryDefinitionCommon, EnquiryService, PeachyClient} from '@peachy/service'
import {useBean} from './AiHarnessContextProvider'
import {Enquiry, Question, QuestionAnswer, yesOption} from '@peachy/repo-domain'
import {Store} from 'solid-js/store'
import {createListStore, ListStoreApi} from '@peachy/client-kit'
import {template} from 'lodash-es'
import {
    ClaimEvidence,
    ClaimTestCaseEvidence,
    CoverCheckEvidence,
    CoverCheckTestCaseEvidence,
    GetClaimChecklistResponse
} from '@peachy/claims-activity-pure'
import {format} from 'date-fns'
import {
    defaultClaim,
    defaultCoverCheck,
    defaultLife, fakeCoverResponse,
    fakeResponse,
    getDob,
    getId,
    getVideoParam,
    plan,
    planYear,
    policy,
    populateClaimActivity,
    prettyPrintJSON,
    toExpenseField,
    toReferral,
    toVideo
} from './TestData'
import {GenderType, Life} from '@peachy/core-domain-pure'
import {plainToInstance} from 'class-transformer'


export type PageMode = 'covercheck' | 'claim'


export class AiHarnessState {

    readonly peachyClient: PeachyClient
    readonly enquiryService: EnquiryService

    readonly pageMode: Accessor<PageMode>
    readonly setPageMode: Setter<PageMode>

    readonly customer: Accessor<Life>
    readonly setCustomer: Setter<Life>

    readonly filename: Accessor<string>
    readonly setFilename: Setter<string>

    readonly expectedOutcome: Accessor<string>
    readonly setExpectedOutcome: Setter<string>

    readonly results: Accessor<GetClaimChecklistResponse>
    readonly setResults: Setter<GetClaimChecklistResponse>

    readonly coverCheck: Accessor<CoverCheckEvidence>
    readonly setCoverCheck: Setter<CoverCheckEvidence>

    readonly claim: Accessor<ClaimEvidence>
    readonly setClaim: Setter<ClaimEvidence>

    readonly claimCoverChecks: Store<CoverCheckEvidence[]>
    readonly claimCoverChecksApi: ListStoreApi<CoverCheckEvidence>

    readonly resultsLoading: Accessor<boolean>
    readonly setResultsLoading: Setter<boolean>

    readonly results: Accessor<GetClaimChecklistResponse>
    readonly setResults: Setter<GetClaimChecklistResponse>

    constructor() {
        this.peachyClient = useBean('peachyClient')
        this.enquiryService = useBean('enquiryService')
        ;[this.pageMode, this.setPageMode] = createSignal<PageMode>('covercheck')
        ;[this.customer, this.setCustomer] = createSignal<Life>(defaultLife())
        ;[this.filename, this.setFilename] = createSignal<string>('TestCase')
        ;[this.expectedOutcome, this.setExpectedOutcome] = createSignal<string>('')
        ;[this.results, this.setResults] = createSignal<GetClaimChecklistResponse>(undefined)
        ;[this.coverCheck, this.setCoverCheck] = createSignal<CoverCheckEvidence>(undefined)
        ;[this.claim, this.setClaim] = createSignal<ClaimEvidence>(undefined)
        ;[this.claimCoverChecks, this.claimCoverChecksApi] = createListStore<CoverCheckEvidence>();
        ;[this.resultsLoading, this.setResultsLoading] = createSignal<boolean>(false)
        ;[this.results, this.setResults] = createSignal<GetClaimChecklistResponse>(undefined)
    }

    isClaim() {
        return this.pageMode() == 'claim'
    }

    async changePageMode(pageMode: PageMode) {
        this.setPageMode(pageMode)
    }

    isLoading() {
        return this.resultsLoading()
    }

    setLoading(loading: boolean) {
        this.setResultsLoading(loading)
    }

    getCustomerName() {
        return this.customer().fullName()
    }

    changeCustomerName(name: string) {
        let [firstname, lastname] = name.split(' ')
        if (!lastname) {
            lastname = 'Lastname'
        }
        this.setCustomer(plainToInstance(Life, {...this.customer(), ...{firstname, lastname}}))
    }

    getCustomerAge() {
        return this.customer().age()
    }

    changeCustomerAge(age: number) {
        this.setCustomer(plainToInstance(Life, {...this.customer(), ...{dateOfBirth: getDob(age).getTime()}}))
    }

    getCustomerGender() {
        return this.customer().gender
    }

    changeCustomerGender(gender: GenderType) {
        this.setCustomer(plainToInstance(Life, {...this.customer(), ...{gender}}))
    }

    getFilename() {
        return this.filename()
    }

    changeFilename(name: string) {
        this.setFilename(name)
    }

    downloadToFile() {
        const json = prettyPrintJSON(this.isClaim() ? this.toClaimTestCaseEvidence() : this.toCoverCheckTestCaseEvidence())
        const blob = new Blob([json], {type: "application/json"})
        const url = URL.createObjectURL(blob)
        const tempLink = document.createElement("a")
        tempLink.href = url
        tempLink.download = `${this.filename()}.json`
        tempLink.click()
        tempLink.remove()
    }

    toCoverCheckTestCaseEvidence(): CoverCheckTestCaseEvidence {
        const coverCheck = this.getCoverCheck()
        return {
            ...coverCheck, ...{
                claimActivity: populateClaimActivity(coverCheck.claimActivity, coverCheck.enquiry, this.customer()),
                expectedOutcome: this.getExpectedOutcome(),
                results: this.getResults(),
                life: this.customer(),
                planYears: [planYear(this.customer())],
                policy: policy(this.customer()),
                plan: plan(),
                isTestCase: true
            }
        } as CoverCheckTestCaseEvidence
    }

    toClaimTestCaseEvidence(): ClaimTestCaseEvidence {
        const claim = this.claim()
        claim.claimActivity = populateClaimActivity(claim.claimActivity, claim.enquiry, this.customer())
        const coverChecks = this.getClaimCoverChecks()
        coverChecks.forEach(coverCheck => {
            const claimActivity = populateClaimActivity(coverCheck.claimActivity, coverCheck.enquiry, this.customer())
            this.claimCoverChecksApi.patchById(getId(coverCheck), {claimActivity})
        })
        return {
            ...claim, ...{
                allCoverChecks: coverChecks,
                expectedOutcome: this.getExpectedOutcome(),
                results: this.getResults(),
                life: this.customer(),
                planYears: [planYear(this.customer())],
                policy: policy(this.customer()),
                plan: plan(),
                isTestCase: true
            }
        } as ClaimTestCaseEvidence
    }

    getExpectedOutcome() {
        return this.expectedOutcome()
    }

    changeExpectedOutcome(text: string) {
        this.setExpectedOutcome(text)
    }

    getResults() {
        return this.results()
    }

    updateResults(results: GetClaimChecklistResponse) {
        this.setResults(results)
    }

    clearResults() {
        this.setResults(undefined)
    }

    async initValues() {
        this.setCoverCheck(await defaultCoverCheck(this.enquiryService, this.customer()))
        this.setClaim(await defaultClaim(this.enquiryService, this.customer()))
    }

    getCoverCheck(): CoverCheckEvidence {
        return this.coverCheck()
    }

    getClaimCoverChecks(): CoverCheckEvidence[] {
        return this.claimCoverChecks
    }

    getClaim(): ClaimEvidence {
        return this.claim()
    }

    async addCoverCheck() {
        const coverCheck = await defaultCoverCheck(this.enquiryService, this.customer())
        this.claimCoverChecksApi.push({...coverCheck, ...{id: getId(coverCheck)}} as CoverCheckEvidence)
    }

    deleteCoverCheck(coverCheck: CoverCheckEvidence) {
        this.claimCoverChecksApi.removeById(getId(coverCheck))
    }

    async setCoverCheckAnswer(coverCheck: CoverCheckEvidence, questionId: string, answer: QuestionAnswer) {
        const enquiry = await this.enquiryService.updateEnquiry(coverCheck.enquiry, questionId, [answer])
        if (this.isClaim()) {
            this.claimCoverChecksApi.patchById(getId(coverCheck), {enquiry})
        } else {
            this.setCoverCheck({...coverCheck, ...{enquiry}})
        }
        console.log(enquiryToString(coverCheck.enquiry))
    }

    async setClaimAnswer(questionId: string, answer: QuestionAnswer) {
        const claim = this.getClaim()
        const enquiry = await this.enquiryService.updateEnquiry(claim.enquiry, questionId, [answer])
        this.setClaim({...claim, ...{enquiry}})
        console.log(enquiryToString(this.claim().enquiry))
    }

    getInvoiceLineItems(): Record<string, string>[] {
        const claim = this.getClaim()
        const invoice = claim.invoices[0].contents.invoice
        const lineItems = invoice.ExpenseDocuments?.flatMap(doc => doc.LineItemGroups).flatMap(group => group.LineItems)
        const getLineItemValue = (lineItem, fieldName) =>  lineItem.LineItemExpenseFields.find(field => field.Type.Text == fieldName).ValueDetection.Text
        return lineItems.map(lineItem => (
            {
                ITEM: getLineItemValue(lineItem, 'ITEM'),
                QUANTITY: getLineItemValue(lineItem, 'QUANTITY'),
                UNIT_PRICE: getLineItemValue(lineItem, 'UNIT_PRICE'),
                PRICE: getLineItemValue(lineItem, 'PRICE')
            }))
    }

    setInvoiceLineItemValue(i: number, fieldName: string, text: string) {
        const claim = this.getClaim()
        const invoice = claim.invoices[0]
        const lineItem = invoice.contents.invoice.ExpenseDocuments?.flatMap(doc => doc.LineItemGroups).flatMap(group => group.LineItems)[i]
        console.log(i)
        console.log(lineItem)
        lineItem.LineItemExpenseFields.find(field => field.Type.Text == fieldName).ValueDetection.Text = text
        this.setClaim({...claim, ...{invoices: [invoice]}})
    }

    addInvoiceLineItemRow() {
        const claim = this.getClaim()
        const invoice = claim.invoices[0]
        const lineItemGroup = invoice.contents.invoice.ExpenseDocuments?.flatMap(doc => doc.LineItemGroups)[0]
        lineItemGroup.LineItems.push({
            LineItemExpenseFields: [
                toExpenseField('ITEM', ''),
                toExpenseField('QUANTITY', ''),
                toExpenseField('UNIT_PRICE', ''),
                toExpenseField('PRICE', '')
            ]
        })
        this.setClaim({...claim, ...{invoices: [invoice]}})
    }

    removeInvoiceLineItemRow(i: number) {
        const claim = this.getClaim()
        const invoice = claim.invoices[0]
        const lineItemGroup = invoice.contents.invoice.ExpenseDocuments?.flatMap(doc => doc.LineItemGroups)[0]
        lineItemGroup.LineItems = lineItemGroup.LineItems.filter((lineItem, index) => index != i)
        this.setClaim({...claim, ...{invoices: [invoice]}})
    }

    getInvoiceValue(fieldName: string) {
        const claim = this.getClaim()
        const invoice = claim.invoices[0].contents.invoice
        if (fieldName == 'FREE_TEXT') {
            const freeTextField = invoice.ExpenseDocuments?.flatMap(doc => doc.Blocks)[0]
            return freeTextField.Text
        } else {
            const summaryField = invoice.ExpenseDocuments?.flatMap(doc => doc.SummaryFields).find(it => it.Type.Text == fieldName)
            return summaryField.ValueDetection.Text
        }
    }

    setInvoiceValue(fieldName: string, value: string) {
        const claim = this.getClaim()
        const invoice = claim.invoices[0]
        if (fieldName == 'FREE_TEXT') {
            const freeTextField = invoice.contents.invoice.ExpenseDocuments?.flatMap(doc => doc.Blocks)[0]
            freeTextField.Text = value
        } else {
            const summaryField = invoice.contents.invoice.ExpenseDocuments?.flatMap(doc => doc.SummaryFields).find(it => it.Type.Text == fieldName)
            summaryField.ValueDetection.Text = value
        }
        this.setClaim({...claim, ...{invoices: [invoice]}})

    }

    getVideoAnswer(questionId: string): string {
        const videoEvidence = this.getClaim()[getVideoParam(questionId)]
        return videoEvidence.contents.transcript.results.transcripts[0].transcript
    }

    setVideoAnswer(questionId: string, text: string) {
        this.setClaim({...this.getClaim(), ...{[getVideoParam(questionId)]: toVideo(text)}})
        console.log(enquiryToString(this.getClaim().enquiry))
    }

    getCoverCheckSubmissionDate(coverCheck: CoverCheckEvidence) {
        return format(coverCheck.claimActivity.dateSubmitted, 'yyyy-MM-dd')
    }

    setCoverCheckSubmissionDate(coverCheck: CoverCheckEvidence, date: string) {
        const claimActivity = {...coverCheck.claimActivity, ...{dateSubmitted: new Date(date)}}
        if (this.isClaim()) {
            this.claimCoverChecksApi.patchById(getId(coverCheck), {claimActivity})
        } else {
            this.setCoverCheck({...coverCheck, ...{claimActivity}})
        }
    }

    getClaimSubmissionDate() {
        return format(this.getClaim().claimActivity.dateSubmitted, 'yyyy-MM-dd')
    }

    setClaimSubmissionDate(date: string) {
        const claim = this.getClaim()
        const claimActivity = {...claim.claimActivity, ...{dateSubmitted: new Date(date)}}
        this.setClaim({...claim, ...{claimActivity}})
    }

    referralToHand(coverCheck: CoverCheckEvidence) {
        return coverCheck.enquiry.getFirstAnswerTo('have-you-got-referral-to-hand') == yesOption
    }

    getReferralText(coverCheck: CoverCheckEvidence) {
        return coverCheck.referrals[0]?.contents.Blocks[0].Text
    }

    setReferralText(coverCheck: CoverCheckEvidence, text: string) {
        if (this.isClaim()) {
            this.claimCoverChecksApi.patchById(getId(coverCheck), {referrals: [toReferral(text)]})
        } else {
            this.setCoverCheck({...coverCheck, ...{referrals: [toReferral(text)]}})
        }
    }

    async getNewResults() {
        this.setResultsLoading(true)
        const request = this.isClaim() ? this.toClaimTestCaseEvidence() : this.toCoverCheckTestCaseEvidence()
        console.log(request)
        let response
        try {
            response = await this.peachyClient.getAiClaimChecklist(request)
            this.setResults(response)
            // this.setResults(fakeCoverResponse())
        } catch (e) {
            console.log(`Error getting checklist results: "${e.message}"`)
        } finally {
            this.setResultsLoading(false)
        }
        console.log(response)
    }

    clearResults() {
        this.setResults(undefined)
    }
}

function enquiryToString(enquiry: Enquiry) {
    return enquiry.getRequiredQuestions()
        .filter(it => !it.tags.includes(EnquiryDefinitionCommon.hideQuestionInChatTag))
        .filter(it => !it.tags.includes(EnquiryDefinitionCommon.hideAnswersInChatTag))
        .map(question => {
            const questionText = template(question.text, {imports: question.dataModel})()
            const answer = question.getFirstAnswer()?.toString()
            return `Insurer: "${questionText}"\nCustomer: "${answer}"`
        }).join('\n')
}

export function getRelevantQuestions(enquiry: Enquiry): Question[] {
    return enquiry?.getRequiredQuestions()
        .filter(it => !it.tags.includes(EnquiryDefinitionCommon.hideQuestionInChatTag))
        .filter(it => !it.tags.includes(EnquiryDefinitionCommon.hideAnswersInChatTag))
}
