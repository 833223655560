import {BenefitSelector} from './BenefitSelector/BenefitSelector'
import {DateBox, TextBox} from '@peachy/client-kit'
import styles from './GroupPlan.module.css'
import {IsValidMarker} from '../IsValidMarker/IsValidMarker'
import {useGroupSubscriptionRequestStore} from '../GroupSubscriptionPanel'
import {createSignal} from "solid-js";
import {PeachyDatePicker} from "../../../../components/DatePicker/PeachyDatePicker";
import {addDays} from "date-fns";
import {classList} from "@peachy/utility-kit-pure";

export function GroupPlan() {

    const [showDatePicker, setShowDatePicker] = createSignal(false)

    const store = useGroupSubscriptionRequestStore()

    const calendarTriggerClasses = classList('fa-light fa-calendar', styles.startDate)

    return (
        <div class={styles.GroupPlan}>
            <h3><IsValidMarker isValid={store.hasValidPlan()}/>Plan Details</h3>
            <DateBox placeholder={'dd/mm/yyyy'} onDateEntered={d => store.setStartDate(d)} selectedDate={store.getSubscriptionRequest().subscription.startDate} class={styles.TextBox}>
                <h6>Start date:
                    <i onClick={() => setShowDatePicker(true)} class={calendarTriggerClasses}/>
                </h6>
            </DateBox>

            <PeachyDatePicker
                isOpen={showDatePicker()}
                onDismiss={() => setShowDatePicker(false)}
                onSelect={d => store.setStartDate(d.getTime())}
                minDate={new Date()}
                maxDate={addDays(new Date(), 30)}
                selectedDate={new Date(store.getSubscriptionRequest().subscription.startDate)}
                headerText={'Start Date'}
                submitText={'Done'}
            />

            <TextBox value={store.getPromoCode()} onChange={value => store.setPromoCode(value)} class={styles.TextBox}>
                <h6>Promo code:</h6>
            </TextBox>
            <BenefitSelector/>
        </div>
    )
}

