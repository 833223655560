import {Api, defineApi, KeyMapped, Payment, PaymentAttempt, postOf} from '@peachy/core-domain-pure'
import {DashboardGroups, DashboardUserGroups} from './user-admin-domain'


export class ListPaymentsRequest {
    startAt:number
    endAt: number
}

export class ListPaymentsResponse {
    payments: Payment[]
    paymentAttempts: KeyMapped<PaymentAttempt>
}


export const PaymentsApiDefinition = defineApi('PaymentsApi')
    .forGroups(DashboardGroups)
    .withActions({
        listPayments: postOf(ListPaymentsRequest).fromGroups(...DashboardUserGroups).willReturn(ListPaymentsResponse)
    })



export type PaymentsApi = Api<typeof PaymentsApiDefinition>

