import { KeyMapped } from '../../some-types'
import { EmailData } from './EmailData'
import { Life } from '../Life'
import { Plan } from '../Plan'

export type PolicyDocChangesEmailData = EmailData & {
    firstName: string
    plans: KeyMapped<Plan>
    addedLives?: Life[]
    removedLives?: Life[]
}
