import {Api, defineApi, postOf} from '@peachy/core-domain-pure'
import {SyncRequest, SyncResponse} from './flash-repo/FlashRepo'
import {Bookmark, Flashlet, Hash} from './flash-repo/flash-repo-domain'
import {DashboardGroups} from '@peachy/web-dashboard-pure'

class AdminRepoSyncRequest {
    sub: string
    syncRequest: SyncRequest
}

class AdminRepoSyncResp implements SyncResponse {
    bookmark: Bookmark
    commonHash: Hash
    flashlets: Flashlet[]
    rootHash: Hash
}

export const FlashRepoApiDefinition = defineApi('LambdaFlashRepoApi')
.forGroups(DashboardGroups)
.withActions({
    adminSync: postOf(AdminRepoSyncRequest).fromGroups('AdminUsers').willReturn(AdminRepoSyncResp)
})

export type FlashRepoApi = Api<typeof FlashRepoApiDefinition>