import {
    RepoClaimActivity,
    DomainMappings
} from '@peachy/repo-domain'
import {LifeService} from './LifeService'
import {BenefitsService} from './BenefitsService'
import {ClaimActivityRepository} from '@peachy/flash-repo-peachy-client'
import {RepoEntityPredicate} from '@peachy/flash-repo-pure'
import { orderBy } from 'lodash-es'

export class ClaimsSearchService {

    constructor(protected readonly claimActivityRepository: ClaimActivityRepository,
                protected readonly benefitsService: BenefitsService,
                protected readonly lifeService: LifeService) {
    }
    async getClaimActivity(claimActivityId: string) {
        return (await this.searchClaimsActivities({id: claimActivityId}))[0]
    }

    async listAllSubmittedClaimsActivities() {
        const submittedRepoActivities = await this.claimActivityRepository.listSubmitted()
        return orderBy(await this.mapToClaimActivities(submittedRepoActivities), it => it.dateSubmitted.getTime(), ['desc'])
    }

    async listSubmittedWithUnfinishedButResumableMediaUploads() {
        const repoClaimsActivities = await this.claimActivityRepository.listSubmittedWithUnfinishedButResumableMediaUploads()
        return this.mapToClaimActivities(repoClaimsActivities)
    }

    async searchClaimsActivities(query: RepoEntityPredicate<RepoClaimActivity>) {
        const repoActivities = await this.claimActivityRepository.filter(query)
        return this.mapToClaimActivities(repoActivities)
    }
    
    async listAllApprovedClaimsFor(lifeId: string) {
        const repoClaims = await this.claimActivityRepository.filter(it => {
            return it.stage === 'CLAIM' &&
            it.treatmentReceiverId === lifeId &&
            it.decision?.type === 'APPROVE'
        })
        return this.mapToClaimActivities(repoClaims)
    }

    private async mapToClaimActivities(repoActivities: RepoClaimActivity[]) {
        const lives = await this.lifeService.getAllLives()
        const benefits = await this.benefitsService.listBenefits()
        return repoActivities.map(repoActivity => {
            const life = lives.find(life => life.id === repoActivity.treatmentReceiverId)
            const benefit = benefits.find(benefit => benefit.id === repoActivity.benefitId)
            return DomainMappings.fromRepo.toClaimActivity(repoActivity, life, benefit)
        })
    }

}