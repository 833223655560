import { AnEvent } from '../event-kit'

export class SmeCancellationEmailSent extends AnEvent {
    public accountId: string

    constructor(from: SmeCancellationEmailSent) {
        super()
        Object.assign(this, from)
    }
}
