export * from './src/enquiry/types';
export * from './src/enquiry/EnquiryDefinitionService';
export * from './src/enquiry/EnquiryReader';
export * from './src/enquiry/EnquiryService';

export * from './src/enquiry/definition/EnquiryDefinitionCommon';
export * from './src/enquiry/definition/cover-check/CoverCheckEnquiryDefinition';
export * from './src/enquiry/definition/make-claim/MakeClaimEnquiryDefinition';
export * from './src/enquiry/definition/book-virtual-gp/BookVirtualGpEnquiryDefinition';
export * from './src/enquiry/definition/book-physio/BookPhysioEnquiryDefinition';

export * from './src/LifeService';
export * from './src/BenefitsService';
export * from './src/ClaimsSearchService';
export * from './src/PlanService';
export * from './src/InProgressService';
export * from './src/RepoManagementService';
export * from './src/IPeachyClient';
export * from './src/PeachyClient';
export * from './src/AscentiService';
export * from './src/NhsService';
export * from './src/SearchService';
export * from './src/GetCareService';
export * from './src/PolicyService';
export * from './src/ClaimsService';
export * from './src/AppointmentsService';
export * from './src/PhysioService';
export * from './src/PreferencesService';
export * from './src/HealthHeroService';
export * from './src/VirtualGpService';

export * from './src/lambda/ILambdaApiProvider';
export * from './src/lambda/LambdaApiProvider';

export * from './src/ApplicationContextFactory';
        import * as me from '@peachy/service'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/comp-kit/service'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/service'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    