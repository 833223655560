import { Component, JSX, Show} from 'solid-js'
import s from './ErrorWrapped.module.css'

type ErrorAwareProps<T> = T & {
    error?: string
    warning?: string
    topRight?: JSX.Element
}

export function ErrorWrapped<ComponentProps>(component: Component<ComponentProps>, styleOverrides: JSX.CSSProperties = {}) {
    
    return (props: ErrorAwareProps<ComponentProps>) => {
        
        const errorOrWarning = () => !!props.error ? {class: s.invalidField, message: props.error} : 
        !!props.warning ? {class: s.warningField, message: props.warning} : {}
        
        return <span style={{position: 'relative'}} class={errorOrWarning().class}>
                {component(props)}
                <span style={{...styleOverrides}} class={s.message}>{errorOrWarning().message}</span>
                <Show when={props.topRight}>
                    <span class={s.topRight}>{props.topRight}</span>
                </Show>
            </span>
    }
}