import { toClass } from '../../validation/validation-core'
import { Company } from '../Company'
import { Plan } from '../Plan'
import { BenefitType } from '../PlanBenefit'
import { Policy } from '../Policy'

export class SmeEmployerPolicyDocData {
    domain: string
    isSample: boolean
    subscriptionStartDate: number
    planNumber: string
    
    plans: Plan[]
    policies: Policy[]
    
    company: Company
    email: string

    constructor (private readonly dataIn: SmeEmployerPolicyDocData) {
        this.domain = dataIn.domain
        this.isSample = dataIn.isSample
        this.subscriptionStartDate = dataIn.subscriptionStartDate
        this.planNumber = dataIn.planNumber
        this.plans = dataIn.plans.map(plan => toClass(plan, Plan))
        this.policies = dataIn.policies.map(policy => toClass(policy, Policy))
        this.company = toClass(dataIn.company, Company)
        this.email = dataIn.email
    }

    hasBenefit(benefitType: BenefitType) {
        return this.plans.some(plan => plan.hasBenefit(benefitType))
    }

    hasExcess() {
        return this.plans.some(plan => plan.hasExcess())
    }

    getPlan(planId: string) {
        return this.plans.find(plan => plan.id === planId)
    }
}