export type InvitePortalUserRequest = {
    email: string
    firstName: string
    lastName: string
}

export type InvitePortalUserResponse = {
    id: string
}

export type InvitePortalUserErrorResponse = {
    message: string
}

export class WelcomeAdminUserRequest {
    email: string
    
    constructor(email: string) {
        this.email = email
    }
}

export class WelcomeAdminUserResponse {
    done: boolean
}

export const PortalUsersGroup = 'PortalUsers'
export const PortalGroups = [PortalUsersGroup] as const