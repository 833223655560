import styles from './DragScope.module.css'
import {createComputed, createContext, createSignal, ParentProps, useContext} from 'solid-js'
import {isPointInBounds, pick, Point, subtractVectors} from '@peachy/utility-kit-pure'
import {Modal} from '../../components/Modal/Modal'
import {da} from "date-fns/locale";

export type DragNode<T> = HTMLElement & {
    dataItem?: T
    origin?: Point
}

export type DragState<T> = {
    dragNodes: DragNode<T>[]
    dragHandle: DragNode<T>
    dragOrigin: Point
}


export type DragScopeProps<T> = ParentProps & {
    onDrop(newOrder: DragNode<T>[]): void
    isDraggable(): boolean
}

type DragContext<T> = {
    dragHook: (element: DragNode<T>) => void
}
const DragContextKey = createContext<DragContext<unknown>>()

export function DragScope<T>(props: DragScopeProps<T>) {

    const [jeff, setJeff] = createSignal('')

    const [dragState, setDragState] = createSignal<DragState<T>>({
        dragNodes: [],
        dragHandle: null,
        dragOrigin: null
    })

    const dragContext: DragContext<T> = {
        dragHook(element: DragNode<T>) {
            setDragState(state => {
                return {
                    ...state,
                    dragNodes: [...state.dragNodes, element]
                } as DragState<T>
            })
        },
    }


    function getDragNodeUnderPoint(point: Point): DragNode<T> {
        for (const d of dragState().dragNodes) {
            if (isPointInBounds(point, d.getBoundingClientRect())) {
                return d
            }
        }
        return null
    }


    const grab = (mev: MouseEvent) => {
        const dragOrigin = mousePoint(mev)

        dragState().dragNodes.forEach(d => {
            d.origin = pick(d.getBoundingClientRect(), 'x', 'y')
        })
        const dragHandle = getDragNodeUnderPoint(dragOrigin)

        if (dragHandle) {
            setDragState((state): DragState<T> => {
                return {
                    ...state,
                    dragHandle,
                    dragOrigin
                }
            })
            dragState().dragNodes.forEach(d => {
                d.style.transition = null
            })
            dragState().dragHandle.setAttribute('data-grabbed', 'true')
        }
    }

    const drag = (mev: MouseEvent) => {

        const la = getDragNodeUnderPoint(mousePoint(mev))
        if (!!la) {
            setJeff('draggable')
        } else {
            setJeff('')
        }

        if (dragState().dragHandle) {

            setJeff('dragging')


            const dragPoint = subtractVectors(mousePoint(mev), dragState().dragOrigin)

            const dragHandle = dragState().dragHandle

            const originalOrder = originalOrdering(dragState().dragNodes)
            const draggedOrder = draggedOrdering(dragState().dragNodes)


            for (let i = 0; i < draggedOrder.length; i++) {

                if (draggedOrder[i] !== dragHandle) {
                    if (originalOrder[i] === draggedOrder[i]) {
                        draggedOrder[i].style.left = '0'
                        draggedOrder[i].style.top = '0'
                    } else {
                        const nudge = subtractVectors(originalOrder[i].origin, draggedOrder[i].origin)
                        draggedOrder[i].style.left = `${nudge.x}px`
                        draggedOrder[i].style.top = `${nudge.y}px`
                    }
                }
            }
            dragHandle.style.left = `${dragPoint.x}px`
            dragHandle.style.top = `${dragPoint.y}px`
        }
    }

    const drop = () => {
        setJeff('')

        if (!props.isDraggable()) {
            return stop()
        }
        const dragHandle = dragState().dragHandle
        if (dragHandle) {

            dragHandle.removeAttribute('data-grabbed')

            const originalOrder = originalOrdering(dragState().dragNodes)
            const draggedOrder = draggedOrdering(dragState().dragNodes)

            const updateItems = () => {
                requestAnimationFrame(() => {
                    originalOrder.forEach(d => {
                        d.style.transition = 'none'
                        d.style.left = null
                        d.style.top = null
                    })
                    props.onDrop(draggedOrder)
                })
                dragHandle.removeEventListener('transitionend', updateItems)
            }
            dragHandle.addEventListener('transitionend', updateItems)

            const draggedIndex = draggedOrder.indexOf(dragHandle)
            const draggedPosition = subtractVectors(originalOrder[draggedIndex].origin, dragHandle.origin)
            dragHandle.style.left = `${draggedPosition.x}px`
            dragHandle.style.top = `${draggedPosition.y}px`

            setDragState(state => {
                return {
                    ...state,
                    dragHandle: null,
                    dragStart: null
                }
            })
        }
    }

    const stop = () => {

        const dragHandle = dragState().dragHandle
        if (dragHandle) {

            dragHandle.removeAttribute('data-grabbed')

            const originalOrder = originalOrdering(dragState().dragNodes)

            const updateItems = () => {
                requestAnimationFrame(() => {
                    originalOrder.forEach(d => {
                        d.style.transition = 'none'
                        d.style.left = null
                        d.style.top = null
                    })
                })
                dragHandle.removeEventListener('transitionend', updateItems)
            }
            dragHandle.addEventListener('transitionend', updateItems)

            originalOrder.forEach(d => {
                d.style.left = `0px`
                d.style.top = `0px`
            })

            setDragState(state => {
                return {
                    ...state,
                    dragHandle: null,
                    dragStart: null
                }
            })
        }
    }

    createComputed(() => {
        const dragHandle = dragState().dragHandle
        if (!props.isDraggable() && dragHandle) {
            stop()
        }
    })




    return (
        <DragContextKey.Provider value={dragContext}>
            {props.children}
            <Modal isOpen={props.isDraggable()}>
                <div data-drag-state={jeff().toString()} class={styles.DragPane} onMouseDown={grab} onMouseMove={drag} onMouseUp={drop}/>
            </Modal>
        </DragContextKey.Provider>
    )
}


export function useDragHook<T>(dataItem?: T) {


    return (element: HTMLElement) => {
        const dragNode: DragNode<T> = element
        dragNode.dataItem = dataItem
        useContext(DragContextKey).dragHook(dragNode)
    }
}



function mousePoint(mev: MouseEvent): Point {
    return {
        x: mev.clientX,
        y: mev.clientY,
    }
}





function originalOrdering<T>(dragNodes: DragNode<any>[])  {
    return [...dragNodes].sort((a: DragNode<any>, b: DragNode<any>) => comparePoints(a.origin, b.origin))
}

function draggedOrdering<T>(dragNodes: DragNode<any>[])  {
    return [...dragNodes].sort((a: DragNode<any>, b: DragNode<any>) => comparePoints(a.getBoundingClientRect(), b.getBoundingClientRect()))
}


const compareNumbers = (a: number, b: number) => Math.sign(a-b)
const comparePoints = (a: Point, b: Point) => compareNumbers(a.y, b.y) || compareNumbers(a.x, b.x)
