import { AccountSubscriptionDto } from '@peachy/core-domain-pure'
import {SubscriptionApi, SubscriptionResponse} from '@peachy/subscription-pure'
import { createSignal } from 'solid-js'
import { API } from '@aws-amplify/api'

export type SubscriptionService = SubscriptionApi & {
    getSubscription: (accountId: string, subscriptionId: string) => Promise<AccountSubscriptionDto> 
    isBusy: () => boolean
    error(): string
}

export function createSubscriptionService(api: SubscriptionApi): SubscriptionService {

    const [isBusy, setIsBusy] = createSignal(false)

    let error: string

    return {
        async setupGroupSubscription(subRequest): Promise<SubscriptionResponse> {
            setIsBusy(true)
            error = null
            let response: SubscriptionResponse
            try {
                response = await api.setupGroupSubscription(subRequest)
            } catch (e) {
                error = e.toString()
            } finally {
                setIsBusy(false)
            }
            return response
        },
        async getSubscription(accountId: string, subscriptionId: string): Promise<AccountSubscriptionDto> {
            try {
                return await API.get('SubscriptionApi', getSubscriptionURL(accountId, subscriptionId), {})
            } catch (e) {
                // do nothing as this is expected - will just return nothing
            }
        },
        isBusy() {
            return isBusy()
        },
        error(): string {
            return error
        }
    }
}

export const getSubscriptionURL = (accountId: string, subscriptionId: string, memberId?: string) : string => {
    const memberPath = memberId ? `/member/${memberId}` : ''
    
    return `/account/${accountId}/subscription/${subscriptionId}${memberPath}`
}