import {
    QuestionIds,
    QuestionInitialisationData,
    QuestionInitialiser,
    QuestionInitialiserAndConfigTuple
} from '../../types'
import {
    BenefitType,
    consultationsBenefitOption,
    dentalBenefitOption,
    doneOptions,
    gotItOptions, hospitalCareBenefitOption, iDontKnowOption,
    imReadyOptions, mentalHealthBenefitOption,
    mentalHealthCareOptions,
    noOption,
    opticalBenefitOption, otherOption, physiotherapistOption,
    prettyPrintBenefit,
    reviewSubmitOptions,
    therapiesBenefitOption,
    therapistOptions,
    whoOptions,
    yesNoDontKnowOptions,
    yesNoOptions,
    yesOption
} from '@peachy/repo-domain'
import {browserLinkMarkdown, modalLinkMarkdown} from '@peachy/utility-kit-pure'
import {EnquiryQuestionDataModelProviders} from '../../EnquiryQuestionDataModelProviders'
import {subYears} from 'date-fns'
import {EnquiryDefinitionCommon} from '../EnquiryDefinitionCommon'
import {
    buttonQuestion,
    captureImagesQuestion,
    fullDatePickerQuestion,
    requiredWhenMoreThanOneOption,
    singleAnswerCheckboxQuestion,
    singleAnswerFreetextQuestion,
    singleAnswerNumberQuestion,
    when
} from '../EnquiryDefinitionUtil'

export class CoverCheckEnquiryDefinition extends EnquiryDefinitionCommon {

    readonly id = 'cover-check'
    readonly questions: QuestionInitialiserAndConfigTuple[]

    constructor(protected readonly dataModelProviders: EnquiryQuestionDataModelProviders) {
        super()
        this.questions = this.buildQuestions()
    }

    buildQuestions(): QuestionInitialiserAndConfigTuple[] {
        const {
            whoIsTheAppOwner,
            whoAreYouCheckingCoverFor,
            whatBenefitAreYouChecking,
            dentalCoverRemainingInfo,
            opticalCoverRemainingInfo,
            therapiesCoverRemainingInfo,
            mentalHealthCoverRemainingInfo,
            consultationsCoverRemainingInfo,
            hospitalCareCoverRemainingInfo,
            dentalCareCoveredListInfo,
            opticalCareCoveredListInfo,
            dentalCareExcludedListInfo,
            opticalCareExcludedListInfo,
            earlyExitDental,
            earlyExitOptical,
            whatTypeOfTherapistDoYouNeed,
            whatConditionAndSymptoms,
            whenDidSymptomsStart,
            whatTypeOfMentalHealthCareDoYouNeed,
            whatOtherMentalHealthCareDoYouNeed,
            whatCareDoYouNeed,
            whatTreatmentOrTestsDoYouNeed,
            haveYouBeenReferred,
            haveYouGotReferralToHand,
            haveNotBeenReferredInfo,
            referralNotToHandInfo,
            youCanSelfRefer,
            prepareToCaptureReferralDocuments,
            captureReferralDocuments,
            willYouSeeAscenti,
            yesWillSeeAscentiInfo,
            notSureIfWillSeeAscentiInfo,
            doYouKnowTheCareCost,
            doYouKnowTheTreatmentCost,
            whatIsTheTotalCost,
            existingConditionsInfo,
            excludedConditionsInfo,
            thanksCoverCheckEnquiryDone
        } = this
        return [
            // unasked.. i.e expected to be initiated before chat
            [whoIsTheAppOwner],

            [whoAreYouCheckingCoverFor, requiredWhenMoreThanOneOption],
            [whatBenefitAreYouChecking],

            // REMAINING COVER
            [dentalCoverRemainingInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, dentalBenefitOption]]
                })
            }],
            [opticalCoverRemainingInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, opticalBenefitOption]]
                })
            }],
            [therapiesCoverRemainingInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, therapiesBenefitOption]]
                })
            }],
            [mentalHealthCoverRemainingInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, mentalHealthBenefitOption]]
                })
            }],
            [consultationsCoverRemainingInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, consultationsBenefitOption]]
                })
            }],
            [hospitalCareCoverRemainingInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, hospitalCareBenefitOption]]
                })
            }],

            // DENTAL/OPTICAL WHAT'S COVERED
            [dentalCareCoveredListInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, dentalBenefitOption]]
                })
            }],
            [opticalCareCoveredListInfo, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, opticalBenefitOption]]
                })
            }],

            // DENTAL/OPTICAL WHAT'S EXCLUDED
            [dentalCareExcludedListInfo, {
                required: when({
                    answersOf: [
                        [whatBenefitAreYouChecking, dentalBenefitOption]
                    ]
                })
            }],
            [opticalCareExcludedListInfo, {
                required: when({
                    answersOf: [
                        [whatBenefitAreYouChecking, opticalBenefitOption]
                    ]
                })
            }],

            // EARLY EXIT DENTAL/OPTICAL
            [earlyExitDental, {
                required: when({
                    answersOf: [
                        [whatBenefitAreYouChecking, dentalBenefitOption]
                    ]
                })
            }],

            [earlyExitOptical, {
                required: when({
                    answersOf: [
                        [whatBenefitAreYouChecking, opticalBenefitOption]
                    ]
                })
            }],

            // WHAT DO YOU NEED - THERAPY
            [whatTypeOfTherapistDoYouNeed, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, therapiesBenefitOption]]
                })
            }],

            [whatConditionAndSymptoms, requiredWhenNotCheckingDentalOrOptical],
            [whenDidSymptomsStart, requiredWhenNotCheckingDentalOrOptical],

            // WHAT DO YOU NEED - MENTAL HEALTH
            [whatTypeOfMentalHealthCareDoYouNeed, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, mentalHealthBenefitOption]]
                })
            }],
            [whatOtherMentalHealthCareDoYouNeed, {
                required: when({
                    answersOf: [[whatTypeOfMentalHealthCareDoYouNeed, otherOption]]
                })
            }],

            // WHAT DO YOU NEED - FREE TEXT
            [whatCareDoYouNeed, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, consultationsBenefitOption]]
                })
            }],
            [whatTreatmentOrTestsDoYouNeed, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, hospitalCareBenefitOption]]
                })
            }],

            // REFERRAL
            [haveYouBeenReferred, requiredWhenNotCheckingDentalOrOptical],
            [haveYouGotReferralToHand, {
                required: when({
                    answersOf: [[haveYouBeenReferred, yesOption]]
                })
            }],
            [haveNotBeenReferredInfo, {
                required: when({
                    answersOf: [[haveYouBeenReferred, noOption]]
                })
            }],
            [referralNotToHandInfo, {
                required: when({
                    answersOf: [[haveYouGotReferralToHand, noOption]],
                })
            }],
            [youCanSelfRefer, {
                required: when({
                    answersOf: [[whatTypeOfTherapistDoYouNeed, physiotherapistOption], [haveYouBeenReferred, noOption]]
                })
            }],
            [prepareToCaptureReferralDocuments, {
                required: when({
                    answersOf: [[haveYouGotReferralToHand, yesOption]]
                })
            }],
            [captureReferralDocuments, {
                required: when({
                    answersOf: [[haveYouGotReferralToHand, yesOption]],
                })
            }],

            // ASCENTI INFO
            [willYouSeeAscenti, {
                required: when({
                    answersOf: [[whatTypeOfTherapistDoYouNeed, physiotherapistOption]]
                })
            }],
            [yesWillSeeAscentiInfo, {
                required: when({
                    answersOf: [[willYouSeeAscenti, yesOption]]
                })
            }],
            [notSureIfWillSeeAscentiInfo, {
                required: when({
                    answersOf: [[willYouSeeAscenti, iDontKnowOption]]
                })
            }],

            // DO YOU KNOW THE COST
            [doYouKnowTheCareCost, {
                required: when({
                    anyAnswersOf: [
                        [whatBenefitAreYouChecking, consultationsBenefitOption],
                        [whatBenefitAreYouChecking, mentalHealthBenefitOption],
                        [whatBenefitAreYouChecking, therapiesBenefitOption]
                    ],
                    noAnswersOf: [
                        [willYouSeeAscenti, yesOption]
                    ]
                })
            }],
            [doYouKnowTheTreatmentCost, {
                required: when({
                    answersOf: [[whatBenefitAreYouChecking, hospitalCareBenefitOption]]
                })
            }],

            [whatIsTheTotalCost, {
                required: when({
                    anyAnswersOf: [[doYouKnowTheCareCost, yesOption], [doYouKnowTheTreatmentCost, yesOption]]
                })
            }],

            [existingConditionsInfo, requiredWhenNotCheckingDentalOrOptical],
            [excludedConditionsInfo, requiredWhenNotCheckingDentalOrOptical],

            [thanksCoverCheckEnquiryDone, requiredWhenNotCheckingDentalOrOptical],
        ]
    }

    get whoAreYouCheckingCoverFor(): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Hi ${policy.primaryLife.firstName}, who are you checking cover for today?`,
            embeddedOptions: whoOptions(policy.managedLivesThatAreAllowedToCheckCover)
        })
        question.id = QuestionIds['who-are-you-checking-cover-for']
        return question
    }


    get whatBenefitAreYouChecking(): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Which benefit do you need to check cover for?',
            optionsId: 'cover-checkable-benefits'
        })
        question.id = QuestionIds['what-benefit-are-you-checking']
        return question
    }

    get dentalCoverRemainingInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: this.coverRemainingAndExcessTemplateStringFor('DENTAL'),
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['dental-cover-remaining-info']
        question.dataModelProviders = [
            this.dataModelProviders.coverCheckCoverRemainingDataModelProvider(),
            this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()
        ]
        return question
    }


    get dentalCareCoveredListInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `Dental care - What's covered:
        
        • Routine and emergency dental treatment in a dental surgery by a NHS or private dental professional:
        \t• general dentist
        \t• periodontist
        \t• orthodontist
        \t• endodontist for root canal treatment
        \t• dental hygienist
        • Dental brace or gum shield provided by a dental surgeon or orthodontist
        • Dental crowns, bridges, fillings
        • Dentures or denture repairs / replacement by a dental technician
        • Dental X-rays
        • Laboratory fees and dental technician's fees if referred by a dental surgeon or orthodontist`,
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['dental-care-covered-list-info']
        return question
    }


    get dentalCareExcludedListInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `Dental care - What's not covered:
        
        • Dental prescription charges
        • Dental consumables, for example toothbrushes, mouthwash, dental floss
        • Cosmetic procedures, for example tooth whitening, dental veneers
        • Dental implants & bone augmentation, for example bone graft, sinus lift
        • Dental treatment provided as an in-patient or day-patient
        • Payments for any items or services supplied under dental insurance, cash plans or dental practice plans`,
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['dental-care-excluded-list-info']
        return question
    }


    get opticalCoverRemainingInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: this.coverRemainingAndExcessTemplateStringFor('OPTICAL'),
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['optical-cover-remaining-info']
        question.dataModelProviders = [
            this.dataModelProviders.coverCheckCoverRemainingDataModelProvider(),
            this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()
        ]
        return question
    }


    get opticalCareCoveredListInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `Optical care - What's covered:
    
    • Sight test fees
    • Spectacles, sunglasses, safety spectacles & swimming goggles with prescription lenses
    • Prescribed lenses fitted to existing frames
    • Repairs for spectacles
    • Contact lenses & cleaning materials or solutions supplied as part of a prescription
    • Corrective laser eye surgery`,
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['optical-care-covered-list-info']
        return question
    }


    get opticalCareExcludedListInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `Optical care - What's not covered:
    
    • Frames only
    • Non-prescription spectacles
    • Optical consumables, for example cases, spectacle chains, cords
    • Cleaning materials or solutions purchased in isolation
    • Payments for any items or services supplied under cash plans or optical plans`,
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['optical-care-excluded-list-info']
        return question
    }


    get earlyExitDental (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "As long as the dental care ${whoFor ? whoFor + ' needs' : 'you need'} is included in what's covered, you're good to go.  If you have any questions or are unsure, feel free to chat to us",
            embeddedOptions: doneOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['early-exit-dental']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get earlyExitOptical (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "As long as the optical care ${whoFor ? whoFor + ' needs' : 'you need'} is included in what's covered, you're good to go.  If you have any questions or are unsure, feel free to chat to us",
            embeddedOptions: doneOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['early-exit-optical']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get therapiesCoverRemainingInfo(): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: this.coverRemainingAndExcessTemplateStringFor('THERAPIES'),
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })

        question.id = QuestionIds['therapies-cover-remaining-info']

        question.dataModelProviders = [
            this.dataModelProviders.coverCheckCoverRemainingDataModelProvider(),
            this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()
        ]
        return question
    }


    get mentalHealthCoverRemainingInfo (): QuestionInitialiser {
        const anyMentalHealthBenefit = 'MENTAL_HEALTH_OUT_PATIENT'
        const question = () => buttonQuestion({
            id: question.id,
            text: '${whoFor ? whoFor + " has" : "You have"} *${MENTAL_HEALTH_OUT_PATIENT.coverRemaining}* of Mental Health cover for out-patient treatment and *${MENTAL_HEALTH_IN_PATIENT.coverRemaining}* for in-patient / day-patient treatment remaining until *${planRefreshDate}*' + this.outstandingExcessTemplatePartStringFor(anyMentalHealthBenefit),
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['mental-health-cover-remaining-info']
        question.dataModelProviders = [
            this.dataModelProviders.coverCheckCoverRemainingDataModelProvider(),
            this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()
        ]
        return question
    }


    get consultationsCoverRemainingInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: this.coverRemainingAndExcessTemplateStringFor('CONSULTATIONS_AND_DIAGNOSTICS'),
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['consultations-cover-remaining-info']
        question.dataModelProviders = [
            this.dataModelProviders.coverCheckCoverRemainingDataModelProvider(),
            this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()
        ]
        return question
    }


    get hospitalCareCoverRemainingInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: this.coverRemainingAndExcessTemplateStringFor('HOSPITAL_CARE'),
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['hospital-care-cover-remaining-info']
        question.dataModelProviders = [
            this.dataModelProviders.coverCheckCoverRemainingDataModelProvider(),
            this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()
        ]
        return question

    }

    get whatTypeOfTherapistDoYouNeed (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'What type of therapist do ${whoFor ? "they" : "you"} need to see?',
            embeddedOptions: therapistOptions
        })
        question.id = QuestionIds['what-type-of-therapist-do-you-need']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get whatTypeOfMentalHealthCareDoYouNeed (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'What care do ${whoFor ? "they" : "you" } need?',
            embeddedOptions: mentalHealthCareOptions
        })
        question.id = QuestionIds['what-type-of-mental-health-care-do-you-need']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get whatOtherMentalHealthCareDoYouNeed (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'Ok, tell us what ${whoFor ? "they" : "you"} need'
        })
        question.id = QuestionIds['what-other-mental-health-care-do-you-need']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get whatConditionAndSymptoms (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: "Ok. Now tell us what's up (e.g. ${whoFor ? 'their' : 'your'} symptoms, diagnosis if known) so we can understand why ${whoFor ? 'they' : 'you'} need to get care"
        })
        question.id = QuestionIds['what-conditions-and-symptoms']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get whenDidSymptomsStart (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const fourYearsPriorToPolicyStartDate = subYears(policy.startDate, 4)
            return fullDatePickerQuestion({
                id: question.id,
                text: 'And when did the symptoms start?  Approximate date is fine',
                minAccepted: fourYearsPriorToPolicyStartDate,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['when-did-symptoms-start']
        return question
    }


    get whatTreatmentOrTestsDoYouNeed (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'What treatment or tests do ${whoFor ? \'they\' : \'you\'} need?'
        })
        question.id = QuestionIds['what-treatment-or-tests-do-you-need']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get whatCareDoYouNeed (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: "What care do ${whoFor ? 'they' : 'you'} need?"
        })
        question.id = QuestionIds['what-care-do-you-need']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get haveYouBeenReferred (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: "Do${whoFor ? 'es ' + whoFor : ' you'} have a referral from a GP or specialist to get care?",
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['have-you-been-referred']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get haveYouGotReferralToHand (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Ok great! Do you have the referral to hand?',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['have-you-got-referral-to-hand']
        return question
    }


    get haveNotBeenReferredInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "Ok, don't worry. You can still continue, but you must have a referral before ${whoFor ? whoFor + ' gets' : 'you get'} care. We may ask for it to check ${whoFor ? 'they' : 'you'}'re covered or when you make a claim",
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['have-not-been-referred']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get referralNotToHandInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "Don't worry. You can still continue, but we may ask for it to check ${possessiveWhoFor ? possessiveWhoFor : 'you\\'re'} covered or when you make a claim",
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['referral-not-to-hand-info']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get youCanSelfRefer (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'You can self refer for up to 5 physiotherapy sessions per plan year with an Ascenti physio',
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['you-can-self-refer']
        return question
    }


    get willYouSeeAscenti (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Are you planning to see an Ascenti physio?',
            embeddedOptions: yesNoDontKnowOptions
        })
        question.id = QuestionIds['will-you-see-ascenti']
        return question
    }


    get yesWillSeeAscentiInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'Just so you know, as you are seeing an Ascenti physio you won\'t have to make a payment or file a claim',
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['yes-will-see-ascenti']
        return question
    }


    get notSureIfWillSeeAscentiInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'No problem! Just so you know, if you see an Ascenti physio you won\'t have to make a payment or file a claim',
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['not-sure-if-will-see-ascenti']
        return question
    }


    get prepareToCaptureReferralDocuments (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'Perfect! Now we just need a photo of the referral, please make sure you capture all pages',
            embeddedOptions: imReadyOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['prepare-to-capture-referral-docs']
        return question
    }

    get captureReferralDocuments (): QuestionInitialiser {
        const question = () => captureImagesQuestion({
            id: question.id,
            text: '',
            tags: [EnquiryDefinitionCommon.textractGeneralTag]
        })
        question.id = QuestionIds['capture-referral-docs']
        return question
    }


    get doYouKnowTheCareCost (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Do you know how much the care will cost?',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['do-you-know-the-care-cost']
        return question

    }

    get doYouKnowTheTreatmentCost (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Do you know how much the treatment or tests will cost?',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['do-you-know-the-treatment-cost']
        return question
    }


    get whatIsTheTotalCost (): QuestionInitialiser {
        const question = () => singleAnswerNumberQuestion({
            id: question.id,
            text: 'Great, how much will the total cost be?',
            tags: ['icon:Currency', 'iconSize:15', 'iconColor:black']
        })
        question.id = QuestionIds['what-is-the-total-cost']
        return question
    }


    get existingConditionsInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `Just so you know, we \${whoFor ? "won't" : "don't"} cover ${modalLinkMarkdown('pre-existing-conditions', 'pre-existing conditions')} that \${whoFor ? whoFor + ' has' : 'you have'} had in the last 3 years unless \${whoFor ? 'they' : 'you'} have been symptom & treatment free for 2 consecutive years after joining Peachy. We also don't cover treatment for ${modalLinkMarkdown('chronic-conditions', 'chronic conditions')}.`,
            modals: [{
                id: 'pre-existing-conditions',
                body: `A pre-existing condition is any medical condition (whether diagnosed or not) that you've received medication, advice or treatment for, have experienced symptoms of or were aware existed in the three years before the plan start date (or where applicable, the date you increased your benefit level or added a benefit to your plan)`,
                header: 'Pre-existing conditions'
            },
                {
                    id: 'chronic-conditions',
                    body: `A chronic condition is a disease, illness or injury that has one or more of the following characteristics: 
    
    1. It needs on-going or long-term monitoring through consultations, examinations, check-ups and/or tests
    
    2. It needs on-going or long-term control or relief of symptoms 
    
    3. It requires your rehabilitation or for you to be specially trained to cope with it 
    
    4. It continues indefinitely 
    
    5. It has no known cure 
    
    6. It comes back or is likely to come back
    
    Examples of chronic conditions include diabetes, asthma and multiple sclerosis. 
    
    We will cover treatment of an acute flare-up of a chronic condition providing this is not part of the normal recurring nature of the condition & is required to return you to your state of health immediately before the acute flare-up`,
                    header: 'Chronic conditions'
                }],
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['existing-conditions-info']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get excludedConditionsInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `We also don't cover a number of conditions or treatments specific to this cover.  Please see your ${browserLinkMarkdown('policy-doc', 'plan document')} for full terms & conditions and exclusions`,
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['excluded-conditions-info']
        return question
    }


    get thanksCoverCheckEnquiryDone (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'Great! Please take a moment to review this cover check before you submit',
            embeddedOptions: reviewSubmitOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['thanks-all-done']
        return question
    }

    private coverRemainingAndExcessTemplateStringFor(benefitType: BenefitType) { 
        const prettyBenefit = prettyPrintBenefit(benefitType)
        const templateText = '${whoFor ? whoFor + " has" : "You have"} *${'+ benefitType +'.coverRemaining}* of '+ prettyBenefit +' cover remaining until *${planRefreshDate}*' + this.outstandingExcessTemplatePartStringFor(benefitType)
        return templateText
    }

    private outstandingExcessTemplatePartStringFor(benefitType: BenefitType) { 
        const templateText = '${'+ benefitType +'.excessIsApplicable ? ", with a *" + excessOutstanding + "* excess (not including any pending claims)" : ""}'
        return templateText
    }
}

const requiredWhenNotCheckingDentalOrOptical = {
    required: when({
        noAnswersOf: [
            [QuestionIds['what-benefit-are-you-checking'], dentalBenefitOption],
            [QuestionIds['what-benefit-are-you-checking'], opticalBenefitOption]
        ]
    })
}