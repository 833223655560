import {Auth} from '@aws-amplify/auth'
import {Signer} from '@aws-amplify/core'
import { HttpMethod } from '@peachy/utility-kit-pure'

export class FunctionUrlClient {

    constructor(
        private readonly auth: typeof Auth,
        private readonly signer: typeof Signer) { 
    }

    async postLambda(url: string, requestBody?: any) {
        return this.fetchLambda('POST', url, requestBody)
    }

    async getLambda(url: string, requestBody?: any) {
        return this.fetchLambda('GET', url, requestBody)
    }

    async fetchLambda(method: HttpMethod, url: string, requestBody?: any, headers?: any) {
        const legalBody = canHaveBody(method) ? requestBody : undefined
        const urlAndParams = url + (requestBody && cantHaveBody(method) ? asQueryParams(requestBody) : '')

        let request: any = {}

        if (this.auth) {
            console.log('Attempting to sign request')
            request = await this.signedRequest(urlAndParams, method, 'lambda', 'eu-west-2', legalBody)
            console.log('Signed request')

        } else {
            request = {
                url: urlAndParams, 
                method, 
                data: legalBody ? JSON.stringify(legalBody) : undefined
            }
        }

        request = createFetchRequest(request, headers)

        const response = await fetch(request.url, request)

        // console.log('response', response)

        const getResponseBody = () => (response.headers.get('Content-Type') === 'application/json') ? response.json() : response.text()

        if (!response.ok) {
            throw await getResponseBody()
        }
        return getResponseBody()
    }


    private async signedRequest(url: string, method: HttpMethod, service: string, region: string, requestBody?: any) {
        const essentialCredentials = this.auth.essentialCredentials(await this.auth.currentCredentials())
        const params = {
            method,
            url,
            data: requestBody ? JSON.stringify(requestBody) : undefined
        }

        const credentials = {
            secret_key: essentialCredentials.secretAccessKey,
            access_key: essentialCredentials.accessKeyId,
            session_token: essentialCredentials.sessionToken,
        }
        const serviceInfo = {region, service}
        return this.signer.sign(params, credentials, serviceInfo)
    }

}

function canHaveBody(method: HttpMethod) {
    return ['POST', 'PUT', 'PATCH'].includes(method)
}

function cantHaveBody(method: HttpMethod) {
    return !canHaveBody(method)
}

function asQueryParams(params: Record<string, string | string[] | undefined>) {
    const keyValuePairs = params ? Object.entries(params).map( ([paramName, value]) => {
        const resolvedValue = value !== undefined && Array.isArray(value) ? value.join(',') : value
        return resolvedValue ? encodeURI(`${paramName}=${resolvedValue}`) : undefined
    }).filter(it => it !== undefined) : []
    return keyValuePairs.length > 0 ? '?' + keyValuePairs.join('&') : ''
}

function createFetchRequest (request: any, headers?: any) {
    return {
        ...request, 
        body: request.data,
        headers: {
            'content-type': 'application/json', // must be set to stop it being base64 encoded (and then the lambda not understanding it)
            ...request.headers,
            ...(headers ?? {})
        },
        data: undefined //now in body
    }
}