import {makeApiClient} from '@peachy/core-domain-client'
import {
    FetchAddressRequest,
    GeoLocationApi,
    GeoLocationApiDefinition,
    LookupAddressRequest
} from '@peachy/geo-location-pure'

import { Signer } from '@aws-amplify/core'

export type GeoLocationServiceClient = GeoLocationApi & {
    isFetching(): boolean
}

export function createGeoLocationServiceClient(servicePatchUri: string, signer: typeof Signer): GeoLocationServiceClient {
    let isFetching = false
    let api: GeoLocationApi
    const getOrCreateApi = async () => {
        api = api ?? await makeApiClient(GeoLocationApiDefinition, servicePatchUri, signer)
        return api
    }
    const jsonHeaders = {'content-type': 'application/json'}

    return {
        async lookupAddress(lookupRequest: LookupAddressRequest) {
            return (await getOrCreateApi()).lookupAddress(lookupRequest, jsonHeaders)
        },
        async fetchAddress(fetchRequest: FetchAddressRequest) {
            isFetching = true
            const response = (await getOrCreateApi()).fetchAddress(fetchRequest, jsonHeaders)
            isFetching = false
            return response
        },
        isFetching() {
            return isFetching
        }
    }
}
