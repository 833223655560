import { Life } from '../../domain/models/Life';
import { AnEvent } from "../event-kit";

export class PolicyDocChangesRequest extends AnEvent {
    public policyId: string
    public accountId?: string

    public addedLives?: Life[]
    public removedLives?: Life[]

    constructor(from: PolicyDocChangesRequest) {
        super()
        Object.assign(this, from)
    }
}