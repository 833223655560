import { Life, toClass } from "@peachy/core-domain-pure"
import { Component, For } from "solid-js"
import { getMemberId } from "../Members"
import { useAccountSubscription } from "../SubscriptionProvider"

export const CancelMembers: Component= () => {
    const accountSubscription = useAccountSubscription()
    const account = accountSubscription.account
    const subscription = accountSubscription.subscription
    
    const getLives = () => {
        
        if (!getMemberId()) {
            return account.type === 'COMPANY' ? subscription.policies.flatMap(p => Object.values(p.lives)) : Object.values(subscription.policies[0].lives)
        }
        
        // TODO Need to ask on subscription page for individual page logic
        const policy = subscription.policies.find(p => p.lives[getMemberId()]) ?? subscription.policies[0]
        const life = policy.lives[getMemberId()]

        if (life.type === 'PRIMARY') {
            // All lives for policy
            return Object.values(policy.lives)
        }

        return [life]
    }

    return (
        <div>
            <p>This will cancel the following members:</p>
            <ul>
                <For each={getLives().map(l => toClass(l, Life))}>
                    {(life) => <li>{life.fullName()}</li>}
                </For>
            </ul>
        </div>
    )
}