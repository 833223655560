import {createResource, createEffect, For, Show} from 'solid-js'
import {Expander} from '../Expander/Expander'
import styles from './TreeNode.module.css'
import {SimpleTreeResolver, Tag, TreeResolver} from './TreeResolver'
import {TreeProp} from './TreeProp'

export const NULL = 'Ø'

export type TreeNodeProps = {
    node: unknown
    treeResolver: TreeResolver
    showChildren?: boolean
    path?: Tag[]
}

export function TreeNode(props: TreeNodeProps) {
    const treeResolver = () => props.treeResolver ?? SimpleTreeResolver

    const [resolved, trigger] = createResource(async () => {
        return treeResolver().resolveChildren(await props.node)
    })

    createEffect(() => {
        props.node
        trigger.refetch()
    })

    return (
        <Show when={resolved.latest}>
            <Expander expanded={props.showChildren} tag={'ul'} class={styles.TreeNode}>
                <TreeNodeExpanded node={resolved()} treeResolver={props.treeResolver} path={props.path ?? []}/>
            </Expander>
        </Show>
    )
}

export function TreeNodeExpanded(props: TreeNodeProps) {
    type K = keyof typeof props.node
    return (
        <For each={Object.keys(props.node)}>{(tag: K) =>
            <TreeProp
                tag={tag}
                value={props.node[tag]}
                node={props.node}
                treeResolver={props.treeResolver}
                path={[...props.path, tag]}
            />}
        </For>
    )
}

