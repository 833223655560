import {useParams} from '@solidjs/router'
import {ClaimDetails} from './ClaimDetails'
import { useNav } from '../../../page-components/Dashboard/Dashboard'

export function ViewClaim() {

    useNav().hide()

    //todo why isnt useParams reactive?
    const {claimId, customerId} = useParams()

    return (
        <ClaimDetails claimId={claimId}/>
    )
}
