import {Api, defineApi, PolicyDocRequest, postOf} from '@peachy/core-domain-pure'
import {AdminUserGroup, DashboardGroups} from './user-admin-domain'


const DocsAdminApiName = 'DocsAdminApi'

export const DocAdminApiDef = defineApi(DocsAdminApiName)
    .forGroups(DashboardGroups)
    .withActions({
        reissuePolicyDocs: postOf(PolicyDocRequest).fromGroups(AdminUserGroup).willReturn(Object),
    })

export type DocAdminApi = Api<typeof DocAdminApiDef>