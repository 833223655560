import { PropertiesOnly } from "@peachy/utility-kit-pure"
import { ClaimActivitySubmissionReason } from "./ClaimActivitySubmissionReason"
import { ClaimInvoiceLineItem } from "./ClaimInvoiceLineItem"
import { HospitalAdmission } from "./HospitalAdmission"
import { assign } from "lodash-es"
import { CoverCheckRequestedTreatment } from "./CoverCheckRequestedTreatment"

export class ClaimAssessment {

    readonly submissionReasons: ClaimActivitySubmissionReason[] = []
    readonly hospitalAdmissions: HospitalAdmission[] = []
    readonly invoiceLineItems: ClaimInvoiceLineItem[] = []
    readonly requestedTreatments: CoverCheckRequestedTreatment[] = []
    readonly referralDate?: Date

    readonly linkedClaimActivityIds: string[] = []

    
    constructor(props: PropertiesOnly<ClaimAssessment>) {
        assign(this, props)
    }

    public getHospitalAdmission(id: string) {
        return this.hospitalAdmissions.find(it => it.id === id)
    }
    
}