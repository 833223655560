import {PlanService} from '../PlanService'
import {ManagedLifeRepository} from '@peachy/flash-repo-peachy-client'
import {BenefitType, Enquiry, Question} from '@peachy/repo-domain'
import {EnquiryReader} from './EnquiryReader'
import {currencyFromPence, possessiveNoun, readableDate} from '@peachy/utility-kit-pure'
import {subDays} from 'date-fns'
import {transform} from 'lodash-es'

export class EnquiryQuestionDataModelProviders {

    constructor(protected readonly planService: PlanService,
                protected readonly managedLifeRepository: ManagedLifeRepository) {}


    coverCheckCoverRemainingDataModelProvider = () => async (enquiry: Enquiry, question: Question) => {
        const enquiryReader = EnquiryReader.forClaimActivity(enquiry)
        const treatmentReceiverId = enquiryReader.extractTreatmentReceiverLifeIdFrom(enquiry)
        const currentPlanYear = await this.planService.getCurrentPlanYearFor(treatmentReceiverId)
        
        const data = {
            planRefreshDate: currentPlanYear ? readableDate(subDays(currentPlanYear.end, 1)) : undefined,
            excessOutstanding: currencyFromPence(currentPlanYear?.excess?.amountOutstandingInPence),
            ...transform(currentPlanYear.coverCheckableBenefits, (collector, benefit) => {
                collector[benefit.type] = {
                    excessIsApplicable: !!currentPlanYear.getExcessApplicableTo(benefit.type),
                    coverRemaining: currencyFromPence(benefit.coverRemainingInPence)
                }
                return collector
            }, {} as Record<BenefitType, any>)
        }

        return data
    }

    whoIfNotPrimaryLifeDataModelProvider = () => async (enquiry: Enquiry, question: Question) => {
        const whoForId = await EnquiryReader.getWhoForIdLifeNameIfNotPrimaryLife(enquiry)
        const whoForLifeIfNotPrimary = await this.managedLifeRepository.find(it => it.id === whoForId && !it.isPrimary)
        const whoFor = whoForLifeIfNotPrimary?.firstName
        return {whoFor, possessiveWhoFor: possessiveNoun(whoFor)}
    }
}