import {createEffect, createSignal, For, Show} from 'solid-js'
import styles from './Chat.module.css'
import {httpMessageHandler} from './HttpMessageHandler'
import {createChatApi, Message} from './ChatApi'
import {Modal} from '@peachy/client-kit'
import {TextArea} from '../../../components/TextBox/TextArea'
import {useNav} from '../../../page-components/Dashboard/Dashboard'
import {Flex} from '../../../components/deprecated/Flex/Flex'

// TODO remove hardcoding endpoint
// https://kbgt2rapzkxsmydcu6maohg4ty0pkyvd.lambda-url.eu-west-2.on.aws/
// const httpEndpoint = 'https://z9wfm564q2.execute-api.eu-west-2.amazonaws.com/demo/ai/chat/message'
const httpEndpoint = 'https://lpnn4admz5.execute-api.eu-west-2.amazonaws.com/default/Demo1-ClaimsActivityStack-handleVitalityRequest'

const demoCustomerDetails = 'Age: 35\n' +
    'Policy Type: Moratorium (no additional exclusions)\n' +
    'Benefits included: Full cover, including full cancel care and ALL optional benefits\n' +
    'Policy start date: 2023-01-01`'

export default function VitalityChat() {
    const nav = useNav()
    nav.hide()
    const savedHistory = localStorage.getItem('chat-history')
    let savedCustomerDetails = localStorage.getItem('chat-customer-details')
    // TODO tidy
    if (!savedCustomerDetails) {
        localStorage.setItem('chat-customer-details', JSON.stringify(demoCustomerDetails))
        savedCustomerDetails = JSON.stringify(demoCustomerDetails)
    }
    console.log('savedHistory', savedHistory)
    const [history, setHistory] = createSignal<Message[]>(savedHistory ? JSON.parse(savedHistory) : [])
    const [isLoading, setIsLoading] = createSignal<boolean>(false)
    const [customerDetails, setCustomerDetails] = createSignal(JSON.parse(savedCustomerDetails))
    let chatHistory: HTMLDivElement
    createEffect(() => {
        isLoading()
        history()
        chatHistory.scrollTop = chatHistory.scrollHeight
    })
    const updateCustomerDetails = (details: string) => {
        setCustomerDetails(details)
        localStorage.setItem('chat-customer-details', JSON.stringify(details))
    }
    const chatApi = createChatApi({
        getHistory: history,
        setHistory: messages => {
            setHistory(messages)
            console.log('setting history', messages)
            localStorage.setItem('chat-history', JSON.stringify(messages))
        },
        messageHandler: httpMessageHandler({
            host: httpEndpoint,
            preSend: _message => ({
                customerDetails: customerDetails(),
                history: history()
            }),
            onLoading: () => {
                if (history().length) {
                    setTimeout(() => {
                        setIsLoading(true)
                    }, 1000)
                }
            },
            onLoadingComplete: () => {
                setIsLoading(false)
            }
        })
    })
    if (history().length === 0) {
        void chatApi.init()
    }
    const clearHistory = async () => {
        setHistory([])
        await chatApi.init()
        localStorage.setItem('chat-history', JSON.stringify([]))
    }
    return <div class={styles.Chat}>
        <ChatHistory ref={chatHistory} history={history} isLoading={isLoading}></ChatHistory>
        <ChatInput sendMessage={chatApi.send}></ChatInput>
        <Flex row justifyContentEnd>
            <button onClick={clearHistory} class={styles.OtherButton}>Clear Chat</button>
            <ChatCustomerDetails content={customerDetails} setContent={updateCustomerDetails}></ChatCustomerDetails>
        </Flex>
    </div>
}

function ChatInput({ sendMessage }: { sendMessage: (m: string) => void }) {
    const [content, setContent] = createSignal('')
    const onEnter = () => {
        if (content().trim().length != 0) {
            sendMessage(content())
            setContent('')
        }
    }
    return <div class={styles.TextAreaWithSendButton}>
        <TextArea value={content()} onChange={setContent} onEnter={onEnter}/>
        <i onClick={onEnter} class="fa-solid fa-paper-plane-top"></i>
    </div>
}

function ChatHistory({history, isLoading, ref}: {
    history: () => Message[],
    isLoading: () => boolean,
    ref: HTMLDivElement
}) {
    return <div ref={ref} class={styles.ChatHistory}>
        <For each={history()}>{message =>
            <>
                <Show when={message.direction === 'incoming'}>
                    <IncomingMessage>{message.text}</IncomingMessage>
                </Show>
                <Show when={message.direction === 'outgoing'}>
                    <OutgoingMessage>{message.text}</OutgoingMessage>
                </Show>
                <br/>
            </>
        }</For>
        <Show when={isLoading()}>
            <IncomingMessage>
                <TypingSpinner/>
            </IncomingMessage>
            <br/>
        </Show>
    </div>
}

function IncomingMessage(props) {
    return <div class={styles.IncomingMessageRow}>
        <div class={styles.IncomingMessage}>
            {props.children}
        </div>
    </div>
}

function OutgoingMessage(props) {
    return <div class={styles.OutgoingMessageRow}>
        <div class={styles.OutgoingMessage}>
            {props.children}
        </div>
    </div>
}

function ChatCustomerDetails(props) {
    const [localContent, setLocalContent] = createSignal(props.content())
    const [isOpen, setIsOpen] = createSignal(false)
    const save = () => {
        props.setContent(localContent())
        setIsOpen(false)
    }
    return <>
        <button class={styles.OtherButton} onClick={() => setIsOpen(true)}>Customer details</button>
        <Modal isOpen={isOpen()} class={styles.modal} onDismiss={() => setIsOpen(false)}>
            <h3>Customer details</h3>
            <TextArea class={styles.CustomerDetailsInput} value={localContent()} onChange={setLocalContent}></TextArea>
            <button onClick={save}>Save</button>
        </Modal>
    </>
}

function TypingSpinner() {
    const Dot = () => <div class={styles.Dot}></div>
    return <div class={styles.TypingSpinner}>
        <Dot/>
        <Dot/>
        <Dot/>
    </div>
}