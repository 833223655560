import {KeyMapped} from '../some-types'
import {Life} from './Life'
import {keysOf, newUUID} from '@peachy/utility-kit-pure'
import {toClass} from '../validation/validation-core'

export type PolicyStatus =
    | 'DRAFT'
    | 'ACTIVE'
    | 'SUSPENDED'
    | 'CANCELLED'

export const PolicyStatuses = keysOf<PolicyStatus>()

export function policyKey(policyId: string) {
    return `peachy/policy/${policyId}`
}

export class Policy {
    id: string
    status: PolicyStatus = 'DRAFT'
    startDate: number
    lives: KeyMapped<Life>

    totalMonthlyPremium: number = 0


    cancelledAt?: number
    cancellationReason?: string


    getPrimaryLife?(): Life {
        const life = Object.values(this.lives).find(l => l.type === 'PRIMARY')
        return toClass(life, Life)
    }

    getSecondaryLife?(): Life {
        const life = Object.values(this.lives).find(l => l.type === 'SECONDARY')
        return toClass(life, Life)
    }

    getDependents?(): Life[] {
        const lives = Object.values(this.lives).filter(l => l.type === 'DEPENDANT')
        return lives.map(l => toClass(l, Life))
    }

    getAppCustomers?(): Life[] {
        const lives = Object.values(this.lives).filter(l => l.type === 'PRIMARY' || l.type === 'SECONDARY')
        return lives.map(l => toClass(l, Life))
    }

    getNonPrimaryLives?(): Life[] {
        const lives = Object.values(this.lives).filter(l => l.type !== 'PRIMARY')
        return lives.map(l => toClass(l, Life))
    }

    getLifeByCognitoUserId?(cognitoUserId: string) {
        const life = Object.values(this.lives).find(it => it.cognitoUserId === cognitoUserId)
        return toClass(life, Life)
    }
}




// repeatable uuid
export function generatePolicyId(subscriptionId: string, lives: KeyMapped<Life>) {
    const lifeSeed = Object.values(lives).map(l => l.email).join()
    return newUUID('policy' + subscriptionId + lifeSeed)
}
