import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {
    BenefitType, defaultBenefitLimits,
    DraftAccount,
    DraftSubscription,
    Life,
    PaymentDetails,
    Plan,
    PlanBenefit,
    Policy,
    SubscriptionRequest,
    validate
} from '@peachy/core-domain-pure'
import {Draft, newUUID} from '@peachy/utility-kit-pure'
import * as dates from 'date-fns'


export class GroupSubscriptionRequestStore {

    private subscriptionRequest: Store<Draft<SubscriptionRequest>>
    private storeSubscriptionRequest: SetStoreFunction<Draft<SubscriptionRequest>>



    private benefitTemplates: Store<PlanBenefit[]>
    private storeBenefitTemplates: SetStoreFunction<PlanBenefit[]>

    constructor() {
        [this.subscriptionRequest, this.storeSubscriptionRequest] = createStore<Draft<SubscriptionRequest>>(createSubscriptionRequest())
        ;[this.benefitTemplates, this.storeBenefitTemplates] = createStore<PlanBenefit[]>(createBenefitTemplates())
    }

    public reset() {
        this.storeSubscriptionRequest(createSubscriptionRequest())
        this.storeBenefitTemplates(createBenefitTemplates())
    }

    public getSubscriptionRequest() {
        return this.subscriptionRequest
    }

    public isValidSubscriptionRequest() {
        return this.hasValidAccountDetails() && this.hasValidPlan() && this.hasValidLives()
    }

    public hasValidAccountDetails() {
        return !validate(this.subscriptionRequest.account, null, DraftAccount)
    }

    public hasValidPlan() {
        return this.hasValidStartDate() && (
            this.hasBenefit('HOSPITAL_CARE') || this.hasBenefit('CONSULTATIONS_DIAGNOSTICS')
        )
    }

    public getPlan() {
        return this.subscriptionRequest.plans[0]
    }

    public setStartDate(startDate: number) {
        this.storeSubscriptionRequest('subscription', 'startDate', startDate)
        this.getSubscriptionRequest().policies.forEach((p, i) => {
            this.storeSubscriptionRequest('policies', i, 'startDate', startDate)
        })
    }

    public hasValidStartDate() {
        const startDate = new Date(this.subscriptionRequest.subscription.startDate ?? 0)
        return (dates.isToday(startDate) || dates.isAfter(startDate, new Date()))
    }

    public hasBenefit(type: BenefitType): boolean {
        return !!this.getBenefit(type)
    }

    public getBenefit(type: BenefitType): PlanBenefit {
        return this.subscriptionRequest.plans[0].benefits.find(b => b.type === type) as PlanBenefit
    }

    public getBenefitTemplate(type: BenefitType): PlanBenefit {
        return this.benefitTemplates.find(b => b.type === type)
    }

    public toggleBenefit(type: BenefitType) {
        const benefits = this.subscriptionRequest.plans[0].benefits
        const existingBenefit = this.getBenefit(type)
        if (existingBenefit) {
            this.storeSubscriptionRequest('plans', 0, 'benefits', benefits.filter(b => b.type !== type))
        } else {
            this.storeSubscriptionRequest('plans', 0, 'benefits', [...benefits, this.getBenefitTemplate(type)])
        }
    }

    public hasBenefitLimit(type: BenefitType): boolean {
        return this.getBenefitLimit(type) !== undefined
    }

    public getBenefitLimit(type: BenefitType): number {
        return this.getBenefitTemplate(type)?.limit
    }

    public updateBenefitLimit(type: BenefitType, limit: number) {
        const templateIndex = this.benefitTemplates.findIndex(b => b.type === type)
        const benefitIndex = this.subscriptionRequest.plans[0].benefits.findIndex(b => b.type === type)
        this.storeBenefitTemplates(templateIndex, 'limit', limit)
        this.storeSubscriptionRequest('plans', 0, 'benefits', benefitIndex, 'limit', limit)
    }


    public getAccountDetails() {
        return this.subscriptionRequest.account
    }

    public updateAccountName(accountName: string) {
        this.storeSubscriptionRequest('account', 'name', accountName)
        this.storeSubscriptionRequest('subscription', 'name', accountName)
    }

    public updateContactName(contactName: string) {
        this.storeSubscriptionRequest('account', 'contactName', contactName)
        this.storeSubscriptionRequest('subscription', 'contactName', contactName)
    }

    public updateContactEmail(contactEmail: string) {
        this.storeSubscriptionRequest('account', 'contactEmail', contactEmail)
        this.storeSubscriptionRequest('subscription', 'contactEmail', contactEmail)
    }

    public addLife() {
        const planId = this.subscriptionRequest.plans[0].id
        const startDate = this.subscriptionRequest.subscription.startDate
        const currentPolicies = this.subscriptionRequest.policies
        const newPolicies = [...currentPolicies, createDraftPolicy(startDate, planId)]
        this.storeSubscriptionRequest('policies', newPolicies)
    }

    public updateLife(id: string, life: Draft<Life>) {
        const policyIndex = this.subscriptionRequest.policies.findIndex(p => !!p.lives[id])
        this.storeSubscriptionRequest('policies', policyIndex, 'lives', id, life)
    }

    public removeLife(id: string) {
        const policyIndex = this.subscriptionRequest.policies.findIndex(p => !!p.lives[id])
        const newPolicies = [...this.subscriptionRequest.policies]
        newPolicies.splice(policyIndex, 1)
        this.storeSubscriptionRequest('policies', newPolicies)
    }

    public getLives(): Draft<Life>[] {
        return this.subscriptionRequest.policies.map(p => Object.values(p.lives)).flat()
    }

    public isValidLife(life: Draft<Life>) {
        return !validate(life, null, Life)
    }

    public hasValidLives() {
        const lives = this.getLives()
        return lives.every(l => this.isValidLife(l))
    }

    public getPromoCode(): string {
        return this.subscriptionRequest.subscription.paymentDetails.promoCode
    }

    public setPromoCode(promoCode: string) {
        this.storeSubscriptionRequest('subscription', 'paymentDetails', 'promoCode', promoCode)
    }
}



function createSubscriptionRequest(): Draft<SubscriptionRequest> {
    const plan = createDraftPlan()
    const startDate = Date.now()
    const policy = createDraftPolicy(startDate, plan.id)
    return {
        account: createDraftAccount(),
        subscription: createDraftSubscription(startDate),
        plans: [plan],
        policies: [policy],
    }
}


function createDraftPolicy(startDate: number, planId: string): Draft<Policy> {
    const life = createDraftLife(planId)
    return {
        id: newUUID(),
        startDate,
        lives: {
            [life.id]: life
        }
    }
}


function createDraftAccount(): DraftAccount {
    return {
        user: undefined,
        company: undefined,
        name: '',
        contactName: '',
        contactEmail: '',
        type: 'GROUP',
    }
}


function createDraftSubscription(startDate: number): DraftSubscription {
    return {
        id: newUUID(),
        name: '',
        contactName: '',
        contactEmail: '',
        paymentDetails: {
            promoCode: ''
        } as PaymentDetails,
        startDate,
    }
}

function createDraftPlan(): Plan {
    return {
        id: newUUID(),
        benefits: [],
        name: 'Group Plan'
    }
}


function createDraftLife(planId: string): Draft<Life> {
    return {
        id: newUUID(),
        type: 'PRIMARY',
        firstname: '',
        lastname: '',
        dateOfBirth: null,
        gender: null,
        email: '',
        address: null,
        postcode: '',
        planId,
    }
}


function createBenefitTemplates(): PlanBenefit[] {
    return [
        {
            type: 'HOSPITAL_CARE',
            limit: defaultBenefitLimits.HOSPITAL_CARE
        },
        {
            type: 'CONSULTATIONS_DIAGNOSTICS',
            limit: defaultBenefitLimits.CONSULTATIONS_DIAGNOSTICS
        },
        {
            type: 'THERAPIES',
        },
        {
            type: 'DENTAL',
        },
    ]
}