import styles from './RepoNodeEditor.module.css'
import {Flash, FlashValue, HardHash, isHardHash, isSoftHash, Tag} from '@peachy/flash-repo-pure'
import {createToggleSignal, NULL, PopupListSelector, Toggle2, TreeResolver, useOnEnterKey} from '@peachy/client-kit'
import {createComputed, createSignal, Match, Show, Switch} from 'solid-js'
import {isArray, isBoolean, isNullish, isNumber, isObject, isString, last} from '@peachy/utility-kit-pure'
import {useRepoContext} from '../../RepoController'
import {ElementEditor} from './ElementEditor'

export type RepoNodeEditorProps = {
    path: Tag[]
    value: unknown
    treeResolver: TreeResolver<FlashValue, Flash>
    onUpdate?: () => void
}


const DataTypes = [
    'string',
    'number',
    'null',
    'boolean',
    'hash',
] as const

type DataType = typeof DataTypes[number]


export function RepoNodeEditor(props: RepoNodeEditorProps) {
    const repoContext = useRepoContext()
    const repo = repoContext.repo

    const [dataType, setDataType] = createSignal<DataType>(getDataType(props.value))

    const [newValue, setNewValue] = createSignal<unknown>(props.value)

    const buttonClass = () => {
        return isValid(newValue(), dataType()) ? styles.validButton : styles.invalidButton
    }

    const onSave = async () => {
        if (isValid(newValue(), dataType())) {
            const node = await repo.getNode(props.path)
            await node(newValue())
            props?.onUpdate()
        }
    }

    useOnEnterKey(async () => {
        console.log('wha gwan?')
        await onSave()
    })


    return (
        <div class={styles.RepoNodeEditor}>
            <header class={styles.PropEditor}>
                <span class={styles.tag}>{last(props.path)}</span>
                <span>:</span>
                <DataTypeControl dataType={dataType()} setDataType={setDataType} tag={last(props.path)}/>
                <ValueEditor value={props.value} onChange={setNewValue} dataType={dataType()}
                             treeResolver={props.treeResolver}/>
            </header>

            <Show when={dataType() === 'hash'}>
                <ElementEditor treeResolver={props.treeResolver} value={props.value} onChange={setNewValue}/>
            </Show>

            <button class={buttonClass()} onClick={onSave}>Save</button>
        </div>
    )
}


type DataTypeControlProps = {
    tag: Tag
    dataType: DataType
    setDataType: (dataType: DataType) => void
}

export function DataTypeControl(props: DataTypeControlProps) {
    const [showSelector, toggleSelector] = createToggleSignal(false)

    const onSelect = (dataType: DataType) => {
        toggleSelector()
        props.setDataType(dataType)
    }

    let displayRef: HTMLSpanElement
    return (
        <div>
            <span ref={displayRef} onClick={toggleSelector} class={styles.type}>&lt;{props.dataType}&gt;</span>
            <PopupListSelector
                list={DataTypes}
                onSelect={onSelect}
                onDismiss={toggleSelector}
                selection={props.dataType}
                enabled={showSelector()}
                locatorElement={displayRef}
                center={true}
                appearance={{
                    list: styles.dataTypeList,
                    listItem: styles.dataTypeListItem,
                    selectedItem: styles.dataTypeSelectedListItem,
                }}
            />
        </div>
    )
}


type ValueEditorProps = {
    treeResolver: TreeResolver
    value: unknown
    onChange: (newValue: unknown) => void
    dataType: DataType
}

export type TypeEditorProps = Omit<ValueEditorProps, 'dataType'>


function ValueEditor(props: ValueEditorProps) {

    createComputed(() => {
        switch (props.dataType) {
            case 'null':
                return props.onChange(null)
            case 'boolean':
                return props.onChange(!!props.value)
            case 'string':
                return props.onChange(`${props.value}`)
            case 'number':
                return props.onChange(+props.value ?? 0)
            case 'hash':
                return !isHardHash(props.value) && (isSoftHash(props.value) ? props.onChange(new HardHash(props.value)) : props.onChange(null))
        }
    })

    return (
        <div class={styles.ValueEditor}>
            <Switch>
                <Match when={props.dataType === 'null'}>
                    {() => <NullEditor/>}
                </Match>
                <Match when={props.dataType === 'hash'}>
                    <HashEditor value={props.value} onChange={props.onChange} treeResolver={props.treeResolver}/>
                </Match>
                <Match when={props.dataType === 'string'}>
                    <StringEditor value={props.value} onChange={props.onChange} treeResolver={props.treeResolver}/>
                </Match>
                <Match when={props.dataType === 'number'}>
                    <NumberEditor value={props.value} onChange={props.onChange} treeResolver={props.treeResolver}/>
                </Match>
                <Match when={props.dataType === 'boolean'}>
                    <BooleanEditor value={props.value} onChange={props.onChange} treeResolver={props.treeResolver}/>
                </Match>
            </Switch>

        </div>
    )
}


function isValid(value: any, dataType: DataType) {

    switch (dataType) {
        case 'null':
            return isNullish(value)
        case 'boolean':
            return isBoolean(value)
        case 'string':
            return isString(value) && value.length
        case 'number':
            return isNumber(value)
        case 'hash':
            return isNullish(value) || isSoftHash(value.toString()) || isArray(value) || isObject(value)
    }
}


function getDataType(value: unknown): DataType {
    if (isNullish(value)) return 'null'
    if (isNumber(value)) return 'number'
    if (isBoolean(value)) return 'boolean'
    if (isHardHash(value)) return 'hash'
    if (isString(value)) return 'string'
    throw `Unexpected data type for value ${value}`
}


function BooleanEditor(props: TypeEditorProps) {
    return <Toggle2 isOn={!!props.value} onToggle={props.onChange} class={styles.toggle}/>
}

function StringEditor(props: TypeEditorProps) {
    return <input type={'text'} value={`${props.value ?? ''}`}
                  onInput={(e) => props.onChange(e.currentTarget.value.trim())}/>
}

function NumberEditor(props: TypeEditorProps) {
    return <input type={'number'} value={`${props.value}`}
                  onInput={(e) => props.onChange(e.currentTarget.value.trim() ? +e.currentTarget.value.trim() : null)}/>
}

function HashEditor(props: TypeEditorProps) {
    return <input type={'text'} value={`${props.value ?? ''}`}
                  onInput={e => props.onChange(new HardHash(e.currentTarget.value.trim()))}/>
}
function NullEditor() {
    return <span>{NULL}</span>
}

