import { AscentiAppointmentBookOut, AscentiAppointmentCancelOut, AscentiAppointmentHoldOut, AscentiInjury, SerialisedAscentiAppointment } from "@peachy/ascenti-client"
import { GeoLocationServiceClient } from "@peachy/client-kit/src/services/geo-location/GeoLocationServiceClient"
import { SyncRequest, SyncResponse } from "@peachy/flash-repo-pure"
import { PeachyPaymentCard } from "@peachy/payments-pure"
import { ClaimStage } from "@peachy/repo-domain"

export type IdCheckRequest = {
    firstname: string
    lastname: string
    birthdate: string
    selfie: string
    photoId: string
}

export type SubmitClaimsActivity = {
    id: string
    stage: ClaimStage
    reference: string
}

export type SubmitClaimsActivityResponse = {
    message: {id: string}
    conversation: {id?: string},
}

export interface IPeachyClient {
    getMediaLinksForClaimsActivity(customerAwsSub: string, claimId: string): Promise<string[]>

    raiseClaimsActivityTicket(details: SubmitClaimsActivity): Promise<SubmitClaimsActivityResponse>

    commentOnClaimsActivity(claimId: string, message: string): Promise<any> // Update with the actual response type

    getIntercomUser(platformOs: string): Promise<{ hash: string }>

    searchGps(searchTerm: string, postcode: string, limit?: number): Promise<any> // Update with the actual response type

    getAscentiAppointmentAvailability(clinicId: number, therapistId?: number): Promise<SerialisedAscentiAppointment[]>

    holdAscentiAppointment(clinicId: number, therapistId: number, dateTime: Date, length: number): Promise<AscentiAppointmentHoldOut>

    bookHeldAscentiAppointment(ascentiAppointmentId: number, whoForId: string, acceptTerms: boolean, injury: AscentiInjury): Promise<AscentiAppointmentBookOut>

    cancelAscentiAppointment(ascentiAppointmentId: number, cancellationToken: string, caseReference: string, caseId: number): Promise<AscentiAppointmentCancelOut>

    searchMarketplace(searchQuery: any): Promise<any> // Update with the actual response type

    syncRemoteRepo(syncRequest: SyncRequest): Promise<SyncResponse>

    adminSyncRemoteRepo(sub: string, syncRequest: SyncRequest): Promise<any> // Update with the actual response type

    stagePolicyDocGen(): Promise<{ ticketId: string }>

    buildFetchStagedDocUrl(stagedTicketId: string): Promise<string>

    getCustomerPaymentCards(): Promise<PeachyPaymentCard[]>

    submitIdCheck(request: IdCheckRequest): Promise<any> // Update with the actual response type

    exchangeNhsLoginCodeForJwtToken(code: string, codeVerifier: string, redirectUri: string): Promise<string>

    resolveAwsUserContentBucket(): Promise<any> // Update with the actual response type

    getThirdPartyUriFor(sub: string, endpoint: 'stripeCustomer' | 'intercomContact'): Promise<any> // Update with the actual response type
    
    getGeolocationApi(): GeoLocationServiceClient
    
    uploadCustomerContent(lifeId: string, pathIncludingInitialSlash: string, fileName: string, content: Blob, contentType: string, tagging?: string): Promise<any> // Update with the actual response type
}