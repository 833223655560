import styles from './GroupSubscriptionPanel.module.css'

import {Spinner} from '../../../components/Spinner/Spinner'
import {GroupLives} from './GroupLives/GroupLives'
import {GroupPlan} from './GroupPlan/GroupPlan'
import {createContext, createSignal, useContext} from 'solid-js'
import {AccountDetails} from './AccountDetails/AccountDetails'
import {dump} from '@peachy/utility-kit-pure'
import {GroupSubscriptionRequestStore} from './models/GroupSubscriptionRequestStore'
import {useSubscriptionService} from '../../../controllers/DashboardServiceController'
import {SubscriptionRequest} from '@peachy/core-domain-pure'
import {Card} from "../../../components/Card/Card";
import {Modal} from '@peachy/client-kit'


const GroupSubscriptionContext = createContext<GroupSubscriptionRequestStore>()

export default function GroupSubscriptionPanel() {
    const store = new GroupSubscriptionRequestStore()

    const [showDoneModal, setShowDoneModal] = createSignal(false)

    const subscriptionService = useSubscriptionService()

    const onSubscribe = async () => {
        dump(store.getSubscriptionRequest())
        const response = await subscriptionService.setupGroupSubscription(store.getSubscriptionRequest() as SubscriptionRequest)
        setShowDoneModal(true)
        dump(response, 'Response')
        store.reset()
    }

    return (
        <GroupSubscriptionContext.Provider value={store}>
            <div class={styles.GroupSubscriptionPanel}>
                <h2>Create group subscription</h2>
                <AccountDetails/>
                <GroupPlan/>
                <GroupLives/>
                <button onClick={onSubscribe} disabled={!store.isValidSubscriptionRequest()}>Create group subscription &rarr;</button>
                <Spinner isOpen={subscriptionService.isBusy()}/>
                <Modal isOpen={showDoneModal()} onDismiss={() => setShowDoneModal(false)}>
                    <Card>
                        <p>Done!</p>
                        <button onClick={() => setShowDoneModal(false)}>OK</button>
                    </Card>
                </Modal>
            </div>
        </GroupSubscriptionContext.Provider>
    )
}


export function useGroupSubscriptionRequestStore(): GroupSubscriptionRequestStore {
    return useContext(GroupSubscriptionContext)
}
