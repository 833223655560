import {Accessor, Show} from 'solid-js'
import styles from './Chat.module.css'
import {Canvas, useFrame} from 'solid-three'
import {useGLTF} from 'solid-drei'
import {GLTF} from 'three-stdlib'
import * as THREE from 'three'



type GLTFResult = GLTF & {
    nodes: {
        Hips: THREE.Bone
        EyeLeft: THREE.SkinnedMesh
        EyeRight: THREE.SkinnedMesh
        Wolf3D_Head: THREE.SkinnedMesh
        Wolf3D_Teeth: THREE.SkinnedMesh
        Wolf3D_Hair: THREE.SkinnedMesh
        Wolf3D_Glasses: THREE.SkinnedMesh
        Wolf3D_Outfit_Bottom: THREE.SkinnedMesh
        Wolf3D_Outfit_Footwear: THREE.SkinnedMesh
        Wolf3D_Outfit_Top: THREE.SkinnedMesh
    }
    materials: {
        Wolf3D_Eye: THREE.MeshStandardMaterial
        Wolf3D_Skin: THREE.MeshStandardMaterial
        Wolf3D_Teeth: THREE.MeshStandardMaterial
        Wolf3D_Hair: THREE.MeshStandardMaterial
        Wolf3D_Glasses: THREE.MeshStandardMaterial
        Wolf3D_Outfit_Bottom: THREE.MeshStandardMaterial
        Wolf3D_Outfit_Footwear: THREE.MeshStandardMaterial
        Wolf3D_Outfit_Top: THREE.MeshStandardMaterial
    }
}

export type AudioAlignment = {
    characters: string[]
    character_start_times_seconds: number[]
    character_end_times_seconds: number[]
}


export function Scene({ audio, audioAlignment }: { audio: Accessor<HTMLAudioElement>, audioAlignment: Accessor<AudioAlignment> }) {
    return <div class={styles.Scene}>
        <Canvas shadows gl={{antialias: true}} height={"286px"} width={"430px"}
                camera={{position: [0, 1.7, 0.4], rotation: [0, 0, 0]}}>
            <ambientLight intensity={0.4}/>
            <spotLight castShadow position={[-5, 5, 0]} intensity={0.8}/>
            {/*<OrbitControls makeDefault />*/}
            <Model audio={audio} audioAlignment={audioAlignment}/>
        </Canvas>
    </div>
}

export function Model({  audio, audioAlignment }: { audio: Accessor<HTMLAudioElement>, audioAlignment: Accessor<AudioAlignment> }) {
    const [modelData] =  useGLTF<any, GLTFResult>("/assets/AvatarModel.glb")
    let previousViseme = charToViseme(' ')
    let currentViseme = charToViseme(' ')
    let visemeStart = 0
    let currentTime = 0
    useFrame(() => {
        if (modelData()) {
            const head = modelData().nodes.Wolf3D_Head
            const teeth = modelData().nodes.Wolf3D_Teeth

            // Animate lipsync
            if (audio() && !audio().ended) {
                currentTime = audio().currentTime + 0.1
                audioAlignment().characters.forEach((char, i) => {
                    const charStart = audioAlignment().character_start_times_seconds[i]
                    const charEnd = audioAlignment().character_end_times_seconds[i]
                    if (currentTime >= charStart && currentTime <= charEnd) {
                        if (visemeStart != charStart) {
                            visemeStart = charStart
                            previousViseme = currentViseme
                            currentViseme = charToViseme(char)
                        }
                    }
                })
            }
            const fade = 0.1
            const limit = 0.5
            const diff = currentTime - visemeStart
            const getVisemeFadeIn = () =>  diff < fade ? limit * (diff / fade) : limit
            const getVisemeFadeOut = () => diff < fade ? limit * (1 - (diff / fade)) : 0
            Object.keys(head.morphTargetDictionary).forEach(headTarget => {
                const newValue = headTarget == currentViseme ? getVisemeFadeIn() : headTarget == previousViseme ? getVisemeFadeOut() : 0
                head.morphTargetInfluences[head.morphTargetDictionary[headTarget]] = newValue
            })
            Object.keys(teeth.morphTargetDictionary).forEach(teethTarget => {
                const newValue = teethTarget == currentViseme ? getVisemeFadeIn() : teethTarget == previousViseme ? getVisemeFadeOut() : 0
                teeth.morphTargetInfluences[teeth.morphTargetDictionary[teethTarget]] = newValue
            })

            // Set smile
            head.morphTargetInfluences[head.morphTargetDictionary['browInnerUp']] = 0.17
            head.morphTargetInfluences[head.morphTargetDictionary['eyeSquintLeft']] = 0.4
            head.morphTargetInfluences[head.morphTargetDictionary['eyeSquintRight']] = 0.44
            head.morphTargetInfluences[head.morphTargetDictionary['noseSneerLeft']] = 0.17
            head.morphTargetInfluences[head.morphTargetDictionary['noseSneerRight']] = 0.14
            head.morphTargetInfluences[head.morphTargetDictionary['mouthPressLeft']] = 0.61
            head.morphTargetInfluences[head.morphTargetDictionary['mouthPressRight']] = 0.41
        }
    })
    return (
        <group dispose={null}>
            <Show when={modelData()} fallback={null}>
                {/*<primitive object={data().nodes.Hips}/>*/}
                <skinnedMesh
                    name="EyeLeft"
                    geometry={modelData().nodes.EyeLeft.geometry}
                    material={modelData().materials.Wolf3D_Eye}
                    skeleton={modelData().nodes.EyeLeft.skeleton}
                    morphTargetDictionary={modelData().nodes.EyeLeft.morphTargetDictionary}
                    morphTargetInfluences={modelData().nodes.EyeLeft.morphTargetInfluences}
                />
                <skinnedMesh
                    name="EyeRight"
                    geometry={modelData().nodes.EyeRight.geometry}
                    material={modelData().materials.Wolf3D_Eye}
                    skeleton={modelData().nodes.EyeRight.skeleton}
                    morphTargetDictionary={modelData().nodes.EyeRight.morphTargetDictionary}
                    morphTargetInfluences={modelData().nodes.EyeRight.morphTargetInfluences}
                />
                <skinnedMesh
                    name="Wolf3D_Head"
                    geometry={modelData().nodes.Wolf3D_Head.geometry}
                    material={modelData().materials.Wolf3D_Skin}
                    skeleton={modelData().nodes.Wolf3D_Head.skeleton}
                    morphTargetDictionary={modelData().nodes.Wolf3D_Head.morphTargetDictionary}
                    morphTargetInfluences={modelData().nodes.Wolf3D_Head.morphTargetInfluences}
                />
                <skinnedMesh
                    name="Wolf3D_Teeth"
                    geometry={modelData().nodes.Wolf3D_Teeth.geometry}
                    material={modelData().materials.Wolf3D_Teeth}
                    skeleton={modelData().nodes.Wolf3D_Teeth.skeleton}
                    morphTargetDictionary={modelData().nodes.Wolf3D_Teeth.morphTargetDictionary}
                    morphTargetInfluences={modelData().nodes.Wolf3D_Teeth.morphTargetInfluences}
                />
                <skinnedMesh
                    geometry={modelData().nodes.Wolf3D_Hair.geometry}
                    material={modelData().materials.Wolf3D_Hair}
                    skeleton={modelData().nodes.Wolf3D_Hair.skeleton}
                />
                <skinnedMesh
                    geometry={modelData().nodes.Wolf3D_Glasses.geometry}
                    material={modelData().materials.Wolf3D_Glasses}
                    skeleton={modelData().nodes.Wolf3D_Glasses.skeleton}
                />
                <skinnedMesh
                    geometry={modelData().nodes.Wolf3D_Outfit_Bottom.geometry}
                    material={modelData().materials.Wolf3D_Outfit_Bottom}
                    skeleton={modelData().nodes.Wolf3D_Outfit_Bottom.skeleton}
                />
                <skinnedMesh
                    geometry={modelData().nodes.Wolf3D_Outfit_Footwear.geometry}
                    material={modelData().materials.Wolf3D_Outfit_Footwear}
                    skeleton={modelData().nodes.Wolf3D_Outfit_Footwear.skeleton}
                />
                <skinnedMesh
                    geometry={modelData().nodes.Wolf3D_Outfit_Top.geometry}
                    material={modelData().materials.Wolf3D_Outfit_Top}
                    skeleton={modelData().nodes.Wolf3D_Outfit_Top.skeleton}
                />
            </Show>
        </group>
    )
}

function charToViseme(char: string) {
    const upperChar = char.toUpperCase()
    switch (upperChar) {
        case 'A':
            return 'viseme_aa';
        case 'E':
        case 'H':
            return 'viseme_E';
        case 'I':
            return 'viseme_I';
        case 'O':
        case 'W':
            return 'viseme_O';
        case 'U':
            return 'viseme_U';
        case 'P':
        case 'B':
        case 'M':
            return 'viseme_PP';
        case 'F':
        case 'V':
            return 'viseme_FF';
        case 'T':
        case 'D':
        case 'S':
        case 'Z':
        case 'X':
            return 'viseme_SS';
        case 'J':
        case 'Y':
            return 'viseme_CH';
        case 'C':
        case 'K':
        case 'G':
        case 'Q':
            return 'viseme_kk';
        case 'R':
            return 'viseme_RR';
        case 'N':
        case 'L':
            return 'viseme_nn';
        case ' ':
            return 'viseme_sil';
        default:
            return 'viseme_sil';
    }
}
