import {last} from '@peachy/utility-kit-pure'

export type MessageHandler = {
    onReceive(handler: (message: Message) => void): void
    send(message: Message): Promise<void>
}

type ChatApi = {
    send(text: string): Promise<void>
    init(): Promise<void>
}

//TODO: create proper models
export type Message = {
    name: string
    text: string
    direction: 'outgoing' | 'incoming'
    type?: 'initial_connection'
    state?: string,
    audio?: string
}

export function createChatApi({
                                  getHistory,
                                  setHistory,
                                  messageHandler,
                              }: {
    getHistory: () => Message[],
    setHistory: (messages: Message[]) => void,
    messageHandler: MessageHandler,
}): ChatApi {
    messageHandler.onReceive((message) => {
        console.log('chat api: message received', message)
        setHistory([ ...getHistory(), message ])
    })
    return {
        async send(text: string) {
            console.log('sending:', text)
            const history = getHistory()
            const message: Message = {
                name: 'human',
                text,
                direction: 'outgoing' as const,
                state: last(history)?.state ?? ''
            }
            setHistory([ ...history, message ])
            return messageHandler.send(message)
        },
        init() {
            return messageHandler.send({
                name: 'human',
                text: '',
                direction: 'outgoing',
                type: 'initial_connection'
            })
        }
    }
}
