import {AnEvent} from '../event-kit'
import {DraftAccount} from '../../domain/models/Account'
import {DraftSubscription} from '../../domain/models/Subscription'
import {Plan} from '../../domain/models/Plan'
import {Policy} from '../../domain/models/Policy'
import { Type } from 'class-transformer'

export class SubscriptionRequest extends AnEvent {
    @Type(() => DraftAccount)
    public account: DraftAccount

    @Type(() => DraftSubscription)
    public subscription: DraftSubscription

    @Type(() => Plan)
    public plans: Plan[]

    @Type(() => Policy)
    public policies: Policy[]

    public intercomVisitorId?: string
    constructor(from: SubscriptionRequest) {
        super()
        Object.assign(this, from)
    }
}
