import { AnEvent } from '../event-kit'
import { CancellationType } from './CancellationType'

export class SmeCancellationEmailRequest extends AnEvent {
    public accountId: string
    public cancellationType: CancellationType

    constructor(from: SmeCancellationEmailRequest) {
        super()
        Object.assign(this, from)
    }
}