export const TEXTRACT = 'TEXTRACT'
export const TEXTRACT_INVOICE = 'TEXTRACT_INVOICE'
export const TRANSCRIBE_AUDIO = 'TRANSCRIBE_AUDIO'
export const TRANSCRIBE_MEDICAL_AUDIO = 'TRANSCRIBE_MEDICAL_AUDIO'
export const SNOMED_COMPREHEND = 'SNOMED_COMPREHEND'
export const ALL_PROCESSING_TAGS = [
    TEXTRACT,
    TEXTRACT_INVOICE,
    TRANSCRIBE_AUDIO,
    TRANSCRIBE_MEDICAL_AUDIO,
    SNOMED_COMPREHEND
] as const
export type ProcessingTag = typeof ALL_PROCESSING_TAGS[number]
