import { keysOf } from '@peachy/utility-kit-pure'
import {Type} from 'class-transformer'
import {Address} from './Address/Address'

export type CompanySource =
    | 'COMPANIES_HOUSE'
    | 'CHARITY_COMMISSION'
    
export const CompanySources = keysOf<CompanySource>()

export class Company {
    id: string
    source: CompanySource
    name: string

    @Type(() => Address)
    address: Address 

    isCharity() {
        return this.source === CompanySources.CHARITY_COMMISSION
    }
}
