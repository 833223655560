import { NupaHttpClient } from './NupaHttpClient'
import { AuthDetails, AuthProvider } from './http-kit'
import { Optional } from './utility-types'

export class ClientCredentialsAuthProvider implements AuthProvider {
    
    private http = new NupaHttpClient()
    private auth: Optional<AuthDetails> = undefined

    constructor(private readonly authClientId: String, private readonly clientSecret: string, private readonly authEndpoint: string) {}

    async getOrRefetchAuth(): Promise<AuthDetails> {
        this.auth = AuthDetails.ifUsable(this.auth) ?? await this.fetchAuth()
        return this.auth
    }

    async fetchAuth() {
        const response = await this.http.fetchWithTimeout('POST', this.authEndpoint, 
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}, 
            `grant_type=client_credentials&client_id=${this.authClientId}&client_secret=${this.clientSecret}`
        )
        return AuthDetails.fromOidcAuthResponse(response)
    }
}