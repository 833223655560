import {CustomerApplicationContextProvider} from '../../controllers/CustomerApplicationContextProvider'
import {useParams, Route, Routes} from '@solidjs/router'
import {CustomerDashboard} from './CustomerDashboard'
import {ViewClaim} from './Claim/ViewClaim'
import {FlashRepoPanel} from '../Admin/flash-repo/FlashRepoPanel/FlashRepoPanel'

export default function CustomerAppContextAwareRoutes() {

    const {customerId} = useParams()

    return (
        <CustomerApplicationContextProvider customerId={customerId}>
            <Routes>
                <Route path="/" component={CustomerDashboard} />
                <Route path="/claim/:claimId" component={ViewClaim} />
                <Route path="/repo" component={FlashRepoPanel} />
            </Routes>
        </CustomerApplicationContextProvider>
    )

}