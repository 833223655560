import {FlashRepo, PNode, RemoteRepoAdapter, SyncContext} from '@peachy/flash-repo-pure'
import {PNodeProxy} from '@peachy/flash-repo-pure/src/flash-repo/path-builder/PNodeProxy'
import {useApplicationContext, useBean} from '../../../controllers/CustomerApplicationContextProvider'
import {createResource, createContext, Show, ParentProps,useContext, createSignal} from 'solid-js'

type RepoContext = {

    root: PNode<any>
    repo: FlashRepo
    sync: () => Promise<SyncContext>

    isSynchronised: () => boolean
}


const RepoContextKey = createContext<RepoContext>()


export function RepoController(props: ParentProps) {

    const [isSynchronised, setIsSynchronised] = createSignal(false)

    const [repoContext, trigger] = createResource<RepoContext>(async () => {

        const appContext = useApplicationContext()

        const repo = appContext.getBean('peachyFlashRepo')
        const remote = appContext.getBean('remoteRepoAdapter')

        const root = await repo.getContentRoot()
        root.$(v => {
            setIsSynchronised(repo.isSynchronised())
        })

        const sync = async () => {
            const result = await repo.sync(remote)
            setIsSynchronised(repo.isSynchronised())
            return result
        }
        return {
            root, repo, sync, isSynchronised
        }
    })

    return (
        <Show when={repoContext.latest}>
            <RepoContextKey.Provider value={repoContext()}>
                {props.children}
            </RepoContextKey.Provider>
        </Show>
    )
}

export function useRepoContext() {
    return useContext(RepoContextKey)
}