import {DateTime, Pence} from '../some-types'
import {newUUID} from '@peachy/utility-kit-pure'


export type PaymentStatus =
    | 'FAILED'
    | 'RECEIVED'
    | 'REFUNDED'

export function paymentKey(paymentId: string) {
    return `peachy/payment/${paymentId}`
}

export function paymentAttemptKey(paymentId: string) {
    return `peachy/payment-attempt/${paymentId}`
}

export const PaymentIndexKey = 'peachy/payment-idx'
export const PaymentAttemptIndexKey = 'peachy/payment-attempt-idx'

export class Payment {
    id: string
    stripeInvoiceId: string
    stripeInvoiceNumber: string
    status: PaymentStatus
    periodStartAt: DateTime
    periodEndAt: DateTime
    amountDue: Pence
    amountCollected: Pence
    amountDiscounted?: Pence
    promo?: string
    paymentAttemptIds?: string[]
}

export class PaymentAttempt {
    id: string
    stripeEventId: string
    stripeInvoiceId: string
    status: PaymentStatus
    occurredAt: DateTime
    amountCollected: Pence
    attempt: number
    refund?: {
        reason: string
    }
    failure?: {
        reason: string
        code: string
        nextAttemptAt?: number
    }
}



export class Discount {
    amount: Pence
    couponName: string
    promoCode: string
}

// repeatable uuid
export function generatePaymentId(stripeInvoiceId: string) {
    return newUUID('payment' + stripeInvoiceId)
}

