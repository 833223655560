import {Api, defineApi, getOf, postOf, SubscriptionRequest, AccountSubscriptionDto} from '@peachy/core-domain-pure'
import { SubscriptionResponse } from './subscription-api'
import { PortalGroups, PortalUsersGroup, WelcomeAdminUserRequest, WelcomeAdminUserResponse } from '@peachy/iam-sme-pure'

class NoParams {
}

export const SmeSubscriptionApiDef = defineApi('LambdaSmeSubscriptionApi')
   .forGroups(PortalGroups)
    .withActions({
        setupSmeSubscription: postOf(SubscriptionRequest).fromAnyone().willReturn(SubscriptionResponse),
        saveDraftSmeSubscription: postOf(SubscriptionRequest).fromAnyone().willReturn(SubscriptionResponse),
        getDraftSmeSubscription: getOf(NoParams).fromGroups(PortalUsersGroup).willReturn(SubscriptionRequest),
        getSmeSubscription: getOf(NoParams).fromGroups(PortalUsersGroup).willReturn(AccountSubscriptionDto),
        welcomeAdminUser: postOf(WelcomeAdminUserRequest).fromAnyone().willReturn(WelcomeAdminUserResponse)
    })

export type SmeSubscriptionApi = Api<typeof SmeSubscriptionApiDef>

