import { AnEvent } from '../event-kit'
import { CancellationType } from './CancellationType'

export class UserCancellationEmailRequest extends AnEvent {
    public accountId: string
    public policyId: string
    public lifeId: string
    public cancellationType: CancellationType

    constructor(from: UserCancellationEmailRequest) {
        super()
        Object.assign(this, from)
    }
}