import { splitProps } from "solid-js"
import { TextBox, TextBoxProps, valueFrom } from "../TextBox/TextBox"
import { format, isValid, parse, setHours } from "date-fns"

type Html5DatePickerProps = Omit<TextBoxProps, 'children' | 'value'  | 'onBlur'> & {
    value: Date
    onBlur?: (value?: Date) => void
}
export function Html5DatePicker(allProps: Html5DatePickerProps) {
    
    const [props, passthroughProps] = splitProps(allProps, ['value', 'onBlur'])
    
    const onBlur = (stringValue: string) => {
        const date = toDateMidday(stringValue)
        props?.onBlur(date)
    }

    return <TextBox type="date" {...passthroughProps}
                value={toHtmlDateInputValue(props.value)} 
                onBlur={e => onBlur(valueFrom(e))}
            />
}


// todo properly deal with timezones and don't just make everything midday
const HTML_DATE_INPUT_FORMAT = 'yyyy-MM-dd'
function toDateMidday(htmlDateInputStr?: string) {
    const date = htmlDateInputStr ? parse(htmlDateInputStr, HTML_DATE_INPUT_FORMAT, new  Date()) : undefined
    const midday = isValid(date) ? setHours(date, 12): undefined
    return midday
}
function toHtmlDateInputValue(date?: Date) {
    return isValid(date) ? format(date, HTML_DATE_INPUT_FORMAT) : undefined
}