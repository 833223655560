// Type of
export const isString = (x: unknown): x is string => typeof x === 'string'
export const isNumber = (x: unknown): x is number => typeof x === 'number'
export const isBoolean = (x: unknown): x is boolean => typeof x === 'boolean'
export const isFunction = (x: unknown): x is Function => typeof x === 'function'
export const isArray = (x: unknown): x is any[] => Array.isArray(x)
export const isObject = (x: unknown): x is object => x && typeof x === 'object' && !isArray(x)

export const isPromise = (x: any): x is Promise<any> => typeof x === 'object' && 'then' in x && typeof x.then === 'function'


// Instance of
export const isDateObject = (x: any): x is Date => x instanceof Date

// Other
export const isNullish = (value: any): value is null | undefined => (value === null || value === undefined)



