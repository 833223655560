import {LifeRow} from './LifeRow/LifeRow'
import {createSignal, For} from 'solid-js'
import styles from './GroupLives.module.css'
import {useGroupSubscriptionRequestStore} from '../GroupSubscriptionPanel'
import {IsValidMarker} from '../IsValidMarker/IsValidMarker'
import {DatePicker} from "@peachy/client-kit";
import {addYears} from "date-fns";

export function GroupLives() {

    const store = useGroupSubscriptionRequestStore()


    const addLife = () => {
        store.addLife()
        requestAnimationFrame(() => {
            const row = tableElement.lastElementChild
            row.querySelector('input').focus()
        })
    }

    let tableElement: HTMLTableElement

    return (
        <div class={styles.Panel}>
            <h3><IsValidMarker isValid={store.hasValidLives()}/>Group Lives</h3>
            <div class={styles.GroupLives}>
                <table ref={tableElement}>
                    <For each={store.getLives()}>{(life) =>
                        <LifeRow life={life}
                                 isOnlyLife={store.getLives().length === 1}
                                 updateLife={(update) => store.updateLife(life.id, update)}
                                 removeLife={() => store.removeLife(life.id)}
                        />
                    }</For>
                </table>
                <button onClick={addLife}>Add Life +</button>
            </div>
        </div>
    )
}



