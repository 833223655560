import {PlanRepository, ManagedLifeRepository, PeachyFlashRepo} from '@peachy/flash-repo-peachy-client'
import {sortBy, transform, isEmpty} from 'lodash-es'
import {
    Plan,
    RepoPlan,
    DomainMappings,
    BenefitType,
    BenefitTypes,
    RepoLivesById,
    PlanYear,
    PlanYearBenefitAmountTotals,
    PlanYearExcessAmountTotals,
    CurrentPlanYear
} from '@peachy/repo-domain'
import {BenefitsService} from './BenefitsService'
import {ClaimsSearchService} from './ClaimsSearchService'
import {Logger, Optional, getAnnualIntervalsToDateOf, getCurrentAnnualIntervalOf} from '@peachy/utility-kit-pure'
import { ExcessService } from './ExcessService'

export class PlanService {

    constructor(protected readonly logger: Logger,
                protected readonly planRepository: PlanRepository,
                protected readonly repo: PeachyFlashRepo,
                protected readonly managedLifeRepository: ManagedLifeRepository,
                protected readonly claimsSearchService: ClaimsSearchService,
                protected readonly benefitsService: BenefitsService,
                protected readonly excessService: ExcessService) { } 


    async getPlans() {
        const repoPlans = await this.planRepository.list()
        const livesById = await this.managedLifeRepository.getAllMappedById()
        const plans = await Promise.all(repoPlans.map(it => this.buildPlan(it, livesById)))
        return sortBy(plans, it => it.life.isPrimary ? 0 : 1)
    }

    getBenefitTypesNotCoveredOn(plan: Plan) {
        const benefitTypesCovered = plan.currentPlanYear?.coverCheckableBenefits?.map(it => it.type) ?? []
        const typesNotCovered = []
        for (let benefitType in BenefitTypes) {
            if (!benefitTypesCovered.includes(benefitType as BenefitType)) {
                typesNotCovered.push(benefitType)
            }
        }
        return typesNotCovered as BenefitType[]
    }

    private async buildPlan(repoPlan: RepoPlan, livesById: RepoLivesById) {

        const repoLife = livesById[repoPlan.lifeId]

        const planYears = await this.getPlanYearsToDateFor(repoPlan.lifeId, new Date())
            
        return DomainMappings.fromRepo.toPlan(repoPlan, repoLife, planYears)
    }

    async getCurrentPlanYearFor(lifeId: string): Promise<Optional<CurrentPlanYear>> {
        const rightNow = new Date()
        const currentPlanYear = (await this.getPlanYearsToDateFor(lifeId, rightNow)).find(it => it.isCurrent)
        return currentPlanYear ? new CurrentPlanYear(currentPlanYear) : undefined
    }

    private async getPlanYearsToDateFor(lifeId: string, atDateTime: Date) {
        
        this.logger.debug('getting all plan years to date for lifeId:', lifeId, 'atDateTime: ', atDateTime)
        
        const repoPlan = await this.planRepository.find({lifeId})
        const root = await this.repo.getContentRoot()


        const subscriptionStartDate = DomainMappings.fromRepo.toDate(await root.subscription.startDate())
        const planStartDate = DomainMappings.fromRepo.toDate(repoPlan.startDate)
        
        const [allTimePlanYearBenefitUsage, allTimePlanYearExcessUsage] = await this.calculateAlltimePlanYearBenefitAndExcessUsageFor(lifeId)

        const yearsToDate = getAnnualIntervalsToDateOf({eventDateTime: subscriptionStartDate, atDateTime, fromDateTime: planStartDate}) 

        // should consider if we want this method to identify "current as at the passed asAtDateTime" or "current as at fetch time" right now it's the latter which is fine for current usage
        const currentPlanYearId = PlanYear.idOf(getCurrentAnnualIntervalOf(subscriptionStartDate))

        const planYears = transform(yearsToDate, (collector, yearInterval) => {
            
            const yearIntervalPlanYearId = PlanYear.idOf(yearInterval)

            const benefitsWithinThePlanYear = this.benefitsService.getBenefitsThatWereActiveDuring(yearInterval, repoPlan)
            const excessWithinThePlanYear = this.excessService.getExcessesThatWereActiveDuring(yearInterval, repoPlan)

            if (!isEmpty(benefitsWithinThePlanYear)) {
                const planYear = new PlanYear({
                    ...yearInterval,
                    isCurrent: currentPlanYearId === yearIntervalPlanYearId,
                    benefits: benefitsWithinThePlanYear.map(it => it.withUsage(
                        yearIntervalPlanYearId,
                        allTimePlanYearBenefitUsage.getTotalFor(yearIntervalPlanYearId, it.type)
                    )),
                    excess: excessWithinThePlanYear?.withUsage(
                        yearIntervalPlanYearId,
                        allTimePlanYearExcessUsage.getTotalFor(yearIntervalPlanYearId, excessWithinThePlanYear.id)
                    )
                })
                collector.push(planYear.isCurrent ? new CurrentPlanYear(planYear) : planYear)
            }

        }, [] as PlanYear[])

        return planYears
    }

    private async calculateAlltimePlanYearBenefitAndExcessUsageFor(lifeId: string) {
        const allApprovedClaims = await this.claimsSearchService.listAllApprovedClaimsFor(lifeId)
        const allApprovedCosts = allApprovedClaims.flatMap(it => it.decision?.approvedCosts?.planYearBenefitApprovals ?? [])
        const allExcessUsage = allApprovedClaims.flatMap(it => it.decision?.approvedCosts?.planYearExcessUsage ?? [])
        return [new PlanYearBenefitAmountTotals(allApprovedCosts), new PlanYearExcessAmountTotals(allExcessUsage)] as const
    }

}