import 'reflect-metadata'
import {DomainMappings} from '../mappings/DomainMappings'
import {Life} from './Life'
import {AppointmentType} from './types'
import {ClassConstructor} from 'class-transformer/types/interfaces'
import {instanceToPlain, plainToInstance, Type} from 'class-transformer'
import {Address} from './Address'
import {Enquiry} from './enquiry/Enquiry'
import {Dictionary} from '@peachy/utility-kit-pure'


class CommonAppointment {

    @Type(() => Date)
    readonly dateCreated: Date

    constructor(readonly id: string,
                readonly type: AppointmentType,
                dateCreated: Date,
                public serviceProviderMetadata: Dictionary<any> = {}) {
        this.dateCreated = dateCreated
    }

    get referenceNumber() {
        return DomainMappings.peachifyUuid(this.id)
    }

    setInMetadata(key: string, value: any) {
        this.serviceProviderMetadata = this.serviceProviderMetadata || {}
        this.serviceProviderMetadata[key] = instanceToPlain(value)
    }

    getInMetadata<T>(key: string, asClass?: ClassConstructor<T>): T | undefined {
        const item = this.serviceProviderMetadata?.[key]
        if (item) {
            return asClass ? plainToInstance(asClass, item) : item
        }
    }

    clearMetadata(...keys: string[]) {
        keys.forEach(it => this.setInMetadata(it, undefined))
    }
}

export class Appointment extends CommonAppointment {

    @Type(() => Date)
    public date: Date
    @Type(() => Life)
    public whoFor: Life
    @Type(() => Address)
    public location?: Address

    constructor(id: string,
                type: AppointmentType,
                dateCreated: Date,

                readonly enquiryId: string,
                date: Date,
                whoFor: Life,
                public whoWith?: string,
                location?: Address,
                public takesPlaceInApp: boolean = false,
                public attended: boolean = false,

                serviceProviderMetadata: Dictionary<any> = {}

    ) {
        super(id, type, dateCreated, serviceProviderMetadata)
        this.date = date
        this.whoFor = whoFor
        this.location = location
    }

    startsWithinMinutes(minutes: number) {
        const millisecondsUntilAppointment = this.date!.getTime() - Date.now()
        const minutesUntilAppointment = millisecondsUntilAppointment / 1000 / 60
        return minutesUntilAppointment < minutes
    }

    isReJoinableInApp() {
        const includeAlreadyAttended = true
        return this.isJoinableInApp(includeAlreadyAttended)
    }

    isJoinableInApp(includeAlreadyAttended = false) {
        return this.takesPlaceInApp &&
        (!this.attended ||  includeAlreadyAttended) &&
        this.startsWithinMinutes(15)
    }

    isCancellable() {
        return !(this.attended || this.startsWithinMinutes(60))
    }

    get whoWithInitials() {
        const [firstName, lastName] = this.whoWith?.split(' ') || []
        const firstInitial = firstName?.slice(0,1)?.toUpperCase()
        const secondInitial = lastName?.slice(0,1)?.toUpperCase()
        return (firstInitial || '') + (secondInitial || '')
    }

}

export class InProgressAppointmentBooking extends CommonAppointment {

    @Type(() => Enquiry)
    public enquiry: Enquiry

    constructor(id: string,
                type: AppointmentType,
                dateCreated: Date,

                enquiry: Enquiry,
                serviceProviderMetadata: Dictionary<any> = {}) {
        super(id, type, dateCreated, serviceProviderMetadata)
        this.enquiry = enquiry
    }

    get enquiryId() {
        return this.enquiry.id
    }
}

export type AppointmentOrInProgressBooking = Appointment | InProgressAppointmentBooking