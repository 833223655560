import {Auth} from '@aws-amplify/auth'
import {Signer} from '@aws-amplify/core'
import {getApiMetadata, makeApiClient} from '@peachy/core-domain-client'
import {DocGenApi, DocGenApiDefinition} from '@peachy/doc-gen-pure'
import {ClaimsActivityApiDefinition} from '@peachy/claims-activity-pure'
import {FlashRepoApiDefinition} from '@peachy/flash-repo-pure'
import {UserAdminApiDefinition} from '@peachy/web-dashboard-pure'
import { createGeoLocationServiceClient } from '@peachy/client-kit/src/services/geo-location/GeoLocationServiceClient'
import { ILambdaApiProvider } from './ILambdaApiProvider'

export class LambdaApiProvider implements ILambdaApiProvider {

    constructor(
        readonly servicePatchUri: string, 
        readonly auth: typeof Auth,
        readonly signer: typeof Signer
        ) {
    }

    async docApi() {
        const client: DocGenApi = await makeApiClient(DocGenApiDefinition, this.servicePatchUri, this.signer, this.auth)

        return {
            ...client,
            getPdfLink(ticketId: string, asFile: string) {
                const routes = getApiMetadata(client).routes
                return `${routes.renderPdfTicket}/${asFile}?ticketId=${ticketId}`
            }
        }
    }

    async claimsActivityApi() {
        return makeApiClient(ClaimsActivityApiDefinition, this.servicePatchUri, this.signer, this.auth)
    }

    async adminRepoApi() {
        return makeApiClient(FlashRepoApiDefinition, this.servicePatchUri, this.signer, this.auth)
    }

    async userAdminApi() {
        return makeApiClient(UserAdminApiDefinition, this.servicePatchUri, this.signer, this.auth)
    }

    geoLocationApi() {
        return createGeoLocationServiceClient(this.servicePatchUri, this.signer)
    }

}