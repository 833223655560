import { AnEvent } from "../event-kit";

export class PolicyDocRequest extends AnEvent {
    public policyId: string
    public accountId?: string

    constructor(from: PolicyDocRequest) {
        super()
        Object.assign(this, from)
    }
}