import { WebCustomerApplicationContext } from "../../service/customer-context/WebCustomerApplicationContext"
import * as CSV from 'papaparse'
import { entries, values, intersection, isEmpty, sortBy, sumBy, uniq, round, isUndefined, uniqBy } from "lodash-es"
import { For, Show, createResource } from "solid-js"
import { useApiInfra, useAuthInfra } from "../../controllers/InfraController"
import { BeanFactory } from "@peachy/service"
import { Spinner } from "@peachy/client-kit"
import { formatInTimeZone } from 'date-fns-tz'
import { Dictionary, ENGLAND_PUBLIC_HOLIDAYS_22_TO_26, UK_TIMEZONE, WorkingHoursBetweenOptions, WorkingHoursRepresentations, stringifyIfItCanBe, workingHoursBetween, minValidDate, maxValidDate, Optional, penceToPounds, groupReduceByNested, mapGroupDistinctBy, Funds} from "@peachy/utility-kit-pure"
import { endOfMonth, format, isAfter, max, parse, parseISO, set, subMonths, isSameDay } from 'date-fns'
import { BenefitType, ClaimActivity, ClaimAssessment, ClaimInvoiceLineItem, DomainMappings, Plan, PlanYearBenefitAmount, PlanYearBenefitAmountTotals, PlanYearId, Policy } from "@peachy/repo-domain"
import { TerminologyItem } from "@peachy/nhs-pure"
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth"
import { APIClass } from "@aws-amplify/api"
import {Fund} from '@peachy/utility-kit-pure'

const notApplicable = 'n/a'
const workingHoursOptions: WorkingHoursBetweenOptions = {
    workingHours: {from: {hours: 9}, to: {hours: 18}},
    publicHolidays: ENGLAND_PUBLIC_HOLIDAYS_22_TO_26
}

/************************************************************* README ************************************************************
 *
 * Before you run this report make sure to take care of all manual data inputs required.
 * Ask Alice to provide the following: In the last reporting month have we had any:
 *      * claims/cover checks raised outside of the app? (will need first acknowledgement dates/times for each of them)
 *      * walkaways? (will need walkaway date/time)
 *      * abandonments? (will need abandonment date/time)
 *      * anything that we want to completely exclude from the report, e.g test claims/cover checks? (will need the ids of each)
 *      * paid claims? (will need the date paid for each of them)
 *
 * See "MANUAL INPUT NEEDED BEFORE RUNNING THE REPORT" below for more details and where to plug them in.
 *
 * Finally grab a list of all cognito ids from the prod userpool and plug that in at the bottom too
 *
 *********************************************************************************************************************************/



function getAllSubs() {
    // before a new report run, make sure to refresh all prod users from executing: aws cognito-idp list-users --user-pool-id eu-west-2_jX24lwObD --query "Users[*].Attributes[?Name == 'sub'].Value"
    let subs = getAllProdSubs()

    // subs = ["6e366346-cbfa-4ca4-b52f-c45b7cb83799"] //alice
     // subs = ["9f69f0da-1524-454f-a733-ffad1eb95323"] //bhav
    //subs = ["29deca76-334f-4452-94ec-c51f635c3812"] //steve
    // subs = ["6e366346-cbfa-4ca4-b52f-c45b7cb83799", "9f69f0da-1524-454f-a733-ffad1eb95323"] // alice & bhav
    // subs = ["d243693c-befe-4b1c-a485-86e0a63e4c9c", "78c63a28-917c-43aa-ac07-a8ab5cf0023e"]

    //subs = ['7881b46a-a28e-43a7-a8e5-7ab3eea705f3','91e86d7d-abe8-45dc-98d1-ac939dc523e5']

    //subs = ['d243693c-befe-4b1c-a485-86e0a63e4c9c']
    return subs
}

export default function ClaimBdx() {
    
    const [reports] = createResource(generateReports)

    const claimsLineItemsCsv = () => reports().claimsLineItems.csv
    const claimPlanYearBenefitsCsv = () => reports().claimsPlanYearBenefits.csv
    const claimsSlaCsv = () => reports().claimsSla.csv

    const coverCheckLineItemsCsv = () => reports().coverCheckLineItems.csv
    const coverCheckSlaCsv = () => reports().coverCheckSla.csv
    
    const filenameDatePrefix = format(new Date(), 'yyyy-MM-dd')

    return (
        <div>
            <h2>Claim & Cover Check Bdx</h2>
            <Show when={!reports.loading} fallback={<Spinner isShown/>}>
                <h3>Claims</h3>
                <a rel="noopener"
                    download={`${filenameDatePrefix}.claims.line-items.csv`}
                    href={`data:attachment/csv,'${encodeURI(claimsLineItemsCsv())}`}>claims line items</a>
                <br/>
                <a rel="noopener"
                    download={`${filenameDatePrefix}.claims.plan-year-benefit-usage.csv`}
                    href={`data:attachment/csv,'${encodeURI(claimPlanYearBenefitsCsv())}`}>claims plan year benefit usage</a>
                <br/>
                <a rel="noopener"
                    download={`${filenameDatePrefix}.claims.sla.csv`}
                    href={`data:attachment/csv,'${encodeURI(claimsSlaCsv())}`}>claim SLAs</a>
                <br/>

                <h3>Cover Check</h3>
                <a rel="noopener"
                    download={`${filenameDatePrefix}.coverchecks.line-items.csv`}
                    href={`data:attachment/csv,'${encodeURI(coverCheckLineItemsCsv())}`}>cover checks line items</a>
                <br/>
                <a rel="noopener"
                    download={`${filenameDatePrefix}.coverchecks.sla.csv`}
                    href={`data:attachment/csv,'${encodeURI(coverCheckSlaCsv())}`}>cover check SLAs</a>
                <br/>
                <Show when={!isEmpty(reports().errors)}>
                    <div>
                        <br/>
                        <strong>Errors</strong>
                        <For each={reports().errors}>{it =>
                            <div>
                                <hr/>
                                <pre>sub: {it.sub}</pre>
                                <pre>
                                    {it.error.name}<br/>
                                    {it.error.message}<br/>
                                    {it.error.stack}<br/>
                                </pre>
                                <br/>
                            </div>
                        }</For>
                    </div>
                </Show>

            </Show>
        </div>
    )
}


type ClaimActivityWorkUnit = {
    policy: Policy,
    claimActivity: ClaimActivity
}

type ClaimAndCoverCheckWorkUnitTuple = {
    claimUnits: ClaimActivityWorkUnit[]
    coverCheckUnits: ClaimActivityWorkUnit[]
}

type AmplifyAuthAndInfra = {
    amplifyAuth: AuthClass
    amplifyApi: APIClass
}

type ReportsByName = Dictionary<{raw: object[], csv: string}>

async function generateReports() {

    // BIG UGLY HACK TO MAKE SURE WE KEEP ON TOP OF PUBLIC HOLIDAYS
    throwAnErrorIfWeAreCloseToOurLimitsOfKnowledgeOfPublicHolidays()

    const authAndInfra = {
        amplifyAuth: useAuthInfra(),
        amplifyApi: useApiInfra()
    }

    const emptyReport = () => ({ raw: [] as object[], csv: '' })

    const reports: ReportsByName = {
        claimsSla: emptyReport(),
        coverCheckSla: emptyReport(),

        claimsLineItems: emptyReport(),
        claimsPlanYearBenefits: emptyReport(),

        coverCheckLineItems: emptyReport(),
    }

    const {claimUnits, coverCheckUnits} = await getAllClaimActivityWorkUnits(authAndInfra)

    // claims
    reports.claimsSla.raw = generateSlaReport(claimUnits)
    reports.claimsLineItems.raw = generateClaimsLineItemsReport(claimUnits)
    reports.claimsPlanYearBenefits.raw = generateClaimsPlanYearBenefitsReport(claimUnits)

    // cover checks
    reports.coverCheckSla.raw = generateSlaReport(coverCheckUnits)
    reports.coverCheckLineItems.raw = generateCoverCheckLineItemsReport(coverCheckUnits)

    values(reports).forEach(it => it.csv = toCsv(it.raw))

    outputDebug({claimUnits, coverCheckUnits}, reports)

    return reports

}

function outputDebug({claimUnits, coverCheckUnits}: ClaimAndCoverCheckWorkUnitTuple, reports: ReportsByName) {

    console.log('\n\n\n\n************************* REPORTS **************************')
    entries(reports).forEach(([name, report]) => {
        console.log(`REPORT [${name}]`)
        console.table(report.raw)
        console.log(report.csv)
    })

    console.log('\n\n\n\n************************* RAW CLAIM UNITS **************************')
    console.log(stringifyIfItCanBe(claimUnits))

    console.log('\n\n\n\n************************* RAW COVER CHECK UNITS **************************')
    console.log(stringifyIfItCanBe(coverCheckUnits))
}


function getSlaReportingPeriodGroups(activities: ClaimActivity[]) {
    const groupings = {
        submittedByPeriod: groupByReportingPeriod(activities, it => it.dateSubmitted),
        firstAcknowledgedByPeriod: groupByReportingPeriod(activities, it => getFirstAcknowledgmentDate(it)),
        referredByPeriod: groupByReportingPeriod(activities, it => it.assessment?.referralDate),
        approvedByPeriod: groupByReportingPeriod(activities, it => it.isApproved() ? it.decision.date : undefined),
        declinedByPeriod: groupByReportingPeriod(activities, it => it.isDeclined() ? it.decision.date : undefined),
        walkawaysByPeriod: groupByReportingPeriod(activities, it => getWalkawayDate(it)) ,
        decidedByPeriod: groupByReportingPeriod(activities, it => getDecisionDateForPurposeOfKpis(it)),
        paidByPeriod: groupByReportingPeriod(activities, it => it.isClaim() ? getDatePaid(it) : undefined)
    }

    const allPeriods = sortBy (
        distinctKeysAcrossAll(values(groupings)) as Period_yyyy_MM[]
    )

    const getPeriodGroupOrEmptyList = (groupName: keyof typeof groupings, period: Period_yyyy_MM) => {
        return groupings[groupName]?.[period] ?? ([] as ClaimActivity[])
    }

    return {
        submittedIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('submittedByPeriod', period),
        firstAcknowledgedIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('firstAcknowledgedByPeriod', period),
        referredIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('referredByPeriod', period),
        approvedIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('approvedByPeriod', period),
        declinedIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('declinedByPeriod', period),
        walkawaysIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('walkawaysByPeriod', period),
        decidedIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('decidedByPeriod', period),
        paidIn: (period: Period_yyyy_MM) => getPeriodGroupOrEmptyList('paidByPeriod', period),
        allPeriods
    }
}

function generateSlaReport(claimActivityUnits: ClaimActivityWorkUnit[]) {

    const claimActivities = claimActivityUnits.map(it => it.claimActivity)
    const anyActivity = claimActivities[0]
    const weAreDealingWithClaims = anyActivity?.isClaim()

    const reportingGroups = getSlaReportingPeriodGroups(claimActivities)

    const report = reportingGroups.allPeriods.map(period => {
        const submittedInPeriod = reportingGroups.submittedIn(period)
        const firstAcknowledgedInPeriod = reportingGroups.firstAcknowledgedIn(period)
        const referredInPeriod = reportingGroups.referredIn(period)
        const approvedInPeriod = reportingGroups.approvedIn(period)
        const declinedInPeriod = reportingGroups.declinedIn(period)
        const decidedInPeriod = reportingGroups.decidedIn(period)
        const paidInPeriod = reportingGroups.paidIn(period)
        const walkawaysInPeriod = reportingGroups.walkawaysIn(period)

        const stillOpenAtEndOfPeriodCount = sumBy(claimActivities, it => wasSubmittedInOrBeforePeriodAndWasStillOpenAtPeriodEnd(it, period) ? 1 : 0)

        const submissionToAcknowledgementKpi = submissionToFirstAcknowledgementKpiStats(firstAcknowledgedInPeriod, 'asWorkingDays', {sla: 1})
        const submissionToReferralKpi = submissionToReferralKpiStats(referredInPeriod, 'asWorkingDays')
        const submissionToDecisionKpi = submissionToDecisionKpiStats(decidedInPeriod, 'asWorkingDays')

        const submissionToPaymentKpi = submissionToPaymentKpiStats(paidInPeriod, 'asWorkingDays')
        const decisionToPaymentKpi = decisionToPaymentKpiStats(paidInPeriod, 'asWorkingDays', {sla: 2})

        return {
            period: format(getEndOfPeriod(period), 'MMM yyyy'),
            submitted: submittedInPeriod.length,
            referred: referredInPeriod.length,
            percent_referred_of_submitted: (!isEmpty(referredInPeriod) && !isEmpty(submittedInPeriod)) ? toOneDp((referredInPeriod.length / submittedInPeriod.length) * 100) : notApplicable,
            open: stillOpenAtEndOfPeriodCount,
            approved: approvedInPeriod.length,
            declined: declinedInPeriod.length,
            walkaway: walkawaysInPeriod.length,

            avg_submission_to_first_acknowledgement: ifDefinedOtherwise(submissionToAcknowledgementKpi?.avgTo1dp, notApplicable),
            percent_submission_to_first_acknowledgement_within_sla: ifDefinedOtherwise(submissionToAcknowledgementKpi?.withinSlaPercent, notApplicable),
            avg_submission_to_referral: ifDefinedOtherwise(submissionToReferralKpi?.avgTo1dp, notApplicable),
            avg_submission_to_decision: ifDefinedOtherwise(submissionToDecisionKpi?.avgTo1dp, notApplicable),

            ...(weAreDealingWithClaims ? {
                avg_submission_to_payment: ifDefinedOtherwise(submissionToPaymentKpi?.avgTo1dp, notApplicable),
                avg_decision_to_payment: ifDefinedOtherwise(decisionToPaymentKpi?.avgTo1dp, notApplicable),
                percent_decision_to_payment_within_sla: ifDefinedOtherwise(decisionToPaymentKpi?.withinSlaPercent, notApplicable)
            } : {}),

        }
    })

    return report
}

function generateClaimsLineItemsReport(claimUnits: ClaimActivityWorkUnit[]) {

    const report = claimUnits.flatMap(({claimActivity, policy}) => {
        const assessedLineItems = extractAssessedClaimLineItemsReportRowsIfAnyFrom(claimActivity, policy)
        return outerJoinTopLevelPolicyAndClaimActivityRowWith(assessedLineItems, claimActivity, policy)
    })

    const sortOrder = ['date_submitted', 'treatment_date_from']
    return sortBy(report, sortOrder)
}

function generateClaimsPlanYearBenefitsReport(claimUnits: ClaimActivityWorkUnit[]) {

    const distinctPlans = uniqBy(claimUnits.flatMap(it => it.policy.plans), it => it.id)
    const allFunds = MemberFunds.initialFundsFor(distinctPlans)

    // BEWARE! the order here is important to how benefit disbursments are calculated (i.e. they must be done in order of decision)
    const claimUnitsInDecisionOrder = sortBy(claimUnits, it => it.claimActivity.decision?.date)

    const report = claimUnitsInDecisionOrder.flatMap(({claimActivity, policy}) => {
        const approvals = extractClaimPlanYearBenefitApprovalReportRowsIfAnyFrom(claimActivity, policy, allFunds)
        return outerJoinTopLevelPolicyAndClaimActivityRowWith(approvals, claimActivity, policy)
    })

    return report
}

function generateCoverCheckLineItemsReport(coverCheckUnits: ClaimActivityWorkUnit[]) {

    const report = coverCheckUnits.flatMap(({claimActivity, policy}) => {
        const assessedTreatmentRequests = extractAssessedCoverCheckLineItemsReportRowsIfAnyFrom(claimActivity, policy)
        return outerJoinTopLevelPolicyAndClaimActivityRowWith(assessedTreatmentRequests, claimActivity, policy)
    })

    const sortOrder = ['date_submitted']
    return sortBy(report, sortOrder)
}


async function getAllClaimActivityWorkUnits(authAndInfra: AmplifyAuthAndInfra): Promise<ClaimAndCoverCheckWorkUnitTuple> {

    const claimUnits: ClaimActivityWorkUnit[] = []
    const coverCheckUnits: ClaimActivityWorkUnit[] = []

    const subs = getAllSubs()

    for (const sub of subs) {
        const context = await WebCustomerApplicationContext.for(sub, authAndInfra)
        await context.getBean('repoManagementService').syncRepoWithRemote(true)

        const {claims, coverChecks, policy} = await getItemsOfWorkIn(context)

        claimUnits.push(...(claims.map(claimActivity => ({claimActivity, policy}))))
        coverCheckUnits.push(...(coverChecks.map(claimActivity => ({claimActivity, policy}))))
    }

    // const manualWorkUnits = await getManualClaimActivityWorkUnits(authAndInfra)
    // claimUnits.push(...manualWorkUnits.claimUnits)
    // coverCheckUnits.push(...manualWorkUnits.coverCheckUnits)

    return {claimUnits, coverCheckUnits}
}

async function getItemsOfWorkIn(context: BeanFactory) {

    const claimSearchService = context.getBean('claimsSearchService')
    const policyService = context.getBean('policyService')

    const policy = await policyService.getPolicy()

    const allClaimActivity = (await claimSearchService.listAllSubmittedClaimsActivities()).filter(it => !isNonsenseTestCaseThatNeverShouldHaveBeenCreated(it))

    const claims = allClaimActivity.filter(it => it.isClaim())
    const coverChecks = allClaimActivity.filter(it => it.isCoverCheck())

    return {claims, coverChecks, policy}
}

// *************** CLAIMS LINE ITEMS

function extractAssessedClaimLineItemsReportRowsIfAnyFrom(claimActivity: ClaimActivity, policy: Policy) {

    const linkedCoverCheckIds = claimActivity.assessment?.linkedClaimActivityIds ?? []

    return (claimActivity.assessment?.invoiceLineItems ?? []).map (it => {

        const {umr, yoa} = getLineItemUmr(it, claimActivity.assessment)

        const hospitalAdmission = claimActivity.assessment.hospitalAdmissions?.find(admission => admission.id === it.hospitalAdmissionId)

        const row = {
            linked_cover_checks: linkedCoverCheckIds.map(id => DomainMappings.peachifyUuid(id)).join('|'),

            benefit: it.benefitType,
            ...snomedTreatmentFields(it.procedure),
            location_of_loss: it.treatmentAddress?.postcode,
            total_claimed: penceToPounds(it.invoiceAmountInPence),
            total_eligible: penceToPounds(it.eligibleAmountInPence),

            plan_year: it.planYearId,
            treatment_date_from: ukDateOnly(hospitalAdmission?.admissionDate ?? it.treatmentDate),
            treatment_date_to: ukDateOnly(hospitalAdmission?.dischargeDate ?? it.treatmentDate),
            date_customer_paid_for_treatment: ukDateOnly(it.treatmentPaymentDate),
            invoice_line_item_umr: umr,
            invoice_line_item_yoa: yoa
        }

        return row
    })
}


// *************** COVER CHECK TREATMENTS
function extractAssessedCoverCheckLineItemsReportRowsIfAnyFrom(claimActivity: ClaimActivity, policy: Policy) {

    const {umr, yoa} = getCoverCheckUmr(claimActivity)

    return (claimActivity.assessment?.requestedTreatments ?? []).map (it => {
        const row = {
            benefit: it.benefitType,
            ...snomedTreatmentFields(it.procedure),
            treatment_cost: penceToPounds(claimActivity.costInPence),
            source_of_treatment_cost: claimActivity.costInPence ? 'customer declared' : undefined,
            treatment_status: it.approved === true ? 'APPROVED' : it.approved === false ? 'DECLINED' : 'UNDECIDED',
            umr,
            yoa
        }
        return row
    })
}


// *************** CLAIMS PLAN YEAR BENEFIT APPROVALS
function extractClaimPlanYearBenefitApprovalReportRowsIfAnyFrom(claimActivity: ClaimActivity, policy: Policy, memberFunds: MemberFunds) {

    const planYearBenefitEligibilityTotals = new PlanYearBenefitAmountTotals((claimActivity.assessment?.invoiceLineItems ?? []).map(it => new PlanYearBenefitAmount({
        planYearId: it.planYearId,
        benefitType: it.benefitType,
        amountInPence: it.eligibleAmountInPence
    })))

    // BEWARE ordering by largest first is important for dusbursment consistency
    const benefitApprovalsLargestFirst = sortBy(claimActivity.decision?.approvedCosts?.planYearBenefitApprovals ?? [], it => -it.amountInPence)

    return (benefitApprovalsLargestFirst).map(approval => {
        const eligibleAmountInPence = planYearBenefitEligibilityTotals.getTotalFor(approval.planYearId, approval.benefitType)
        const {excess: excessFund, benefit: benefitFund} = memberFunds.disburseFor(claimActivity.treatmentReceiver.id, approval.planYearId, approval.benefitType, approval.amountInPence)

        const row = {
            plan_year: approval.planYearId,
            benefit: approval.benefitType,
            date_claim_paid: ukDateTime(getDatePaid(claimActivity)),
            eligible_amount: penceToPounds(eligibleAmountInPence),
            approved_amount: penceToPounds(approval.amountInPence),

            excess_balance_start: penceToPounds(excessFund.balanceBefore),
            excess_paid: penceToPounds(excessFund.disbursed),
            excess_balance_end: penceToPounds(excessFund.balanceAfter),

            benefit_balance_start: penceToPounds(benefitFund.balanceBefore),
            benefit_used: penceToPounds(benefitFund.disbursed),
            benefit_balance_end: penceToPounds(benefitFund.balanceAfter),

        }
        return row
    })

}


// *************** TOP LEVEL
function extractClaimAndPolicyTopLevelReportRowsFrom(claimActivity: ClaimActivity, policy: Policy) {

    const claimant = claimActivity.treatmentReceiver
    const claimStage = claimActivity.stage.toLowerCase()

    const row = {
        risk_code: 'KM',
        product: policy.accountType ?? 'INDIVIDUAL',
        _plan_type: undefined as unknown, //todo-sme i.e. lite|diagnostics|treatment|full monty,
        _company_name: undefined as unknown, //todo-sme,
        _company_plan_number: undefined as unknown, //todo-sme
        unique_policy_ref: policy.longReferenceNumber,
        name_of_claimant: claimant.fullName,
        type_of_claimant: claimant.type,
        dob_of_claimant: claimant.dateOfBirth,
        address_of_claimant: claimant.address.toFormattedString(),
        postcode_of_claimant: claimant.address.postcode,
        [`${claimStage}_ref`]: claimActivity.referenceNumber,

        date_submitted: ukDateTime(claimActivity.dateSubmitted),
        date_referred: ukDateOnly(claimActivity.assessment?.referralDate),

        policy_start_date: ukDateTime(policy.startDate),
        cover_start_date: ukDateTime(policy.getPlanByLife(claimant).startDate),

        kpi_submission_to_first_acknowledgement: ifDefinedOtherwise(toOneDp(submissionToFirstAcknowledgementKpi(claimActivity)?.asWorkingDays), notApplicable),
        kpi_submission_to_referral: ifDefinedOtherwise(toOneDp(submissionToReferralKpi(claimActivity)?.asWorkingDays), notApplicable),
        kpi_submission_to_decision: ifDefinedOtherwise(toOneDp(submissionToDecisionKpi(claimActivity)?.asWorkingDays), notApplicable),

        ...(claimActivity.isClaim() ? {
            kpi_submission_to_payment: ifDefinedOtherwise(toOneDp(submissionToPaymentKpi(claimActivity)?.asWorkingDays), notApplicable),
            kpi_decision_to_payment: ifDefinedOtherwise(toOneDp(decisionToPaymentKpi(claimActivity)?.asWorkingDays), notApplicable),
            date_of_payment: ukDateTime(getDatePaid(claimActivity))
        } : {}),

        date_of_decision: ukDateTime(claimActivity.decision?.date),
        date_of_walkaway: ukDateTime(getWalkawayDate(claimActivity)),
        date_of_abandonment: ukDateTime(getAbandonmentDate(claimActivity)),
        status: getStatus(claimActivity),
        notes: claimActivity.decision?.notes ? claimActivity.decision?.notes : undefined,
        claim_id: claimActivity.id,
        policy_id: policy.id,
        repo_sub: policy.primaryLife.awsSub
    }

    return row
}

function ifDefinedOtherwise(thing: Optional<any>, otherwise: any) {
    return !isUndefined(thing) ? thing : otherwise
}

function submissionToDecisionKpi(claimActivity: ClaimActivity) {
    const decisionDate = getDecisionDateForPurposeOfKpis(claimActivity)
    return workingHoursBetween(claimActivity.dateSubmitted, decisionDate, workingHoursOptions)
}

function submissionToReferralKpi(claimActivity: ClaimActivity) {
    const referralDate = claimActivity.assessment?.referralDate
    return workingHoursBetween(claimActivity.dateSubmitted, referralDate, workingHoursOptions)
}

function submissionToPaymentKpi(claimActivity: ClaimActivity) {
    const paymentDate = getDatePaid(claimActivity)
    return workingHoursBetween(claimActivity.dateSubmitted, paymentDate, workingHoursOptions)
}

function decisionToPaymentKpi(claimActivity: ClaimActivity) {
    const paymentDate = getDatePaid(claimActivity)
    const decisionDate = getDecisionDateForPurposeOfKpis(claimActivity)
    return workingHoursBetween(decisionDate, paymentDate, workingHoursOptions)
}

function submissionToFirstAcknowledgementKpi(claimActivity: ClaimActivity) {
    return workingHoursBetween(claimActivity.dateSubmitted, getFirstAcknowledgmentDate(claimActivity), workingHoursOptions)
}

function submissionToDecisionKpiStats(claimActivities: ClaimActivity[], representation: keyof WorkingHoursRepresentations, options = {sla: undefined as number}) {
    return kpiStats(submissionToDecisionKpi, representation, claimActivities, options.sla)
}

function submissionToReferralKpiStats(claimActivities: ClaimActivity[], representation: keyof WorkingHoursRepresentations, options = {sla: undefined as number}) {
    return kpiStats(submissionToReferralKpi, representation, claimActivities, options.sla)
}

function submissionToPaymentKpiStats(claimActivities: ClaimActivity[], representation: keyof WorkingHoursRepresentations, options = {sla: undefined as number}) {
    return kpiStats(submissionToPaymentKpi, representation, claimActivities, options.sla)
}

function decisionToPaymentKpiStats(claimActivities: ClaimActivity[], representation: keyof WorkingHoursRepresentations, options = {sla: undefined as number}) {
    return kpiStats(decisionToPaymentKpi, representation, claimActivities, options.sla)
}

function submissionToFirstAcknowledgementKpiStats(claimActivities: ClaimActivity[], representation: keyof WorkingHoursRepresentations, options = {sla: undefined as number}) {
    return kpiStats(submissionToFirstAcknowledgementKpi, representation, claimActivities, options.sla)
}

function kpiStats(kpiFn: ((claimActivity: ClaimActivity) => WorkingHoursRepresentations), representation: keyof WorkingHoursRepresentations, claimActivities: ClaimActivity[], sla?: number) {
    if (!isEmpty(claimActivities)) {
        const stats = claimActivities.reduce((collector, claim) => {
            const kpi = kpiFn(claim)
            collector.kpis.push(kpi)
            collector.sum += kpi[representation]
            if (isFinite(sla) && kpi[representation] <= sla) {
                collector.kpisWithinSla.push(kpi)
            }
            return collector
        }, { kpis: [], sum: 0, kpisWithinSla: []})

        const avg = stats.sum / stats.kpis.length
        const withinSlaFraction = isFinite(sla) ? stats.kpisWithinSla.length / stats.kpis.length: undefined

        return {
            num: stats.kpis.length,
            sum: stats.sum,
            avg,
            avgTo1dp: toOneDp(avg),
            withinSlaFraction,
            withinSlaPercent: isFinite(withinSlaFraction) ? toOneDp(withinSlaFraction * 100) : undefined
        }
    }
}


function outerJoinTopLevelPolicyAndClaimActivityRowWith<T extends object>(toJoinWithRows: T[], claimActivity: ClaimActivity, policy: Policy) {
    const topLevelRow = extractClaimAndPolicyTopLevelReportRowsFrom(claimActivity, policy)

    const topLevelKeys = distinctKeysAcrossAll([topLevelRow])
    const toJoinWithKeys = distinctKeysAcrossAll(toJoinWithRows)
    const duplicateKeyNames = intersection(topLevelKeys, toJoinWithKeys)
    if (!isEmpty(duplicateKeyNames)) {
        throw new Error(`Duplicate key names on top level join: \n\n[${duplicateKeyNames}]\n\n`)
    }

    const joinedRows = toJoinWithRows.map(it => ({
        ...topLevelRow,
        ...it,
    }))

    if (isEmpty(joinedRows)) {
        // @ts-ignore
        joinedRows.push(topLevelRow)
    }
    return joinedRows
}

function throwAnErrorIfWeAreCloseToOurLimitsOfKnowledgeOfPublicHolidays() {
    const lastPublicHolidayWeKnowAbout = max(ENGLAND_PUBLIC_HOLIDAYS_22_TO_26)
    const aFewMonthsBeforeItsTooLate = subMonths(lastPublicHolidayWeKnowAbout, 4)
    const today = new Date()
    if (isAfter(today, aFewMonthsBeforeItsTooLate)) {
        throw new Error(`Hello future me! There's only a few months of public holidays left that we know about.  It's probably time you updated the ENGLAND_PUBLIC_HOLIDAYS_22_TO_26 const in calendar-kit with all the new ones`)
    }
}

const PERIOD_FORMAT = 'yyyy MM'
function getEndOfPeriod(period: Period_yyyy_MM) {
    return endOfMonth(parse(period, PERIOD_FORMAT, new Date()))
}

function wasSubmittedInOrBeforePeriodAndWasStillOpenAtPeriodEnd(activity: ClaimActivity, period: Period_yyyy_MM) {
    const endOfPeriod = getEndOfPeriod(period)
    const submittedInOrBeforePeriod = isAfter(endOfPeriod, activity.dateSubmitted)
    const dateConsideredClosed = minValidDate(activity.decision?.date, getWalkawayDate(activity), getAbandonmentDate(activity))
    const eitherStillOpenOrWasClosedAfterPeriodEnd = !dateConsideredClosed || isAfter(dateConsideredClosed, endOfPeriod)

    return submittedInOrBeforePeriod && eitherStillOpenOrWasClosedAfterPeriodEnd
}

type Period_yyyy_MM = `${number}${number}${number}${number} ${number}${number}`
function groupByReportingPeriod<T>(itemsToGroup: T[], ofDate: (item: T) => Date): Record<Period_yyyy_MM, T[]> {
    return itemsToGroup.reduce((group, it) => {
        const releventDate = ofDate(it)
        if (releventDate) {
            const periodOfReleventDate = format(releventDate, PERIOD_FORMAT) as Period_yyyy_MM
            group[periodOfReleventDate] = group[periodOfReleventDate] ?? []
            group[periodOfReleventDate].push(it)
        }
        return group
    }, {} as Record<Period_yyyy_MM, T[]>)
}

function toCsv<T extends object>(rowObjects: T[]) {
    const allFields = distinctKeysAcrossAll(rowObjects)
    return CSV.unparse({data: rowObjects, fields: allFields})
}

function distinctKeysAcrossAll(objects: object[]) {
    const keys = objects.flatMap(it => Object.keys(it))
    return uniq(keys)
}

function snomedTreatmentFields(item?: TerminologyItem) {
    return {
        treatment_code: item?.code && item?.code !== 'missing_procedure' ? `SCT:${item?.code}` : undefined,
        treatment: item?.code !== 'missing_procedure' ? item?.display : undefined,
    }
}

function ukDateOnly(date: Date) {
    return ukDateTime(date)?.substring(0, 10)
}

function ukDateTime(date: Date) {
    return date ? formatInTimeZone(date, UK_TIMEZONE, 'yyyy-MM-dd HH:mm:ss') : undefined
}

function getStatus(claimActivity: ClaimActivity) {
    // we don't model walkaways properly yet and status needs to take it into account so...
    return claimActivity.decision?.type ?? (
        getWalkawayDate(claimActivity) ? 'WALKAWAY' :
        getAbandonmentDate(claimActivity) ? 'ABANDONED' :
        'OPEN'
    )
}

function getDecisionDateForPurposeOfKpis(claimActivity: ClaimActivity) {
    // reason this exists as a function is because there was talk about including walkaways/abandonments as decided so abstracted for ease of changing in only one place if needed
    return claimActivity.decision?.date
}

function getLossDate(invoiceLineItem: ClaimInvoiceLineItem, assessment: ClaimAssessment) {
    return maxValidDate (
        invoiceLineItem.treatmentDate,
        assessment.getHospitalAdmission(invoiceLineItem.hospitalAdmissionId)?.dischargeDate
    )
}

function toOneDp(num?: number) {
    return isFinite(num) ? round(num, 1) : undefined
}

class MemberFunds {

    protected fundsByLifeIdPlanYearBenefit: Map<string, Map<PlanYearId, Map<BenefitType, Funds>>> = new Map()

    private constructor(plans: Plan[]) {

        plans.forEach(plan => {

            const initialExcessFundsByPlanYearId = mapGroupDistinctBy(
                plan.planYears,
                planYear => [planYear.id, {excess: planYear.excess, fund: new Fund(planYear.excess?.amountInPence ?? 0)}]
            )

            const flattenedPlanYearBenefits = plan.planYears.flatMap(planYear => planYear.benefits.map(benefit => ({planYear, benefit})))

            const initialFundsByPlanYearBenefit = groupReduceByNested(
                flattenedPlanYearBenefits,
                it => [it.planYear.id, it.benefit.type],
                (_, {planYear, benefit}) => {
                    const excessFund = initialExcessFundsByPlanYearId[planYear.id].excess?.appliesTo(benefit.type) ? initialExcessFundsByPlanYearId[planYear.id].fund : new Fund(0)
                    const benefitFund = new Fund(benefit.coverAmountInPence)
                    return Funds.wrapExisting([excessFund, benefitFund])
            }) as Map<string, Map<BenefitType, Funds>>

            this.fundsByLifeIdPlanYearBenefit.set(plan.life.id, initialFundsByPlanYearBenefit)
        })
    }

    public static initialFundsFor(plans: Plan[]) {
        return new MemberFunds(plans)
    }

    public disburseFor(lifeId: string, planYearId: PlanYearId, benefitType: BenefitType, amountInPence: number) {
        const funds = this.fundsByLifeIdPlanYearBenefit.get(lifeId)?.get(planYearId)?.get(benefitType)
        const [excess, benefit] = funds?.disburseAmountFromEvery(amountInPence) ?? [undefined, undefined]
        return {excess, benefit}
    }
}



// *********************************************************************************
// ***************** MANUAL INPUT NEEDED BEFORE RUNNING THE REPORT *****************
// *********************************************************************************

// ***************** FIRST ACKNOWLEDGEMENT DATES FOR CLAIMS SUBMITTED VIA EMAIL ETC... *****************
const firstAcknowledgementDateByClaimIdForClaimsRaisedOutsideOfNormalProcess: Dictionary<Date> = {
    '8806852b-cc52-44b6-97a3-65319524e49c': parseISO('2024-01-15T09:05:38.000Z'),
    'bfc7128d-10c6-46a0-b6f5-ba2d78dc7ea0': parseISO('2024-01-22T11:49:17.000Z'),
    '09fe141d-60c6-4206-a229-688c0243cac0': parseISO('2024-01-26T13:39:26.000Z'),
}
function getFirstAcknowledgmentDate(activity: ClaimActivity) {
    // under normal circumstances claims will be acknowledged as soon as they are submitted via an automated response, however some claims have been raised outside of the normal submission process (i.e. came in direct to intercom or email etc...)
    // this implementation deals with those "non standard submissions" in a hardcoded way currently. For all claims raised in the "standard" way we can assume they were acknowledged immediately (i.e. the submissionDate)
    // should probably integrate with intercom to get these non-standard timestamps for real at some point
    return firstAcknowledgementDateByClaimIdForClaimsRaisedOutsideOfNormalProcess[activity.id] ?? activity.dateSubmitted
}


// ***************** WALKAWAY DATES *****************
// we don't model walkaways properly yet so...
const walkawayDatesByClaimId: Dictionary<string> = {
    '6c189c4c-c5dd-4939-b855-39f79c58ffdb': '2024-01-19T07:37:00Z',
    '545f57c9-9a16-48da-949f-75f9cdf35157': '2024-01-02T14:04:00Z',
    '98a20daa-d0e0-41b3-abc7-e5130813244f': '2023-07-25T09:13:00Z',
    '74cdd72f-df09-40a4-9d89-4db80b4c040a': '2022-12-20T16:09:00Z',
    '549d6d93-21a6-4476-8168-32ada5370749': '2023-11-15T09:13:00Z',
    'd0e5c029-702e-442b-9d6d-cf05315b5a38': '2023-07-25T10:36:00Z',
    '1b0ee0e5-e4c0-4001-866f-9cb30f278e06': '2023-11-13T13:17:00Z',
    '1e396d96-dc71-4355-a5fa-748387091ef0': '2023-11-14T09:33:00Z',
    '733b4369-a3ae-44c3-8527-bd757a15faf8': '2024-03-25T14:19:00Z',
}
function getWalkawayDateStr(claimActivity: ClaimActivity) {
    return walkawayDatesByClaimId[claimActivity.id]
}
function getWalkawayDate(claimActivity: ClaimActivity) {
    const str = getWalkawayDateStr(claimActivity)
    const walkawayDate = str ? parseISO(str) : undefined
    return walkawayDate
}


// ***************** ABANDONMENT DATES *****************
// we don't model abandonments properly yet so...
const abandonmentDatesByClaimId: Dictionary<string> = {
    'c6ddac7f-5c26-472e-b80f-d4b74c141475': '2023-11-21T17:46:00Z',
    '210f19b3-061d-4975-8f7b-0693e6c87139': '2023-05-12T18:58:00Z'
}
function getAbandonmentDateStr(claimActivity: ClaimActivity) {
    return abandonmentDatesByClaimId[claimActivity.id]
}
function getAbandonmentDate(claimActivity: ClaimActivity) {
    const str = getAbandonmentDateStr(claimActivity)
    const abandonmentDate = str ? parseISO(str) : undefined
    return abandonmentDate
}


// ***************** TEST CLAIMS THAT SHOULD'T HAVE BEEN RAISED IN PROD *****************
// a bunch of test or otherwise abandonded claims/coverchecks.
// hacky way to exclude from the bdx until we implement "abandoned claims" functionality
const nonsenseTestCaseThatNeverShouldHaveBeenCreated = [
    //claims
    '8f0be309-5a85-493a-94c1-bab2f74a1f41',
    '1a192fbf-778f-42e4-8d12-74706f4e3255',
    '0cde5629-24ee-4db7-91a0-f2cac97375dc',
    //cover checks
    'd4288a4c-324a-4b88-b55b-a41269a3178f',
    '443ba902-e3e9-4698-af5c-901483356b92',
    'f6b22b46-c04c-4a75-a468-46c9a33a887d',
    'a3466d19-ee78-423a-8d5c-c57253b9dcc0',
    'a8d6cc2a-1a78-4cc6-a0e4-57c554b731f7',
    'f2dfd191-9280-4a9e-a745-36ecd41a4e32',
    'af64b48f-4d0b-4ab5-a399-b75651bcc4e5',
    'dde8c001-4c6f-40d1-b779-ffaee3c500e0',
    '2aeb55ea-818d-4bff-ad39-db233d3a65c8',
    'f669ab11-d154-42ff-b4f9-f5e03423021b',
    '65a168ea-1dd7-4bc1-a06c-ab950fbd34f3',
    '88a89fbd-14bc-45b6-a5bc-58d2b69d8753',
    '4bf3c996-856b-410a-ab48-fe113d2a6f97',
    '5df94cfe-a547-4037-bd1b-02b13f56169f',
    'ec7552e7-61d7-49b8-804a-33ce3e54eec9',
    'd2d72b4b-5fca-4b19-af93-880f7b0cc55f',
    '824e2a8b-2266-45a7-b223-75a784098df5',
    'ac7c8ede-a81c-4727-904a-b647a016fa72',
    '3d18ad86-17e4-425d-878f-0bb5f846e593',
    'd092c3e8-41cc-4203-a701-5d1c1ba7d012',
    'ca598f65-dab4-4d4e-8950-aecc94dd6789',
    '657b4383-2cb0-4254-8642-f89427602f01',
    'f656d4a4-6db7-42ca-a9a0-4f9c7cbc3934',
    '913851d7-28ff-4fe7-a657-b4993dcc5cd3',
    'e0ad1096-98d7-4898-ab3b-7d9cdb65e32a',
    '197938d1-6cc7-415a-9c61-a09c476d57fa',
    'c52b1a2e-5077-49c8-887e-a0a759c56e91',
    'cb9f29a8-2997-4f59-ab2a-27acc0c61830',
    'e1323020-0700-49bb-b95c-c3df6ca90b08',
    'bd88f689-1e2a-4719-b6f1-5eee8889c536',
    '1f4545b1-3fdd-467f-b231-51350a1f97a7',
    '847ed829-fb6d-493b-9ed1-fc07a3f15734',
    '7dc2c67a-d835-447f-9102-2e3ab8ce5df9',
    '1c69610c-5081-47e2-82a7-e2995a41b669',
    'b396b3f4-5844-4065-beae-419cf05a9726',
    'f7f99454-af8f-468f-9aa0-024a60310c9b',
    '2dd57d26-1044-4859-831f-1a940686b21f',
    '7ef33193-cbb3-4ca1-a742-434907bc4233',
    '28d0a601-70cd-42e8-8fa0-0d470560e04b',
    'd16aca00-7e7f-4700-bf31-09d303d6961e',
    '696405a9-5ba6-4482-881f-78782fb9493e',
    'd75b4a32-8634-4252-a84f-2222e5ee7c59',
    '9d2d8ef5-48a5-40aa-ad60-034e90c4f821'
]

function isNonsenseTestCaseThatNeverShouldHaveBeenCreated(claimActivity: ClaimActivity) {
    return nonsenseTestCaseThatNeverShouldHaveBeenCreated.includes(claimActivity.id)
}


// ***************** PAYMENT DATES *****************
const paymentDatesByClaimRef: Dictionary<string> = {
    'PCY F0853': '2022-10-13',
    'PCY C4036': '2022-10-26',
    'PCY CF538': '2022-11-07',
    'PCY 38990': '2022-12-20',
    'PCY F509C': '2023-01-11',
    'PCY 8E07B': '2023-02-09',
    'PCY 81AC2': '2023-02-13',
    'PCY 66733': '2023-02-17',
    'PCY B7FDD': '2023-03-14',
    'PCY 92A0D': '2023-03-16',
    'PCY 96D58': '2023-03-27',
    'PCY 166AA': '2023-03-27',
    'PCY 71DE5': '2023-04-05',
    'PCY D7139': '2023-04-12',
    'PCY C6884': '2023-04-14',
    'PCY 5A303': '2023-04-27',
    'PCY AAA83': '2023-05-15',
    'PCY 28D2D': '2023-05-19',
    'PCY 49B08': '2023-05-30',
    'PCY E8B32': '2023-06-05',
    'PCY 9CD21': '2023-06-07',
    'PCY 19FB2': '2023-06-07',
    'PCY FEC62': '2023-06-15',
    'PCY 99296': '2023-06-19',
    'PCY 75ED4': '2023-06-28',
    'PCY 6D180': '2023-07-18',
    'PCY 0BF4F': '2023-07-18',
    'PCY 11000': '2023-08-11',
    'PCY 0D82F': '2023-08-25',
    'PCY 9B98A': '2023-09-18',
    'PCY 17D36': '2023-09-28',
    'PCY 55E0B': '2023-09-29',
    'PCY 7E0C2': '2023-10-20',
    'PCY B5BC2': '2023-11-22',
    'PCY 0C3F4': '2023-11-22',
    'PCY 0434A': '2023-11-22',
    'PCY 0DFFD': '2023-12-06',
    'PCY D5555': '2023-12-06',
    'PCY 7CC3E': '2023-12-19',
    'PCY A95C7': '2023-12-20',
    'PCY C9A9F': '2024-01-04',
    'PCY 207DA': '2024-01-15',
    'PCY F2DDA': '2024-01-19',
    'PCY 21039': '2024-01-19',
    'PCY 0D362': '2024-01-23',
    'PCY BFC71': '2024-01-24',
    'PCY 7A80D': '2024-01-24',
    'PCY 0693F': '2024-01-25',
    'PCY 09FE1': '2024-02-02',
    'PCY 96C51': '2024-02-12',
    'PCY FC93D': '2024-02-20',
    'PCY BA11A': '2024-02-20',
    'PCY 55375': '2024-02-23',
    'PCY 5C6EA': '2024-02-27',
    'PCY 13CE0': '2024-03-07T12:51:09Z',
    'PCY E55C7': '2024-03-13T09:52:04Z',
    'PCY 748F0': '2024-03-11T13:15:58Z',
    'PCY 09B45': '2024-03-11T13:19:41Z',
    'PCY B26FE': '2024-03-11T13:10:55Z',
    'PCY ABC21': '2024-03-11T13:11:58Z',
    'PCY 6CB6D': '2024-03-22T17:06:37Z',
    'PCY 3B6A6': '2024-03-22T13:57:10Z',
    'PCY 925D6': '2024-03-22T17:16:45Z',
    'PCY F4493': '2024-03-25T12:57:59Z',
}
function getDatePaidStrRaw(claim: ClaimActivity) {
    return paymentDatesByClaimRef[claim.referenceNumber]
}

function getDatePaid(claim: ClaimActivity) {
    const paymentDateStr = getDatePaidStrRaw(claim)
    if (paymentDateStr) {
        const weCapturedTheTime = paymentDateStr.length > 10
        if (weCapturedTheTime) {
            return parseISO(paymentDateStr)
        } else {
            // start by assuming payment was made at midday (because we don't get a time from Vitesse)
            const middayOnDatePaid = set(parseISO(paymentDateStr), {hours: 12})
            // if payment was same day as approval then they were almost certainly done at the same time so assume that
            const bestGuessOfPaymentDate = isSameDay(claim.decision.date, middayOnDatePaid) ? claim.decision.date : middayOnDatePaid
            return bestGuessOfPaymentDate
        }
    }
}

// ***************** UMR *****************
// only needs updating every binder renewall
const umrChangeDate = parseISO('2023-12-31')
function getLineItemUmr(invoiceLineItem: ClaimInvoiceLineItem, assessment: ClaimAssessment) {
    const lossDate = getLossDate(invoiceLineItem, assessment)
    return getUmrAt(lossDate)
}

function getCoverCheckUmr(coverCheck: ClaimActivity) {
    return coverCheck.isCoverCheck() ? getUmrAt(coverCheck.dateSubmitted) : undefined
}

function getUmrAt(date: Date) {
    if (isAfter(date, umrChangeDate)) {
        return {
            umr: 'B6151PEACHY2023',
            yoa: '2023'
        }
    } else {
        return {
            umr: 'B6151PEACHY2022',
            yoa: '2022'
        }
    }
}


function getAllProdSubs(): string[] {
    return [ ]
}
