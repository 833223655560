import {Modal} from '../Modal/Modal'
import {Component} from 'solid-js'
import styles from './Spinner.module.css'
import {Show} from 'solid-js/web'

export const Spinner: Component<{ isShown: boolean }> = (props) => {
    const Dot = () => <div class={styles.Dot}></div>

    return (
        <Modal isOpen={props.isShown} class={styles.Modal}>
            <div class={styles.Spinner}>
                <Dot/>
                <Dot/>
                <Dot/>
            </div>
        </Modal>
    )
}

export const LittleSpinner: Component<{ isShown: boolean }> = (props) => {
    const Dot = () => <div class={styles.Dot}></div>

    return (
        <Show when={props.isShown}>
            <div class={styles.Spinner}>
                <Dot/>
                <Dot/>
                <Dot/>
            </div>
        </Show>
    )
}
