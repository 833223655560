
export class CloudZoneConfig {

    configProfileName: string

    defaultLogRetentionDays: number
    defaultBucketRemovalPolicy: string
    defaultTableRemovalPolicy: string
    defaultFileSystemRemovalPolicy: string
    shouldDeploySupportInstance: boolean
    domainConfig?: DomainConfig
    quoteEngine?: QuoteConfig
    stripe?: StripeConfig
    slack?: SlackConfig
    peachySesSmtpUsername: string
    healthHero: HealthHeroConfig
    ascenti: AscentiConfig
    claimsActivity: ClaimsActivityConfig
    nhs: NhsConfig
    auditBccEmail?: string
    healthcheckEmails?: string
    scheduleRenewalEmails: boolean
}

export type DomainConfig = {
    rootDomainName: string
    hostedZoneId: string
    dashboardDomainName?: string
    dashboardHostedZoneId?: string
    smeWebFrontDomainName?: string
    smeWebFrontHostedZone?: string
    alternativeNames?: string[]
}

export type QuoteConfig = {
    version: number
}

export type StripeConfig = {
    individualProduct: string
}

export type SlackConfig = {
    pingHook: string
    alertHook: string
    alertChannel: string
}


export type HealthHeroConfig = {
    api: {
        domain: string
        clientId: string
    }
}

export type AscentiConfig = {
    api: {
        domain: string
    }
}

export type ClaimsActivityConfig = {
    intercom: {
        conversationStarterId: string
    }
}

export type NhsConfig = {
    login: {
        clientId: string
        oidcBaseUri: string
    }
}
