import {createUserAdminService, UserAdminClient} from './user-admin/UserAdminClient'
import {createIamService, IamService} from './iam/IamService'
import {createPaymentService, PaymentService} from './payments/PaymentService'
import {createIamApi} from './iam/IamApi'
import {createUserAdminApi} from './user-admin/UserAdminApi'
import {PaymentsApiDefinition, UserAdminApiName} from '@peachy/web-dashboard-pure'
import {makeApiClient} from '@peachy/core-domain-client'
import Auth from '@aws-amplify/auth'
import {fetchServiceConfig} from '@peachy/core-domain-client/src/fetchServiceConfig'
import {createSubscriptionService, SubscriptionService} from './subscription/SubscriptionService'
import {SubscriptionApiDef} from '@peachy/subscription-pure'
import {useServicePatch} from '@peachy/client-kit'
import { Signer } from '@aws-amplify/core'
import { API } from '@aws-amplify/api'

export type DashboardServices = {
    iamService: IamService
    userAdminService: UserAdminClient
    paymentService: PaymentService
    subscriptionService: SubscriptionService
}

export async function createDashboardServices(): Promise<DashboardServices> {

    const servicePatch = useServicePatch()

    return {
        iamService: createIamService(createIamApi()),
        userAdminService: createUserAdminService(createUserAdminApi(await fetchServiceConfig(UserAdminApiName, servicePatch))),
        paymentService: createPaymentService(await makeApiClient(PaymentsApiDefinition, servicePatch, Signer, Auth)),
        subscriptionService: createSubscriptionService(await makeApiClient(SubscriptionApiDef, servicePatch, Signer, Auth), API)
    }
}
