import { AnEvent } from '../event-kit'

export class UserCancellationEmailSent extends AnEvent {
    public accountId: string
    public policyId: string
    public lifeId: string

    constructor(from: UserCancellationEmailSent) {
        super()
        Object.assign(this, from)
    }
}
