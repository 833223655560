import {AnyFunc} from './utility-types'
import {isArray, isFunction, isNullish} from './type-check-kit'

//TODO Does this need to be relocated

type ReturnType<T> = T extends (...args: any[]) => infer R ? R : any;

type BuildPlan<T> = {
    readonly [_ in (keyof T)]: (...args: any[]) => readonly (keyof T)[] | keyof T | AnyFunc
}

type BuildPlanStepFunction<T extends BuildPlan<T>, K extends keyof T> = (...args: Parameters<T[K]>) => BuildPlanStep<T, K>

type BuildPlanStep<T extends BuildPlan<T>, K extends keyof T> = ReturnType<T[K]> extends readonly (keyof T)[] ? {
    [k in ReturnType<T[K]>[number]]?: BuildPlanStepFunction<T, k>
} : ReturnType<T[K]> extends keyof T ? {
    [k in ReturnType<T[K]>] : BuildPlanStepFunction<T, k>
} : ReturnType<ReturnType<T[K]>>


type BuildPlanArgs<T extends BuildPlan<T>> = {
    [k in keyof T]?: Parameters<T[k]> | Parameters<T[k]>[]
}

const isStepArray = <T extends BuildPlan<T>>(a: readonly (keyof T)[] | keyof T | AnyFunc): a is readonly (keyof T)[] => {
    return isArray(a)
}
const isMakerFunction = (nextStep: any): nextStep is AnyFunc => {
    return isFunction(nextStep)
}



export const makeBuilder = <T extends BuildPlan<T>, K extends keyof T>(plan: T, stepName: K): BuildPlanStepFunction<T, K> => {
    return (...args: Parameters<T[K]>) => {
        const firstBuildStep = makeBuildStep(plan, stepName)
        return firstBuildStep(...args)
    }
}

const makeBuildStep = <T extends BuildPlan<T>, K extends keyof T>(plan: T, stepName: K, planArgs: BuildPlanArgs<T> = {}): BuildPlanStepFunction<T, K> => {

    let nextStep = plan[stepName]()
    let stepFunction: BuildPlanStepFunction<T, K>

    if (isMakerFunction(nextStep)) {
        const maker: AnyFunc = nextStep
        stepFunction = (...args: Parameters<T[K]>) => {
            recordArgs(stepName, args, planArgs)

            const values = Object.keys(plan).map(k => {
                const args = planArgs[k as keyof T]
                if (args?.length > 1) {
                    return args
                } else {
                    return args?.[0]
                }
            })
            return maker(...values)
        }
    } else {

        if (!isStepArray(nextStep)){nextStep = [nextStep]}
        const nextSteps = nextStep

        stepFunction = (...args: Parameters<T[K]>) => {

            recordArgs(stepName, args, planArgs)

            let stepResult: any = {  }
            nextSteps.forEach(s => {
                stepResult[s] = makeBuildStep(plan, s, planArgs)
            })
            return stepResult
        }
    }
    return stepFunction
}




function recordArgs<T extends BuildPlan<T>, K extends keyof T>(stepName: K, args: Parameters<T[K]>, planArgs: BuildPlanArgs<T>) {
    const currentArgs = planArgs[stepName]

    const cleanArgs = args.length === 0 ? true : args.length === 1 ? args[0] : args

    if (isNullish(currentArgs)) {
        planArgs[stepName]= [cleanArgs]
    }
    else {
        currentArgs.push(cleanArgs)
    }
}

