import {Message, MessageHandler} from './ChatApi'

type HttpMessageHandlerArgs = {
    host: string
    preSend?: (m: Message) => object
    onLoading?: () => void
    onLoadingComplete?: () => void
}
export const httpMessageHandler: (args: HttpMessageHandlerArgs) => MessageHandler =
    ({
         host,
         preSend = x => x,
         onLoading = () => {},
         onLoadingComplete = () => {}
     }) => {
        let onReceiveHandler = (m: Message) => {
            console.log('received message with no handler:', m)
        }
        const doSend = async (message: Message) => {
            const response = await fetchWithAbort(host, {
                method: 'POST',
                body: JSON.stringify(preSend(message)),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            console.log('response:', response)
            return response?.json()
        }
        return {
            onReceive(handler: (message: Message) => void) {
                onReceiveHandler = handler
            },
            async send(message) {
                onLoading()
                const responseMessage = await doSend(message)
                if (!responseMessage) return
                console.log('response message:', responseMessage)
                onReceiveHandler({ text: responseMessage.response, direction: 'incoming', name: 'ai', state: responseMessage.state, audio: responseMessage.audio })
                onLoadingComplete()
            }
        }
    }

let controller: AbortController = null

async function fetchWithAbort(url: string, options: RequestInit = {}): Promise<Response | undefined> {
    if (controller) {
        controller.abort()
    }
    controller = new AbortController()
    options.signal = controller.signal

    try {
        const response = await fetch(url, options)
        controller = null
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch aborted')
        } else {
            throw error
        }
    }
}
