import {
    EnquiryDefinitionId,
    whoOptions
} from '@peachy/repo-domain'
import {
    EnquiryDefinition, QuestionIds,
    QuestionInitialisationData,
    QuestionInitialiser,
    QuestionInitialiserAndConfigTuple
} from '../types'
import {singleAnswerCheckboxQuestion} from './EnquiryDefinitionUtil'
import {
    ProcessingTag,
    TEXTRACT,
    TEXTRACT_INVOICE,
    TRANSCRIBE_MEDICAL_AUDIO
} from '@peachy/core-domain-pure'

export abstract class EnquiryDefinitionCommon implements EnquiryDefinition {

    abstract id: EnquiryDefinitionId
    abstract questions: QuestionInitialiserAndConfigTuple[]

    //todo move this to some shared 'processing' module
    static readonly transcribeMedicalAudioTag = TRANSCRIBE_MEDICAL_AUDIO
    static readonly textractGeneralTag = TEXTRACT
    static readonly textractInvoiceTag = TEXTRACT_INVOICE
    static readonly allProcessingTags: ProcessingTag[] = [
        TEXTRACT,
        TEXTRACT_INVOICE,
        TRANSCRIBE_MEDICAL_AUDIO
    ]

    static getProcessingTags(tags?: string[]): ProcessingTag[] {
        return (tags?.filter(it => this.allProcessingTags.includes(it as ProcessingTag)) ?? []) as ProcessingTag[]
    }

    static readonly hideAnswersInChatTag = 'HIDE_ANSWERS_IN_CHAT'
    static readonly hideQuestionInChatTag = 'HIDE_QUESTION_IN_CHAT'
    static readonly forceNumericKeyboardTag = 'NUMERIC_KEYBOARD'
    static readonly accHolderNameTag = 'ACC_HOLDER_NAME:'

    static readonly hideAnswersInChat = [EnquiryDefinitionCommon.hideAnswersInChatTag]
    static readonly hideQuestionInChat = [EnquiryDefinitionCommon.hideQuestionInChatTag]
    static readonly forceNumericKeyboard = [EnquiryDefinitionCommon.forceNumericKeyboardTag]

    get whoIsTheAppOwner (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Who is the App owner?`,
            embeddedOptions: whoOptions([policy.primaryLife]),
            tags: EnquiryDefinitionCommon.hideQuestionInChat
        })
        question.id = QuestionIds['who-is-the-app-owner']
        return question
    }
}