import {Accessor, createEffect, createSignal, For, Resource, Show} from 'solid-js'
import styles from './Chat.module.css'
import {httpMessageHandler} from './HttpMessageHandler'
import {createChatApi, Message} from './ChatApi'
import {Modal} from '@peachy/client-kit'
import {TextArea} from '../../../components/TextBox/TextArea'
import {useNav} from '../../../page-components/Dashboard/Dashboard'
import {last} from '@peachy/utility-kit-pure'
import {Flex} from '../../../components/deprecated/Flex/Flex'
import {AudioAlignment, Scene} from './Avatar'

// TODO remove hardcoding endpoint
// const httpEndpoint = 'https://0bg4uxqah7.execute-api.eu-west-2.amazonaws.com/default/Demo1-ClaimsActivityStack-handleGpRequest'
const httpEndpoint = 'https://h4pnnzhoyq47fz5zv6jkaj4jha0rsmhu.lambda-url.eu-west-2.on.aws'

const demoMedicalHistory = `Name: Amit Patel
Age: 46
Gender: Male`

export default function GpChat() {
    const nav = useNav()
    nav.hide()
    const savedHistory = localStorage.getItem('gp-chat-history')
    let savedMedicalHistory = localStorage.getItem('chat-medical-history')
    if (!savedMedicalHistory) {
        localStorage.setItem('chat-medical-history', JSON.stringify(demoMedicalHistory))
        savedMedicalHistory = JSON.stringify(demoMedicalHistory)
    }
    console.log('savedHistory', savedHistory)
    const [history, setHistory] = createSignal<Message[]>(savedHistory ? JSON.parse(savedHistory) : [])
    const [isLoading, setIsLoading] = createSignal<boolean>(false)
    const [medicalHistory, setMedicalHistory] = createSignal(JSON.parse(savedMedicalHistory))
    const [audio, setAudio] = createSignal<HTMLAudioElement>()
    const [audioAlignment, setAudioAlignment] = createSignal<AudioAlignment>()
    let chatHistory: HTMLDivElement
    createEffect(() => {
        isLoading()
        history()
        chatHistory.scrollTop = chatHistory.scrollHeight
    })
    const updateMedicalHistory = (details: string) => {
        setMedicalHistory(details)
        localStorage.setItem('chat-medical-history', JSON.stringify(details))
    }
    const chatApi = createChatApi({
        getHistory: history,
        setHistory: messages => {
            setHistory(messages)
            console.log('setting history', messages)
            localStorage.setItem('gp-chat-history', JSON.stringify(messages))
        },
        messageHandler: httpMessageHandler({
            host: httpEndpoint,
            preSend: _message => ({
                medicalHistory: medicalHistory(),
                history: history().map(message => ({...message, audio: ''})), // Don't send the audio back every time
                state: last(history())?.state
            }),
            onLoading: () => {
                if (history().length) {
                    setTimeout(() => {
                        setIsLoading(true)
                    }, 1000)
                }
            },
            onLoadingComplete: () => {
                setIsLoading(false)
                const audioResponse = JSON.parse(last(history()).audio)
                audio()?.pause()
                setAudio(new Audio(`data:audio/mp3;base64,${audioResponse.audio_base64}`))
                audio().play()
                setAudioAlignment(audioResponse.alignment)
            }
        })
    })
    if (history().length === 0) {
        void chatApi.init()
    }
    const clearHistory = async () => {
        setHistory([])
        await chatApi.init()
        localStorage.setItem('gp-chat-history', JSON.stringify([]))
    }
    return <div>
        <Flex row justifyContentCenter>
            <div class={styles.Chat}>
                <ChatHistory ref={chatHistory} history={history} isLoading={isLoading}></ChatHistory>
                <ChatInput sendMessage={chatApi.send}></ChatInput>
                <Flex row justifyContentEnd>
                    <button onClick={clearHistory} class={styles.OtherButton}>Clear Chat</button>
                    <ChatMedicalHistory content={medicalHistory} setContent={updateMedicalHistory}></ChatMedicalHistory>
                </Flex>
            </div>
            <Scene audio={audio} audioAlignment={audioAlignment} />
        </Flex>
    </div>
}

function ChatInput({sendMessage}: { sendMessage: (m: string) => void }) {
    const [content, setContent] = createSignal('')
    const onEnter = () => {
        if (content().trim().length != 0) {
            sendMessage(content())
            setContent('')
        }
    }
    return <div class={styles.TextAreaWithSendButton}>
        <TextArea value={content()} onChange={setContent} onEnter={onEnter}/>
        <i onClick={onEnter} class="fa-solid fa-paper-plane-top"></i>
    </div>
}

function ChatHistory({history, isLoading, ref}: {
    history: () => Message[],
    isLoading: () => boolean,
    ref: HTMLDivElement
}) {
    return <div ref={ref} class={styles.ChatHistory} >
        <For each={history()}>{message =>
            <>
                <Show when={message.direction === 'incoming'}>
                    <IncomingMessage>{message.text}</IncomingMessage>
                </Show>
                <Show when={message.direction === 'outgoing'}>
                    <OutgoingMessage>{message.text}</OutgoingMessage>
                </Show>
                <br/>
            </>
        }</For>
        <Show when={isLoading()}>
            <IncomingMessage>
                <TypingSpinner/>
            </IncomingMessage>
            <br/>
        </Show>
    </div>
}

function IncomingMessage(props) {
    return <div class={styles.IncomingMessageRow}>
        <div class={styles.IncomingMessage}>
        {props.children}
        </div>
    </div>
}

function OutgoingMessage(props) {
    return <div class={styles.OutgoingMessageRow}>
        <div class={styles.OutgoingMessage}>
            {props.children}
        </div>
    </div>
}

function ChatMedicalHistory(props) {
    const [localContent, setLocalContent] = createSignal(props.content())
    const [isOpen, setIsOpen] = createSignal(false)
    const save = () => {
        props.setContent(localContent())
        setIsOpen(false)
    }
    return <>
        <button class={styles.OtherButton} onClick={() => setIsOpen(true)}>Medical History</button>
        <Modal isOpen={isOpen()} class={styles.modal} onDismiss={() => setIsOpen(false)}>
            <h3>Medical History</h3>
            <TextArea class={styles.MedicalDetailsInput} value={localContent()} onChange={setLocalContent}></TextArea>
            <button onClick={save}>Save</button>
        </Modal>
    </>
}

function TypingSpinner() {
    const Dot = () => <div class={styles.Dot}></div>
    return <div class={styles.TypingSpinner}>
        <Dot/>
        <Dot/>
        <Dot/>
    </div>
}
