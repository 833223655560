import {Api, defineApi, postOf, SubscriptionRequest} from '@peachy/core-domain-pure'
//FIXME: subscription pure should not have a dependency on web dashboard
import {DashboardGroups} from '@peachy/web-dashboard-pure'

export class SubscriptionResponse {
    done: boolean
}

export const SubscriptionApiDef = defineApi('LambdaSubscriptionApi')
    .forGroups(DashboardGroups)
    .withActions({
        setupGroupSubscription: postOf(SubscriptionRequest).fromGroups('AdminUsers').willReturn(SubscriptionResponse)
    })

export type SubscriptionApi = Api<typeof SubscriptionApiDef>

