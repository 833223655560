import styles from './MainPane.module.css'
import {Route, Routes} from '@solidjs/router'
import {IamRoute} from '../MainNav/IamRoute'
import {Home} from '../../pages/Home/Home'
import {AdminUserGroup, UserAdminActions} from '@peachy/web-dashboard-pure'
import {Scratch} from '../../scratch/Scratch'
import {FlashRepoPanel} from '../../pages/Admin/flash-repo/FlashRepoPanel/FlashRepoPanel'
import {AiHarness, AiHarnessContextAware} from '../../pages/Ai/AiHarness'
import { lazy } from 'solid-js'

const ClaimBdx = lazy(() => import('../../pages/bdx/ClaimBdx'))

const NewCustomerPassword = lazy(() => import('../../pages/Admin/password/NewCustomerPassword'))
const NewDashUserPassword = lazy(() => import('../../pages/Admin/password/NewDashUserPassword'))

const GroupSubscriptionPanel = lazy(() => import('../../pages/Admin/group-subscription/GroupSubscriptionPanel'))
const CancelSubscription = lazy(() => import('../../pages/Admin/subscription/CancelSubscription'))
const CancelPolicy = lazy(() => import('../../pages/Admin/subscription/CancelPolicy'));
const AiChatDemo = lazy(() => import('../../pages/demo/ai/VitalityChat'))
const GpChatDemo = lazy(() => import('../../pages/demo/ai/GpChat'))

const CustomerAppContextAwareRoutes = lazy(() => import('../../pages/Customer/CustomerAppContextAwareRoutes'))

export function MainPane() {
    return (
        <main class={styles.MainPane}>
            <Routes>
                {/*<IamRoute path={'/premium-bdx'} groups={[AdminUserGroup, InsurerUserGroup]} component={PremiumBdx}/>*/}
                <IamRoute path={'/claim-bdx'} groups={[AdminUserGroup]} component={ClaimBdx}/>
                <IamRoute path={`/${UserAdminActions.newCustomerPassword}`} groups={AdminUserGroup} component={NewCustomerPassword}/>
                <IamRoute path={`/${UserAdminActions.newDashUserPassword}`} groups={AdminUserGroup} component={NewDashUserPassword}/>

                {/*<IamRoute path={`/payments`} groups={AdminUserGroup} component={PaymentsPanel}/>*/}

                <IamRoute path={`/group-subscription`} groups={AdminUserGroup} component={GroupSubscriptionPanel}/>
                <IamRoute path={`/account/:accountId/subscription/:subscriptionId`} groups={AdminUserGroup} component={CancelSubscription}/>
                <IamRoute path={`/account/:accountId/subscription/:subscriptionId/member/:memberId`} groups={AdminUserGroup} component={CancelPolicy}/>
                <IamRoute path={`/scratch`} groups={AdminUserGroup} component={Scratch}/>

                <Route path="/ai" component={AiHarnessContextAware}/>
                <Route path={`/ai-chat`} component={AiChatDemo}/>
                <Route path={`/gp-chat`} component={GpChatDemo}/>

                <Route path="/customer/:customerId/*" component={CustomerAppContextAwareRoutes}/>
                <Route path={'*'} component={Home}/>
            </Routes>
        </main>
    )
}