import {sortBy} from 'lodash-es'
import {isAfter, subMinutes} from 'date-fns'
import {DomainMappings, RepoAppointment} from '@peachy/repo-domain'
import {PeachyFlashRepo} from '../types'
import {PeachyMapRepository} from './abstract/PeachyMapRepository'

export class AppointmentRepository extends PeachyMapRepository<RepoAppointment> {

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.appointments)
    }

    async getAllFutureAppointments(minutesLeniency: number = 0) {
        const toDate = DomainMappings.fromRepo.toDate
        const maxAppointmentStartDate = subMinutes(new Date(), minutesLeniency)
        const futureAppointments = await this.filter(it => isAfter(toDate(it.date),  maxAppointmentStartDate))
        return sortBy(futureAppointments, it => toDate(it.date))
    }

}