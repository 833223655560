import {groupBy} from 'lodash-es'
import {QuestionIds} from './enquiry/types'
import {EnquiryService} from './enquiry/EnquiryService'
import {LifeService} from './LifeService'
import {InProgressService} from './InProgressService'
import {RepoManagementService} from './RepoManagementService'
import {PolicyService} from './PolicyService'
import {
    DomainMappings,
    AppointmentType,
    Appointment,
    InProgressAppointmentBooking,
    Question,
    QuestionAnswer,
    EnquiryDefinitionId,
    whoOptions, AppointmentTypes
} from '@peachy/repo-domain'
import {newUUID, Logger, Dictionary} from '@peachy/utility-kit-pure'
import {AppointmentRepository, PeachyRepoRootNode} from '@peachy/flash-repo-peachy-client'
import {PeachyFlashRepo} from '@peachy/flash-repo-peachy-client'
import {VirtualGpService} from './VirtualGpService'
import {PhysioService} from './PhysioService'

export type AppointmentProviderService = {
    bookingEnquiryDefinitionId: EnquiryDefinitionId
    getBookingConfirmationMessage(): string
    confirmBooking(appointment: InProgressAppointmentBooking): Promise<Appointment>
    cancelBooking(appointment: Appointment): void
    cancelBookingAndRebook(appointment: Appointment, rebookingQuestion: Question): Promise<Appointment>
    getChooseAppointmentDateQuestion(): Question
}

export class AppointmentsService {

    protected readonly appointmentProviderServiceByType: Dictionary<AppointmentProviderService>

    constructor(protected readonly logger: Logger,
                protected readonly repo: PeachyFlashRepo,
                protected readonly appointmentRepository: AppointmentRepository,
                protected readonly enquiryService: EnquiryService,
                protected readonly inProgressService: InProgressService,
                protected readonly lifeService: LifeService,
                protected readonly policyService: PolicyService,
                protected readonly repoManagementService: RepoManagementService,
                protected readonly virtualGpService: VirtualGpService,
                protected readonly physioService: PhysioService) {

        this.appointmentProviderServiceByType = {
            [AppointmentTypes.VIRTUAL_GP]: virtualGpService,
            [AppointmentTypes.PHYSIO]: physioService,
        }

    }

    async save(appointment: Appointment, context?: PeachyRepoRootNode) {
        const repoAppointment = DomainMappings.fromDomain.toRepoAppointment(appointment)
        return this.appointmentRepository.save(repoAppointment, context)
    }

    async saveInProgress(appointment: InProgressAppointmentBooking) {
        const repoAppointment = DomainMappings.fromDomain.toRepoInProgressAppointmentBooking(appointment)
        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            await this.enquiryService.save(appointment.enquiry, rootTx)
            await this.inProgressService.save(appointment.type, repoAppointment, rootTx)
        })
    }

    async getInProgressOrInitiateNewAppointmentBooking(appointmentType: AppointmentType) {
        const inProgress = await this.getInProgressAppointmentBooking(appointmentType)
        if (inProgress) {
            this.logger.debug('found inprogress', appointmentType, inProgress.id, inProgress.enquiryId)
            return inProgress
        } else {
            this.logger.debug('creating new', appointmentType)
            return this.initiateNewInProgressBooking(appointmentType)
        }
    }

    async getInProgressAppointmentBooking(appointmentType: AppointmentType) {
        const inProgress = await this.inProgressService.get(appointmentType)
        if (inProgress) {
            const enquiry = await this.enquiryService.get(inProgress.enquiryId)
            return DomainMappings.fromRepo.toInProgressAppointmentBooking(inProgress, enquiry)
        }
    }

    async initiateNewInProgressBooking(appointmentType: AppointmentType, initAnswers?: Dictionary<QuestionAnswer[]>) {
        const enquiry = await this.initiateNewEnquiryFor(appointmentType, initAnswers)
        const inProgress = new InProgressAppointmentBooking(newUUID(), appointmentType, new Date(), enquiry)
        this.logger.debug('created new', appointmentType, 'id: ', inProgress.id, 'enquiryId:', enquiry.id)
        return inProgress
    }

    async deleteInProgressAppointmentBookingAndEnquiry(appointment: InProgressAppointmentBooking) {
        await this.inProgressService.deleteInProgressThingAndEnquiry(appointment)
    }

    protected async initiateNewEnquiryFor(appointmentType: AppointmentType, initAnswers?: Dictionary<QuestionAnswer[]>) {
        const policy = await this.policyService.getPolicy()
        const appOwnerOption = whoOptions([await this.lifeService.getPrimaryLife()])[0]
        const init = {[QuestionIds['who-is-the-app-owner']]: [appOwnerOption]}
        return this.enquiryService.initiateNewEnquiry(this.appointmentProviderServiceFor(appointmentType).bookingEnquiryDefinitionId, policy, {...init, ...initAnswers})
    }

    async confirmBooking(bookingInProgress: InProgressAppointmentBooking) {
        const appointment = await this.appointmentProviderServiceFor(bookingInProgress).confirmBooking(bookingInProgress)
        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            await this.save(appointment, rootTx)
            await this.enquiryService.save(bookingInProgress.enquiry, rootTx)
            await this.inProgressService.deleteInProgressThingButPreserveEnquiry(bookingInProgress, rootTx)
        })
        // should use a decorator pattern to remove the syncing concern from this service or even better constantly fire off tiny update events to a back end queue for processing
        await this.repoManagementService.syncRepoWithRemoteIgnoreErrors()
        return appointment
    }

    async getAppointment(appointmentId: string) {
        const repoAppointment = await this.appointmentRepository.get(appointmentId)
        const whoFor = await this.lifeService.getLife(repoAppointment.whoForId)
        return DomainMappings.fromRepo.toAppointment(repoAppointment, whoFor)
    }

    //lots of inefficiencies here.  should probably tidy
    async getFutureAppointmentsByCustomerId() {
        const minutesLeniency = 15
        const repoFutureAppointments = await this.appointmentRepository.getAllFutureAppointments(minutesLeniency)
        const allLives = await this.lifeService.getAllLives()
        const futureAppointments = repoFutureAppointments.map(
            repoAppointment => DomainMappings.fromRepo.toAppointment(repoAppointment, allLives.find(life => life.id === repoAppointment.whoForId))
        )
        return groupBy(futureAppointments, it => it.whoFor.id)
    }

    async cancelAppointment(appointment: Appointment) {
        await this.appointmentProviderServiceFor(appointment).cancelBooking(appointment)
        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            await this.appointmentRepository.delete(appointment.id, rootTx)
            await this.enquiryService.delete({id: appointment.enquiryId}, rootTx)
        })
    }

    async cancelBookingAndRebook(appointment: Appointment, rebookingQuestion: Question) {
        const reBookedAppointment = await this.appointmentProviderServiceFor(appointment).cancelBookingAndRebook(appointment, rebookingQuestion)
        await this.save(reBookedAppointment)
    }

    getChooseAppointmentDateQuestionFor(type: AppointmentType) {
        return this.appointmentProviderServiceFor(type).getChooseAppointmentDateQuestion()
    }

    getBookingConfirmationMessageFor(type: AppointmentType) {
        return this.appointmentProviderServiceFor(type).getBookingConfirmationMessage()
    }

    async markAppointmentAsAttended(appointment: Appointment) {
        appointment.attended = true
        return this.save(appointment)
    }

    private appointmentProviderServiceFor(appointmentOrType: Appointment | AppointmentType | InProgressAppointmentBooking) {
        // @ts-ignore
        const type = appointmentOrType.type || appointmentOrType
        return this.appointmentProviderServiceByType[type]
    }

}
