import {createEffect, createSignal} from 'solid-js'
import {createUserAdminMachine, UserAdminStates} from './UserAdminMachine'
import {UserAdminApi} from '@peachy/web-dashboard-pure'
import {EventTrigger} from '../../to-abstract/solid-state'


export type UserAdminClient = UserAdminApi & {
    isInState(...s: UserAdminStates[]) : boolean
    isBusy(): boolean
    error(): string
}


export function createUserAdminService(userAdminApi: UserAdminApi): UserAdminClient {

    const userAdminMachine = createUserAdminMachine()

    const [error, setError] = createSignal('')

    createEffect(() => {
        userAdminMachine.currentState()
        setError('')
    })


    function triggerEvent<R>(trigger: EventTrigger, action: () => R) {
        return new Promise<R>((resolve, reject) => {
            const didFire = trigger(async () => {
                try {
                    const result = await action()
                    userAdminMachine.success()
                    resolve(result)

                } catch (e) {
                    userAdminMachine.failure()
                    console.error('Action error', e)
                    setError(e.message)
                    reject(e)
                }
            })
            if (!didFire) reject('Invalid state transition')
        })
    }

    return {

        async newDashUserPassword(email: string, password?: string) {
            return triggerEvent(userAdminMachine.requestNewDashUserPassword, () => userAdminApi.newDashUserPassword(email, password))
        },

        async newCustomerPassword(email: string, password?: string) {
            return triggerEvent(userAdminMachine.requestNewCustomerPassword, () => userAdminApi.newCustomerPassword(email, password))
        },

        isInState(...s): boolean {
            return userAdminMachine.isInState(...s)
        },

        isBusy(): boolean {
            return userAdminMachine.hasTag('busy')
        },
        error(): string {
            return error()
        }
    }
}

