import {Api, ApiActions, ApiDef, ApiMetadata, ApiMetadataKey} from '@peachy/core-domain-pure'
import {FunctionUrlClient} from './FunctionUrlClient'
import {fetchServiceConfig} from './fetchServiceConfig'
import {Auth} from '@aws-amplify/auth'
import { Signer } from '@aws-amplify/core'

export async function makeApiClient<G extends string, A extends ApiActions<G>, T extends ApiDef<G, A>>(
    apiDef: T,
    servicePatchUri: string,
    signer: typeof Signer,
    auth?: typeof Auth,

): Promise<Api<T>> {

    const routes = await fetchServiceConfig(apiDef.apiName, servicePatchUri)

    const apiClient = new FunctionUrlClient(auth, signer)

    const api = {} as any
    Object.keys(apiDef.actions).forEach(action => {
        api[action] = async (requestBody?: any, headers?: any) => {
            return apiClient.fetchLambda(apiDef.actions[action].method, routes[action], requestBody, headers)
        }
    })
    api[ApiMetadataKey] = {routes}
    return api
}


export function getApiMetadata<A extends Api<any>>(api: A): ApiMetadata<StringKeysOf<A>> {
    // @ts-ignore
    return api[ApiMetadataKey]
}


type StringKeysOf<T extends object> = keyof T extends string ? keyof T : never


