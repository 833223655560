import {EnquiryRepository, PeachyRepoRootNode} from '@peachy/flash-repo-peachy-client'
import {isEmpty} from 'lodash-es'
import {
    DomainMappings,
    QuestionAnswer,
    Enquiry,
    EnquiryDefinitionId,
    Policy
} from '@peachy/repo-domain'
import {newUUID, Dictionary} from '@peachy/utility-kit-pure'
import {QuestionInitialisationData} from './types'
import {EnquiryDefinitionService} from './EnquiryDefinitionService'
import {EnquiryDefinitionCommon} from './definition/EnquiryDefinitionCommon'

export class EnquiryService {

    constructor(protected readonly enquiryRepository: EnquiryRepository,
                protected readonly config: QuestionInitialisationData['config'],
                protected readonly enquiryDefinitionService: EnquiryDefinitionService) {}

    async initiateNewEnquiry(definition: EnquiryDefinitionId, policy: Policy, initAnswers?: Dictionary<QuestionAnswer[]>) {
        const enquiry = new Enquiry(newUUID(), definition)
        await this.enquiryDefinitionService.initialiseQuestionsOn(enquiry, {policy, config: this.config}, initAnswers)
        return enquiry
    }

    async unsatisfyQuestions(enquiry: Enquiry, ...questionIds: string[]) {
        const updatedEnquiry = Enquiry.copyOf(enquiry)
        questionIds.forEach(questionId => updatedEnquiry.getQuestionEvenIfNotRequired(questionId)?.setSatisfied(false))
        await this.enquiryDefinitionService.updateRequiredQuestionsOn(updatedEnquiry)
        await this.enquiryDefinitionService.buildCurrentQuestionDataModel(enquiry)
        return updatedEnquiry
    }

    async unsatisfyAllQuestionsFromAndAfter(enquiry: Enquiry, firstQuestionToUnsatisfyId: string) {
        return this.unsatisfyQuestions(enquiry, ...enquiry.getQuestionsFromAndAfter(firstQuestionToUnsatisfyId).map(it => it.id))
    }

    async updateEnquiry(enquiry: Enquiry, questionId: string, answers: QuestionAnswer[]) {
        const updatedEnquiry = Enquiry.copyOf(enquiry)
        updatedEnquiry.getQuestionEvenIfNotRequired(questionId)?.satisfyWith(answers)
        await this.enquiryDefinitionService.updateRequiredQuestionsOn(updatedEnquiry)
        await this.enquiryDefinitionService.buildCurrentQuestionDataModel(enquiry)
        return updatedEnquiry
    }

    anyAskedQuestionsHaveAnswersIn(enquiry: Enquiry) {
        return !isEmpty(this.getAskedQuestionsThatHaveAnswersIn(enquiry))
    }

    private getAskedQuestionsThatHaveAnswersIn(enquiry: Enquiry) {
        const askedQuestions = enquiry.getRequiredQuestions().filter(it => !it.hasTag(EnquiryDefinitionCommon.hideQuestionInChatTag))
        return askedQuestions.filter(it => it.hasAnyAnswer())
    }

    async get(enquiryId: string) {
        const repoEnquiry = await this.enquiryRepository.get(enquiryId)
        return DomainMappings.fromRepo.toEnquiry(repoEnquiry)
    }

    async save(enquiry: Enquiry, context?: PeachyRepoRootNode) {
        const repoEnquiry = DomainMappings.fromDomain.toRepoEnquiry(enquiry)
        return this.enquiryRepository.save(repoEnquiry, context)
    }

    async delete(enquiry: Pick<Enquiry, 'id'>, context?: PeachyRepoRootNode) {
        return this.enquiryRepository.delete(enquiry.id, context)
    }

    async list(context?: PeachyRepoRootNode): Promise<Enquiry[]> {
        const repoEnquiries = await this.enquiryRepository.list(context)
        return repoEnquiries.map(DomainMappings.fromRepo.toEnquiry)
    }
}
