import {KeyMapped} from '../some-types'
import {Subscription} from './Subscription'
import {Optional, keysOf, newUUID} from '@peachy/utility-kit-pure'
import Required from '../validation/validators/Required'
import IsEmail from '../validation/validators/IsEmail'
import {Company} from './Company'
import {User} from './User'
import {Type} from 'class-transformer'


export type AccountType =
    | 'INDIVIDUAL'
    | 'GROUP'
    | 'COMPANY'
export const AccountTypes = keysOf<AccountType>()

export type AccountStatus =
    | 'DRAFT'
    | 'ACTIVE'
    | 'ARREARS'
    | 'SUSPENDED'
    | 'CANCELLED'
export const AccountStatuses = keysOf<AccountStatus>()


export type IdCheckStatus =
    | 'DRAFT'
    | 'FLAGGED'
    | 'FAILED'
    | 'PASSED'

export const IdCheckStatuses = keysOf<IdCheckStatus>()


export type SanctionsCheckStatus =
    | 'DRAFT'
    | 'FLAGGED'
    | 'FAILED'
    | 'PASSED'


export const SanctionsCheckStatuses = keysOf<SanctionsCheckStatus>()

export type LivenessCheckStatus =
    | 'DRAFT'
    | 'FAILED'
    | 'PASSED'

export const LivenessCheckStatuses = keysOf<LivenessCheckStatus>()


// redis key for account index
export const AccountIndexKey = 'peachy/account-idx'

// redis key
export function accountKey(accountId: string) {
    return `peachy/account/${accountId}`
}

// repeatable uuid
export function generateAccountId() {
    return newUUID()
}

export class DraftAccount {
    type: AccountType

    @Required()
    name: string

    @Required()
    contactName: string
    
    @Required()
    @IsEmail()
    contactEmail: string

    @Type(() => User)
    @Required<DraftAccount>('', ({instance}) => instance.type === AccountTypes.COMPANY)
    user: User

    @Type(() => Company)
    @Required<DraftAccount>('Please enter a valid company', ({instance}) => instance.type === AccountTypes.COMPANY)
    company: Company
}



export class Account extends DraftAccount {
    id: string
    status: AccountStatus = 'DRAFT'
    createdAt: number
    stripeCustomerId?: string
    subscriptions?: KeyMapped<Subscription>

    getSubscriptionForPolicy?(policyId: string): Optional<Subscription> {
        if (this.subscriptions) {
            const subscriptions: Subscription[] = Object.values(this.subscriptions)
            return subscriptions.find((subscription) => subscription.policyIds?.includes(policyId))
        }
    }

    getLatestSubscription?(): Optional<Subscription> {
        if (this.subscriptions) {
            const subscriptions: Subscription[] = Object.values(this.subscriptions)
            return subscriptions.reduce((latest, current) => {
                return latest.startDate > current.startDate ? latest : current
            })
        }
    }
}
