import {Api, defineApi, getOf, postOf} from '@peachy/core-domain-pure'
import {DashboardGroups} from '@peachy/web-dashboard-pure'
import {enumerate, keysOf} from '@peachy/utility-kit-pure'
import {
    GetClaimChecklistParams,
    GetClaimChecklistResponse,
    GetClaimChecklistResultsParams
} from './ai/ClaimChecklistModel'

class GetMediaUrisRequestParams {
    claimId: string
    sub: string
}

export type TerminologyItemExtraction = {
    display: string
    code: string
    synonyms: string[]
    type: TerminologyItemType
    snomedType: SnomedType
    text?: string[]
}

export const TerminologyItemTypes = enumerate([
    'symptom',
    'condition',
    'treatment'
])
export type TerminologyItemType = keyof typeof TerminologyItemTypes

export type SnomedConcept = {
    type: SnomedType
    display: string
    code: string
    synonyms: string[]
}

export type FindSnomedsRequest = {
    searchTerm: string
    numResults?: number
}

export const SnomedTypesMap = {
    finding: '404684003',
    disorder: '64572001',
    procedure: '71388002'
}

export type SnomedType = keyof typeof SnomedTypesMap
export const SnomedTypes = keysOf<SnomedType>()

export type PatientProfile = {
    name: string
    dob: Date
    gender: string
    medicalNotes: MedicalNote[]
    potentialDiagnoses: PotentialDiagnosis[]
    followUpQuestions: FollowUpQuestion[]
}

export type MedicalNote = {
    date: string
    note: string
    source: string
    name: string
}

export type PotentialDiagnosis = {
    name: string
    snomed: TerminologyItemExtraction
    description: string
    differentials: string
    demographics: string
    symptoms: string
    indicators: string
    contraindicators: string
    prognosis: string
    treatments: string
    tests: DiagnosisTest[]
    confidence: number
}

export type DiagnosisTest = {
    snomed: TerminologyItemExtraction
    reason: string
    priceRange?: {
        low: number
        high: number
    }
}

export type FollowUpQuestion = {
    question: string
    reason: string
}

export const ClaimsActivityApiDefinition = defineApi('LambdaClaimsActivityApi')
    .forGroups(DashboardGroups)
    .withActions({
        adminGetMediaUris: getOf(GetMediaUrisRequestParams).fromGroups('AdminUsers').willReturn(Array<string>),
        adminGetClaimChecklist: postOf(GetClaimChecklistParams).fromGroups('AdminUsers').willReturn(GetClaimChecklistResponse),
        adminGetClaimChecklistResults: postOf(GetClaimChecklistResultsParams).fromGroups('AdminUsers').willReturn(GetClaimChecklistResponse)
    })

export type ClaimsActivityApi = Api<typeof ClaimsActivityApiDefinition>
