import {AnEvent} from '../event-kit'

export class CancelSubscriptionRequest extends AnEvent {
    public accountId: string
    public subscriptionId: string

    public cancelledAt: number
    public cancellationReason?: string

    constructor(from: CancelSubscriptionRequest) {
        super()
        Object.assign(this, from)
    }
}