import {ParentProps} from 'solid-js'
import {GeoLocationServiceController} from '@peachy/client-kit/src/services/geo-location/GeoLocationServiceController'
import {useServicePatch} from '@peachy/client-kit'


export type DashboardServiceControllerProps = ParentProps & {
}

export function DashboardServiceController2(props: DashboardServiceControllerProps) {

    const servicePatch = useServicePatch()

    return (
        <GeoLocationServiceController servicePatchUrl={servicePatch}>
            {props.children}
        </GeoLocationServiceController>
    )
}

