import {AnEvent} from '../event-kit'
import {Policy} from '../../domain/models/Policy'
import {Type} from 'class-transformer'
import {Props} from '@peachy/utility-kit-pure'


export class AlterPolicyRequest extends AnEvent {
    public accountId: string
    public subscriptionId: string
    public prorationDate?: number

    @Type(() => Policy)
    public policy: Policy

    constructor(from: Props<AlterPolicyRequest>) {
        super()
        Object.assign(this, from)
    }
}
