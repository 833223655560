import {isNil, isUndefined, negate} from 'lodash-es'
import {isNullish, isObject} from './type-check-kit'

// find out import usages
import 'intl'
import 'intl/locale-data/jsonp/en-GB'

// Better name for the file?

export const NOOP = () => {
}
export const identity = <T>(t: T): T => t
export const any = (o: Object) => o && Object.keys(o).length

export const isDefined = negate(isUndefined)
// todo - typescript

export function memoize(fn: any) {
    const cache: any = {}
    return (...args: unknown[]) => {
        let argString = JSON.stringify(args)
        return (cache[argString] = cache[argString] || fn(...args))
    }
}

type Producer<T> = () => Promise<T>

export function memo<T>(create: Producer<T>): Producer<T> {
    let theOne: T
    return async () => theOne ?? (theOne = await create())
}

export function mapObject<T extends object = object>(object: object, mapper: (key: string, value: any) => any): T {
    const mapped: any = {}
    for (let [key, value] of Object.entries(object)) {
        mapped[key] = mapper(key, value)
    }
    return mapped
}

export async function mapObjectAsync<T extends object = object>(object: object, mapper: (key: string, value: any) => Promise<any>): Promise<T> {
    const mapped: any = {}
    for (let [key, value] of Object.entries(object)) {
        mapped[key] = await mapper(value, key)
    }
    return mapped
}

export function isEmpty(value: any[] | object | string) {
    if (isObject(value)) {
        return Object.keys(value).length === 0
    }
    return isNullish(value) || value.length === 0
}

export function removeUndefinedPropertiesFrom<T extends object = object>(thing: T): Partial<T> {
    return Object.entries(thing).reduce((collector, [key, value]) => {
        if (!isNil(value)) {
            collector[key as keyof T] = value
        }
        return collector
    }, {} as Partial<T>)
}



export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const picked: Partial<Pick<T, K>> = {}
    keys.forEach(k => {
        picked[k] = obj[k]
    })
    return picked as Pick<T, K>
}