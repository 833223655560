import {Benefit, ClaimActivity, Enquiry, Question} from '@peachy/repo-domain'
import {Dictionary, enumerateKeys} from '@peachy/utility-kit-pure'
import {Life, Payment, Plan, Policy} from '@peachy/core-domain-pure'
import {ProcessedInvoice, ProcessedTextract, ProcessedTranscript} from '@peachy/aws-kit'
import {BoundingBox} from '@aws-sdk/client-textract'
import {PatientProfile, TerminologyItemExtraction} from '../claims-activity-api'
import {InferredClaimAssessmentValues} from './InferredClaimAssessmentValues'

export const ClaimsChecks = {
    COVER_CHECK_APPROVED: 'Has a cover check been done & approved?',
    TREATMENT_MATCHES_COVER_CHECK: 'Is treatment received in line with cover check?',
    BENEFIT_SELECTED: 'Does the benefit the customer has selected match what is being claimed for?',
    PLAN_ACTIVE: 'Is the plan active and the premiums paid?',
    DIETETICS_2_SESSIONS_CLAIMED: 'Have two sessions for dietetics already been claimed in the current plan year?',
    ALL_EVIDENCE_SUBMITTED: 'Has all of the required information (e.g. video selfie, receipts) been submitted?',
    NAMES_MATCH: 'Do the details of the person receiving treatment match the details on plan?',
    WITHIN_CLAIM_WINDOW: `Is treatment or payment date (whichever is later) within ${Benefit.CLAIM_WINDOW_WEEKS} weeks of claim submission?`,
    UK_PROVIDER: 'Has treatment been provided in the UK by a licensed practitioner / hospital / clinic?',
    GP_REFERRAL: 'Is there evidence of specialist or GP referral?',
    ASCENTI_PHYSIO: 'Did the member see an Ascenti physio?',
    ASCENTI_4_SESSIONS_CLAIMED: 'Have they had more than 4 sessions with an Ascenti physio in plan year?',
    BENEFIT_EXCLUSION: 'Is treatment received excluded under a benefit-specific exclusion?',
    GENERAL_EXCLUSION: 'Is treatment received excluded under general exclusions?',
    PRE_EXISTING_CONDITION: 'Is treatment received for a pre-existing condition?',
    DELEGATED_AUTHORITY_LIMIT: 'Is claim amount greater than delegated authority?',
    BANK_VERIFIED: 'Are bank details for payment verified?',
    TREATMENT_COST_REASONABLE: 'Is the amount being claimed reasonable for the given treatment?',
    TREATMENT_LOCATION_REASONABLE: 'Is the location at which the treatment was received reasonable given the customer\'s address?',
    TREATMENT_PROCEDURE_REASONABLE: 'Is the treatment the best course of action given the customer\'s history?',
}

export type ClaimsCheckId = keyof typeof ClaimsChecks

export const ClaimsCheckIds = enumerateKeys(ClaimsChecks)

export class GetClaimChecklistResultsParams {
    lifeId: string
    claimActivity: ClaimActivity
    llmProfile?: string
}

export type ClaimChecklist = Dictionary<ClaimCheckResult>

export type ClaimCheckResult = {
    status: ClaimCheckStatus
    value?: string | []
    message?: string
    supportingEvidence?: SupportingEvidence[]
}

export type SupportingEvidence = {
    type: 'image' | 'video' | 'question' | 'plaintext'
    description: string
    text?: string
    question?: Question
    fileUri?: string
    boundingBoxes?: BoundingBox[]
    startTime?: string,
    check?: ClaimsCheckId
}

export type ClaimEvidence = {
    life: Life
    policy: Policy
    policyDocHtml: string
    claimActivity: ClaimActivity
    enquiry: Enquiry
    invoices: InvoiceEvidence[]
    whatWasWrongTranscript: VideoEvidence
    whatTreatmentsTranscript: VideoEvidence
    anythingElseTranscript: VideoEvidence
    allCoverChecks?: CoverCheckEvidence[]
    linkedCoverChecks?: CoverCheckEvidence[]
    latestPayment: Payment
}

export type ClaimTestCaseEvidence = TestCaseEvidence & {
    invoices: InvoiceEvidence[]
    whatWasWrongTranscript: VideoEvidence
    whatTreatmentsTranscript: VideoEvidence
    anythingElseTranscript: VideoEvidence
    allCoverChecks?: CoverCheckEvidence[]
}

export type CoverCheckTestCaseEvidence = TestCaseEvidence & {
    referrals: ReferralEvidence[]
}

export type TestCaseEvidence = {
    life: Life
    policy: Policy
    plan: Plan
    claimActivity: ClaimActivity
    enquiry: Enquiry
    expectedOutcome?: string
    results?: GetClaimChecklistResponse
    isTestCase: boolean
}

export type InvoiceEvidence = {
    filename: string
    contents: ProcessedInvoice
}

export type VideoEvidence = {
    filename: string
    contents: ProcessedTranscript
}

export type ReferralEvidence = {
    filename: string
    contents: ProcessedTextract
}
//
// class InferredValues {
//     benefits: InferredValue<BenefitType>[]
//     symptoms: InferredValue<TerminologyItemExtraction>[]
//     disorders: InferredValue<TerminologyItemExtraction>[]
//     treatments: InferredValue<TerminologyItemExtraction>[]
//
//     getBenefits = (): BenefitType[] => this.benefits?.map(it => it.value)
//     getSymptoms = (): TerminologyItemExtraction[] => this.symptoms?.map(it => it.value)
//     getDisorders = (): TerminologyItemExtraction[] => this.disorders?.map(it => it.value)
//     getTreatments = (): TerminologyItemExtraction[] => this.treatments?.map(it => it.value)
// }
//
// export class InferredClaimValues extends InferredValues {
//     paymentDate: InferredValue<Date>
//     planYear: InferredValue<PlanYear>
//     onsetDate: InferredValue<Date>
//     coverChecks: InferredValue<CoverCheckEvidence>[]
//     treatmentLocation: InferredValue<Address>
//
//     getPaymentDate = (): Date => this.paymentDate?.value
//     getPlanYear = (): PlanYear => this.planYear?.value
//     getOnsetDate = (): Date => this.onsetDate?.value
//     getCoverChecks = (): CoverCheckEvidence[] => this.coverChecks?.map(it => it.value)
//     getTreatmentLocation = (): Address => this.treatmentLocation?.value
//     getAllLinkedSnomeds = (): InferredValue<TerminologyItemExtraction>[] => {
//         const allLinkedSnomeds = [...this.symptoms, ...this.disorders, ...this.treatments]
//         this.getCoverChecks().forEach(coverCheck => {
//             allLinkedSnomeds.push(...[...coverCheck.inferredValues.symptoms, ...coverCheck.inferredValues.disorders, ...coverCheck.inferredValues.treatments])
//         })
//         return groupInferredSnomedCodes(allLinkedSnomeds)
//     }
// }
//
// export class InferredCoverCheckValues extends InferredValues {}

export type InferredValue<T> = {
    value: T
    evidence?: SupportingEvidence[]
}

export type CoverCheckEvidence = {
    claimActivity: ClaimActivity
    enquiry: Enquiry
    referrals: ReferralEvidence[]
    policyDocHtml?: string
    life?: Life
    policy?: Policy
    latestPayment?: Payment
}

export type ClaimCheckStatus = 'PASS' | 'FAIL' | 'WARN' | 'N/A'

export function groupInferredSnomedCodes(inferredValues: InferredValue<TerminologyItemExtraction>[]) {
    const groupedInferredValues: InferredValue<TerminologyItemExtraction>[] = []
    inferredValues.forEach(inferredValue => {
        const existingSnomed = groupedInferredValues.find(it => it.value.display == inferredValue.value.display)
        if (existingSnomed) {
            existingSnomed.evidence.push(...inferredValue.evidence)
        } else {
            groupedInferredValues.push(inferredValue)
        }
    })
    return groupedInferredValues
}

export class GetClaimChecklistParams {
    policyId: string
    lifeId: string
    claimActivity: ClaimActivity
    allClaimActivity: ClaimActivity[]
    allEnquiries: Enquiry[]
    llmProfile?: string
}

export class GetClaimChecklistResponse {
    inferredValues: InferredClaimAssessmentValues
    checks: ClaimChecklist
    graph?: string
    patientProfile: PatientProfile
}