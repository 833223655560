import styles from './Modal.module.css'
import { Portal, Show } from 'solid-js/web'
import { classList, NOOP, Point } from '@peachy/utility-kit-pure'
import { createEffect, createSignal, onCleanup, ParentProps } from 'solid-js'
import { useOnEscapeKey } from '../../controllers/KeyboardController'

export type ModalProps = ParentProps<{
    isOpen?: boolean
    onDismiss?: () => void
    class?: string
    locatorElement?: HTMLElement
    center?: boolean
    blurBackground?: boolean
}>


export function Modal(props: ModalProps) {
    const [location, setLocation] = createSignal<Point>()

    useOnEscapeKey((evt: KeyboardEvent) => {
        if (props.isOpen) {
            props.onDismiss?.()
            evt.stopPropagation()
        }
    })

    const locateModal = () => {
        const locatorBounds = props.locatorElement?.getBoundingClientRect()
        if (locatorBounds) {
            let {x, y} = locatorBounds
            if (props.center) {
                x += (locatorBounds.width/2)
            }
            setLocation({x, y})
        }
    }

    createEffect(() => {
        locateModal()

        if(props.isOpen) {
            // Handles locating within nested modals
            locateModal()
        }
    })

    createEffect(() => {
        if (props.locatorElement) {
            window.addEventListener('resize', locateModal)
            window.addEventListener('scroll', locateModal)
            onCleanup(() => {
                window.removeEventListener('resize', locateModal)
                window.removeEventListener('scroll', locateModal)
            })
        }
    })

    const positioningStyle = () => {
        return location() ? {
            left: `${location().x}px`,
            top: `${location().y}px`,
            position: 'relative'
        }: ''
    }

    const modalPaneClasses = () => classList(
        styles.ModalPane,
        !props.locatorElement && styles.ModalPaneCentered,
        props.blurBackground && styles.blur
    )
    const modalContentClasses = () => classList(props.class, props.center && styles.ModalContentCentered)
    return (
        <Show when={props.isOpen}>
            <Portal>
                <aside class={modalPaneClasses()} onClick={props.onDismiss ?? NOOP}>
                    <div onClick={e => e.stopPropagation()} style={positioningStyle() as any} class={modalContentClasses()}>
                        {props.children}
                    </div>
                </aside>
            </Portal>
        </Show>
    )
}

