import { PropertiesOnly } from "@peachy/utility-kit-pure";
import { assign } from "lodash-es";

export class HospitalAdmission {

    readonly id: string
    readonly admissionDate: Date
    readonly dischargeDate: Date
    
    constructor(props: PropertiesOnly<HospitalAdmission>) {
        assign(this, props)
    }
}