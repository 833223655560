import {
    createResource,
    Show,
    For
} from 'solid-js'

import {
    Enquiry, 
    prettyPrintDecision
} from '@peachy/repo-domain'
import {readableDate} from '@peachy/utility-kit-pure'
import {prettyPrintBenefit, prettyPrintClaimStage} from '@peachy/repo-domain'
import {camelCase, capitalize, isEmpty, isUndefined, sum} from 'lodash-es'
import {Stat} from '../../../components/deprecated/Stat/Stat'
import {EnquiryReader, MakeClaimEnquiryReader} from '@peachy/service'
import {useBean, useCurrentCustomerId} from '../../../controllers/CustomerApplicationContextProvider'
import {Card as _Card} from '../../../components/Card/Card'
import {Svg} from '../../../components/Svg/Svg'
import {Flex} from '../../../components/deprecated/Flex/Flex'
import {Spinner} from '../../../components/Spinner/Spinner'
import {ModalOpener} from '../../../components/ModalOpener/ModalOpener'

import styles from './ClaimDetails.module.css'
import {useSearchParams} from '@solidjs/router'
import {Marginable} from '../../../components/deprecated/Margin/Margin'
import {CustomerDashboard} from '../CustomerDashboard'
import { ClaimActivityTable } from './ClaimActivityTable'
import { ProcessClaimActivity } from './ProcessClaimActivity'

const Card = Marginable(_Card)

//todo this whole file is very much a WIP / smoke and mirrors.  Needs completely redesigning and modularising
export function ClaimDetails({claimId}: {claimId: string}) {
    
    const forceEditMode = !isUndefined(useSearchParams()[0].edit)

    const policyService = useBean('policyService')
    const enquiryService = useBean('enquiryService')
    const claimsSearchService = useBean('claimsSearchService')

    const [claimData, {refetch: refetchClaimData}] = createResource(claimId, async id => {
        const claim = await claimsSearchService.getClaimActivity(id)
        const otherClaimActivities = await claimsSearchService.searchClaimsActivities(it => 
            it.id !== claim.id && 
            it.treatmentReceiverId === claim.treatmentReceiver.id
        )
        const policy = await policyService.getPolicy()
        const enquiry = await  enquiryService.get(claim.enquiryId)
        return {claim, otherClaimActivities, policy, enquiry}
    })
    const loaded = () => !claimData.loading && !!claim()

    const policy = () => claimData()?.policy
    const claim = () => claimData()?.claim
    const enquiry = () => claimData()?.enquiry
    const enquiryReader = () => EnquiryReader.forClaimActivity(enquiry()) as MakeClaimEnquiryReader
    const otherClaimActivities = () => claimData()?.otherClaimActivities
    const plan = () => claim()?.treatmentReceiver ? policy()?.getPlanByLife(claim().treatmentReceiver) : undefined
    const readOnlyMode = () => !forceEditMode && !!claim() && !claim().isPendingDecision()

    const declaredBenefit = () => {
        // ugliness around mental health "any" being an actual benefit type...
        return {
            text: prettyPrintBenefit(claim()?.customerDeclaredBenefitType) ?? claim()?.userChosenBenefitName ?? '',
            type: claim()?.customerDeclaredBenefitType
        }
    }

    const claimingOnOtherPolicy = () => claim()?.isClaim() && enquiryReader().extractClaimingOnAnotherPolicyFrom(enquiry())
    const decisionColor = () => claim()?.isApproved() ? 'var(--peachy-green)' : claim()?.isDeclined() ? 'var(--peachy-pink)' : 'grey'

    return (
       <Show when={loaded()} fallback={<Spinner isOpen/>}>
            <div>
                <Flex row justifyContentSpaceBetween>
                    <div>
                        <h1>{prettyPrintClaimStage(claim())}</h1>
                        <h3>Decision [<span style={{color: decisionColor()}}>{!claim()?.decision ? 'Pending' : prettyPrintDecision(claim().decision)}</span>]</h3>
                        <Show when={claim().decision?.notes}><span>Notes: {claim().decision?.notes}</span></Show>
                        <h3>{claim().referenceNumber} / {claim().treatmentReceiver.fullName}</h3>
                        <p>
                            <ModalOpener trigger={<a>Customer details</a>}>
                                <div class={styles.QnA} style={{"min-width": "105ch"}}><CustomerDashboard/></div>
                            </ModalOpener>
                        </p>
                    </div>
                    <h3>
                        <ModalOpener trigger={<button>QnA</button>}>
                            <QandA claimId={claim().id} enquiry={enquiry()}/>
                        </ModalOpener>
                    </h3>
                </Flex>


                <Flex row justifyContentSpaceBetween marginTop>
                    <Stat title={'Policy'}
                          value={capitalize(policy().status)}
                          valueColor={policy().isActive ? 'info' : 'primary'}
                          description={<>Start date: {readableDate(policy().startDate)}</>}
                    />
                    <Stat marginLeft
                          title={'Premiums'}
                          value={'Check'}
                          valueColor={'primary'}
                    />

                    <Stat marginLeft
                          title={'Sanctions'}
                          value={'Check'}
                          valueColor={'primary'}
                          description={<>Last checked {readableDate(policy().startDate)}</>}
                    />

                    {claim().isClaim() &&
                        <Stat marginLeft
                              title={'Other policy'}
                              value={claimingOnOtherPolicy() ? 'Yes' : 'No'}
                              valueColor={claimingOnOtherPolicy() ? 'primary' : 'info'}
                              description={'Being claimed on another policy?'}
                        />}

                </Flex>


                <Flex row justifyContentSpaceBetween marginTop>
                    
                    <Stat marginTop={0.5} 
                        title="Declared Benefit"
                        value={declaredBenefit()?.text}
                        figure={declaredBenefit().type ? <Svg svgName={camelCase(declaredBenefit().type)}/> : undefined}
                    />

                    <Stat marginLeft
                          title={`${prettyPrintClaimStage(claim())} submitted`}
                          value={readableDate(claim().dateSubmitted)}
                    />
                </Flex>

                <Card marginTop>
                    <Show when={plan()}>
                        <ProcessClaimActivity 
                            claimActivityId={claimId}
                            otherClaimActivities={otherClaimActivities()}
                            enquiry={enquiry()}
                            readOnlyMode={readOnlyMode()} 
                            forceEditMode={forceEditMode} 
                            plan={plan()}
                            onSaveOrApplyDecision={refetchClaimData}/>
                    </Show>
                </Card>


                <Flex row justifyContentSpaceBetween marginTop>
                    {claim().isClaim() &&
                        <ModalOpener trigger={<button>Bank details</button>}>
                            <Card>
                                <div style={{padding: '5em'}}>
                                <Flex column alignItemsCenter>
                                    <h3>{enquiryReader().extractBankDetailsFrom(enquiry()).name}</h3>
                                    <div><strong>Acc:</strong> {enquiryReader().extractBankDetailsFrom(enquiry()).accountNumber}<br/>
                                    <strong>Sort:</strong> {enquiryReader().extractBankDetailsFrom(enquiry()).sortCode}</div>
                                </Flex>
                                </div>
                            </Card>
                        </ModalOpener>}
                    {!claim().isClaim() && <div/>}
                </Flex>

                <Card marginTop>
                    <h3>Other Claim Activities</h3>

                    <Show when={!isEmpty(otherClaimActivities())} fallback={<span>None</span>}>
                        <ClaimActivityTable customerAwsSub={useCurrentCustomerId()} claims={otherClaimActivities()}/>
                    </Show>
                </Card>
            </div>
        </Show>
    )
}


const linksCache: Record<string, string[]> = {}
async function getMediaLinks(claimId: string) {
    const peachyClient = useBean('peachyClient')
    linksCache[claimId] = linksCache[claimId] ?? await peachyClient.getMediaLinksForClaimsActivity(useCurrentCustomerId(), claimId)
    return linksCache[claimId].filter(it =>
        it.includes('.mov') ||
        it.includes('.mp4') ||
        it.includes('.jpg') ||
        it.includes('.jpeg')
    )
}
function QandA({enquiry, claimId}: {claimId: string, enquiry: Enquiry} ) {
    const [mediaLinks] = createResource(claimId, getMediaLinks, {initialValue: []})

    const questionsToRender: Record<string, string> = {
        'what-are-you-claiming-for': 'Ok, what are you claiming for today?',
        'have-you-checked-your-cover': 'Did you check cover before making this claim?',
        'when-treated': 'When did you get care?',
        'record-what-was-wrong-video': 'Tell us what was wrong, the symptoms you were experiencing & the diagnosis (if you know it)',
        'record-what-treatments-video': 'Tell us what investigations / treatment you received',
        'record-anything-else-video': 'Tell us anything else that you think might be relevant',
        'capture-receipts': 'Capture your receipts',
        'collect-bank-account-details': 'Please add your bank details so we can process your payment as fast as possible!',
        'claiming-on-another-policy': 'Are you making a claim for this treatment under another policy?',
        'other-policy-details': 'Please provide the details of the other policy, including the name of the company and policy number',


        // cover check
        'who-are-you-checking-cover-for': 'Who are you checking cover for today?',
        'what-benefit-are-you-checking': 'Which benefit do you need to check cover for?',
        'what-type-of-therapist-do-you-need': 'What type of therapist do you need to see?',
        'what-type-of-mental-health-care-do-you-need': 'What care do you need?',
        'what-other-mental-health-care-do-you-need': 'Ok, tell us what you need',
        'what-conditions-and-symptoms': "Ok. Now tell us what's up (e.g. your symptoms, diagnosis if known) so we can understand why you need to get care",
        'when-did-symptoms-start': "And when did the symptoms start?  Approximate date is fine",
        'what-treatment-or-tests-do-you-need': "What treatment or tests do you need?",
        'what-care-do-you-need': "What care do you need?",
        'have-you-been-referred': "Do you have a referral from a GP or specialist to get care?",
        'have-you-got-referral-to-hand': "Ok great! Do you have the referral to hand?",
        'will-you-see-ascenti': "Are you planning to see an Ascenti physio?",
        'capture-referral-docs': "Perfect! Now we just need a photo of the referral, please make sure you capture all pages",
        'do-you-know-the-care-cost': "Do you know how much the care will cost?",
        'do-you-know-the-treatment-cost': "Do you know how much the treatment or tests will cost?",
        'what-is-the-total-cost': "Great, how much will the total cost be?",

    }

    return (
        <Card class={styles.QnA}>
            <h3>Q and A</h3>
            <For each={enquiry.getRequiredQuestions()}>{ question => {
                const questionText = questionsToRender[question.id]
                const mediaAnswers = question.getMediaAnswers()
                return (
                    <Show when={questionText}>
                        <strong>{questionText}</strong>
                        <Show when={mediaAnswers.length > 0} fallback={<p>{question.getFirstAnswer().toString()}</p>}>
                            <For each={mediaAnswers}>{ (media, i) => {
                                const mediaNumber = i() + 1
                                const filename = `${question.id}_${mediaNumber}`
                                const buttonLabel = `Show ${media.shortType}${mediaAnswers.length > 1 ? ' '+ mediaNumber : ''}`
                                return (
                                    <ModalOpener trigger={<button disabled={mediaLinks.loading}>{mediaLinks.loading ? 'Loading...' : buttonLabel}</button>}>
                                        <Card>
                                            <MediaThumbnail url={mediaLinks().find(link => link.includes(filename))}/>
                                        </Card>
                                    </ModalOpener>
                                )
                            }}</For>
                        </Show>
                    </Show>
                )
            }}</For>
        </Card>
    )
}

function MediaThumbnail({url}: {url: string}) {
    if (!url) {
        return ''
    }
    const isVideo = url.includes('.mov') || url.includes('.mp4')
    const isImg = url.includes('.jpg') || url.includes('.jpeg')
    let description = url.substring(0, url.indexOf('?X-Amz'))
    description = description.substring(description.lastIndexOf('/') + 1)
    description = description.substring(0, description.lastIndexOf('_'))

    const mediaStyle = {width: '40ch'}

    return <a href={url} target="_blank">
        <Flex>
            {isImg && <img style={mediaStyle} src={url}/>}
            {isVideo && <video style={mediaStyle} controls>
                <source src={url} type="video/mp4"/>
            </video>}
        </Flex>
        <p style={{'font-size': 'small'}}>{description}</p>
    </a>
}


