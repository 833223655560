import { Component, For } from "solid-js"
import { useAccountSubscription } from "./SubscriptionProvider"
import { Life, Policy, toClass } from "@peachy/core-domain-pure"

import styles from './CancelSubscription.module.css'
import { Link, useParams } from "@solidjs/router"
import { ukStyleDate } from "@peachy/utility-kit-pure"
import { getSubscriptionURL } from "../../../service/subscription/SubscriptionService"


export const Members: Component = () => {
    const accountSub = useAccountSubscription()

    const lives = () => {
        return (getMemberId() ? [accountSub.subscription.policies.find(p => p.lives[getMemberId()])] : accountSub.subscription.policies).flatMap(p => Object.values(p.lives))
    }

    const getPolicy = (life: Life) => {
        return accountSub.subscription.policies.find(p => p.lives[life.id])
    }

    return (
        <table class={styles.table}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Member type</th>
                    <th>Date added</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <For each={lives()}>
                    {(life) => <Member life={life} policy={getPolicy(life)} />}
                </For>
            </tbody>
        </table>
    )
}


const Member: Component<{ life: Life, policy: Policy }> = (props) => {
    const accountSub = useAccountSubscription()
    const accountId = accountSub.account.id
    const subId = accountSub.subscription.id
    const life = toClass(props.life, Life)

    return (
        <tr>
            <td><Link href={getSubscriptionURL(accountId, subId, life.id)}>{life.fullName()}</Link></td>
            <td>{life.type}</td>
            <td>{ukStyleDate(new Date(props.policy.startDate))}</td>
            {/* TODO Cancel status (at) time logic */}
            <td>{props.policy.status}</td>
        </tr>
    )
}

export const getMemberId = () => useParams().memberId