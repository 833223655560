import { sumBy } from 'lodash-es'
import { PlanYearExcessAmountMap } from '../types'
import { PlanYearExcessAmount } from './ApprovedClaimCosts' 
import { NestByPathFn, groupReduceByNested } from '@peachy/utility-kit-pure'

export class PlanYearExcessAmountTotals {
    
    private readonly totalsByPlanYearExcess: PlanYearExcessAmountMap
    readonly overallTotal: number

    constructor(planYearExcessAmounts: PlanYearExcessAmount[]) {
        const amountInPenceSumReducer = (acc: number, it: PlanYearExcessAmount) => acc + it.amountInPence
        const byPlanYearExcess: NestByPathFn<PlanYearExcessAmount> = it => [it.planYearId, it.excessId]

        this.totalsByPlanYearExcess = groupReduceByNested(planYearExcessAmounts, byPlanYearExcess, amountInPenceSumReducer, 0) as PlanYearExcessAmountMap
        this.overallTotal = sumBy(planYearExcessAmounts, it => it.amountInPence ?? 0)
    }

    getTotalFor(planYearId: string, excessId: string) {
        return this.getTotalOrUndefinedFor(planYearId, excessId) ?? 0
    }
    
    getTotalOrUndefinedFor(planYearId: string, excessId: string) {
        return this.totalsByPlanYearExcess.get(planYearId)?.get(excessId)
    }
}