import {createContext, createResource, useContext} from 'solid-js'
import {createDashboardServices, DashboardServices} from '../service/DashboardServices'
import {Show} from 'solid-js/web'

const DashboardServiceContext = createContext<DashboardServices>()

export type DashboardServiceControllerProps = {
    children: any
}

export function DashboardServiceController(props: DashboardServiceControllerProps) {

    const [dashboardServices] = createResource(createDashboardServices)

    return (
        <Show when={dashboardServices()}>
            <DashboardServiceContext.Provider value={dashboardServices()}>
                {props.children}
            </DashboardServiceContext.Provider>
        </Show>
    )
}

export function useDashboardServices() {
    return useContext(DashboardServiceContext)
}


// overkill?
export function useIamService() {
    return useContext(DashboardServiceContext)?.iamService
}
export function useUserAdminService() {
    return useContext(DashboardServiceContext)?.userAdminService
}

export function useSubscriptionService() {
    return useContext(DashboardServiceContext)?.subscriptionService
}