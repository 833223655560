import {dump, enumerate} from '@peachy/utility-kit-pure'

import {API} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import {Amplify} from '@aws-amplify/core'
import {UserAdminApiName} from '@peachy/web-dashboard-pure'
import {useServicePatch} from '@peachy/client-kit'


const Apis = [
    'QuoteApi',
    'NhsApi',
    'SubscriptionApi'
] as const
export const ApiNames = enumerate(Apis)
type ApiName = keyof typeof ApiNames

const LambdaApis = [
    UserAdminApiName,
]

export const LambdaApiNames = enumerate(LambdaApis)
type LambdaApiName = keyof typeof LambdaApiNames


const Services = enumerate([
    'DomainConfig',
    'DashIamConfig',
] as const)
type ServiceName = keyof typeof Services


export type InfraContext = {
    auth: AuthType,
    api: ApiType,
    servicePatch: ServicePatch
}

export type AuthType = typeof Auth
export type ApiType = typeof API

export type ServicePatch = Partial<{
    userPoolId: string
    userPoolWebClientId: string
    identityPoolId: string
    customerContentBucket: string
    rootDomain: string
    apis: {
        [_ in ApiName]?: {endpoint: string}
    },
    lambdaApis: {
        [_ in LambdaApiName]?: {
            [r: string]: string
        }
    }
}>



const region = 'eu-west-2'

export const servicePatch: ServicePatch = { apis: {}, lambdaApis: {} }

export async function configureInfra(): Promise<InfraContext> {


    try {
        await Promise.all(
            [
                (async () => {
                    const domainConfig = await fetchServiceConfig(Services.DomainConfig)
                    servicePatch.rootDomain = domainConfig.rootDomainName
                })(),
                (async () => {
                    const iamConfig = await fetchServiceConfig('DashIamConfig')
                    servicePatch.userPoolId = iamConfig.userPoolId
                    servicePatch.userPoolWebClientId = iamConfig.userPoolWebClientId
                    servicePatch.identityPoolId = iamConfig.identityPoolId
                    servicePatch.customerContentBucket = iamConfig.customerContentBucket
                })(),
                ...Apis.map(apiName => (async () => servicePatch.apis[apiName] = await fetchServiceConfig(apiName))()),
                ...LambdaApis.map(apiName => (async () => servicePatch.lambdaApis[apiName] = await fetchServiceConfig(apiName))()),
            ]
        )

    } catch (e) {
        console.log(e)
    }

    const awsConfig = {
        Auth: {
            userPoolId: servicePatch.userPoolId,
            userPoolWebClientId: servicePatch.userPoolWebClientId,
            identityPoolId: servicePatch.identityPoolId,
            region,
        },
        API: {
            region,
            endpoints: Object.entries(servicePatch.apis).map(([name, config]) => ({
                name,
                endpoint: config.endpoint,
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }))
        }
    }

    Amplify.configure(awsConfig)

    const infraContext = {
        api: API,
        auth: Auth,
        servicePatch
    }

    dump(servicePatch)
    return infraContext
}


async function fetchServiceConfig(service: ServiceName | ApiName | LambdaApiName): Promise<any> {
    const serviceConfigUrl = `${useServicePatch()}/${service}.json`
    const response = await fetch(serviceConfigUrl)
    return response.json()
}
