import { PropertiesOnly } from "@peachy/utility-kit-pure"
import { assign } from "lodash-es";
import { BenefitType } from "../types"
import { TerminologyItem } from "@peachy/nhs-pure"

export class CoverCheckRequestedTreatment {

    readonly id: string
    readonly procedure: TerminologyItem
    readonly reasonId: string
    readonly benefitType: BenefitType
    readonly approved: boolean

    constructor(props: PropertiesOnly<CoverCheckRequestedTreatment>) {
        assign(this, props)
    }
}