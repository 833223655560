import {classList} from '@peachy/utility-kit-pure'
import styles from './IsValidMarker.module.css'

export type IsValidMarkerProps = {
    isValid: boolean
}


export function IsValidMarker(props: IsValidMarkerProps) {
    const classes = () => classList('fa-solid fa-check', styles.IsValidMarker, props.isValid ? styles.valid : styles.invalid)
    return <i class={classes()}/>
}





