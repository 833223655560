import 'reflect-metadata'
import {DomainMappings} from '../mappings/DomainMappings'
import {Benefit} from './Benefit'
import {Life} from './Life'
import {Decision} from './Decision'
import {ClaimStage, DecisionTypes, ClaimStages, BenefitType, BenefitTypes} from './types'
import {Enquiry} from './enquiry/Enquiry'
import {Type} from 'class-transformer'
import {COMPLETE_OR_COMPLETELY_BUSTED_STATES, RepoClaimActivityMediaByFileName} from '../repo/types'
import { ClaimAssessment } from './assessment/ClaimAssessment'
import { sortBy } from 'lodash-es'
import { addWeeks, isBefore } from 'date-fns'

class CommonClaimActivity {

    @Type(() => Date)
    readonly dateCreated: Date

    constructor(readonly id: string,
                readonly stage: ClaimStage,
                dateCreated: Date) {
        this.dateCreated = dateCreated
    }

    get referenceNumber() {
        return DomainMappings.peachifyUuid(this.id)
    }
}

export type ClaimSubmissionIdentifier = {
    message: {id: string}
    conversation: {id?: string},
}

type ClaimActivityMediaByFileName = RepoClaimActivityMediaByFileName

export class ClaimActivity extends CommonClaimActivity {

    /**
     * @deprecated to be removed and replaced with just the benefitType
     */
    @Type(() => Benefit)
    private readonly benefit: Benefit

    @Type(() => Life)
    readonly treatmentReceiver: Life
    @Type(() => Date)
    readonly treatmentDate: Date
    @Type(() => Date)
    readonly dateSubmitted: Date

    constructor(id: string,
                stage: ClaimStage,
                dateCreated: Date,

                readonly enquiryId: string,
                readonly submissionId: ClaimSubmissionIdentifier | undefined,
                dateSubmitted: Date,

                benefit: Benefit, // should-dp drop this and replace with just the benefitType, I think doing that will also make claimsservice and benefit service circular deps easier to manage, plus will tidy up a lot of looking up benefit type when all you have is benefit id... however it will have consequences for timed migrations across peachy pit and mobile
                readonly userChosenBenefitName: string,
                readonly treatment: string,
                treatmentReceiver: Life,
                // should check if this is this even used? if not, remove it
                readonly treatmentProvider: string | undefined,
                readonly costInPence: number | undefined,
                treatmentDate: Date | undefined,
                readonly decision: Decision | undefined,
                readonly amountReimbursedInPence: number | undefined,
                readonly media: ClaimActivityMediaByFileName,
                readonly assessment: ClaimAssessment | undefined

    ) {
        super(id, stage, dateCreated)
        this.dateSubmitted = dateSubmitted
        this.benefit = benefit
        this.treatmentReceiver = treatmentReceiver
        this.treatmentDate = treatmentDate
    }

    public isDeclined() {
        return this.decision?.type === DecisionTypes.DECLINE
    }

    public isApproved() {
        return this.decision?.type === DecisionTypes.APPROVE
    }

    public isClaim() {
        return this.stage === ClaimStages.CLAIM
    }

    public isCoverCheck() {
        return this.stage === ClaimStages.COVER_CHECK
    }

    public isPendingDecision() {
        return !this.decision
    }

    public isForCustomerDeclaredBenefitOf(givenBenefitType: BenefitType) {
        return (this.customerDeclaredBenefitType && this.customerDeclaredBenefitType === givenBenefitType) ||
        // hacky stuff to deal with mental health in and out patient not having a proper super type so is just undefined
        !this.customerDeclaredBenefitType && [BenefitTypes.MENTAL_HEALTH_IN_PATIENT, BenefitTypes.MENTAL_HEALTH_OUT_PATIENT].includes(givenBenefitType)
    }

    public hasBeenAssessedAsRelatingTo(givenBenefitType: BenefitType) {
        return this.assessedBenefits.includes(givenBenefitType)
    }

    /**
     * @deprecated use customerDeclaredBenefitType as it's more expressive of the intent
     */
    public get benefitType() {
        return this.customerDeclaredBenefitType
    }

    public get customerDeclaredBenefitType() {
        return this.benefit?.type
    }
    
    /**
     * should-dp remove this
     * @deprecated this.benefit is due to be removed and replaced with just a reference to a benefit type.
     */
    public get benefitId() {
        return this.benefit?.id
    }

    public get uploadedOrPermanentlyFailedMediaFileNames() {
        return Object.entries(this.media)
            .filter(([_, {uploadState}]) => COMPLETE_OR_COMPLETELY_BUSTED_STATES.includes(uploadState))
            .map(([fileName, _]) => fileName)
    }

    public get assessedBenefits() {
        // should sort in benefit enum order
        return [...new Set([...(this.assessment?.invoiceLineItems ?? []), ...(this.assessment?.requestedTreatments ?? [])].map(it => it.benefitType))]
    }

    public get assessedPlanYearIds() {
        const lineItems = this.assessment?.invoiceLineItems ?? []
        return sortBy([...new Set(lineItems.map(it => it.planYearId))], it => it)
    }

    /**
     * only makes sense to call this on CLAIMs as there is no "window" for COVER CHECKs
     */
    public wasSubmittedWithinTheClaimWindowGiven(treatmentOrPaymentDate: Date) {
        const latestValidSubmissionDate = addWeeks(treatmentOrPaymentDate, Benefit.CLAIM_WINDOW_WEEKS)
        return isBefore(this.dateSubmitted, latestValidSubmissionDate)
    }
}

export class InProgressClaimActivity extends CommonClaimActivity {

    @Type(() => Enquiry)
    public enquiry: Enquiry

    constructor(id: string,
                stage: ClaimStage,
                dateCreated: Date,

                enquiry: Enquiry) {
        super(id, stage, dateCreated)
        this.enquiry = enquiry
    }
}