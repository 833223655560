import {PaymentFailed} from './models/payments/PaymentFailed'
import {PaymentReceived} from './models/payments/PaymentReceived'
import {PaymentRefunded} from './models/payments/PaymentRefunded'
import {SubscriptionRequest} from './models/SubscriptionRequest'
import {SubscriptionCreated} from './models/SubscriptionCreated'
import {PolicyDocRequest} from './models/PolicyDocRequest'
import {PolicyDocSent} from './models/PolicyDocSent'
import {RawStripeEvent} from './models/RawStripeEvent'
import {keysOf} from '@peachy/utility-kit-pure'
import {PolicyReposRequest} from './models/PolicyReposRequest'
import {PolicyReposCreated} from './models/PolicyReposCreated'
import {SubscriptionCancelled} from './models/SubscriptionCancelled'
import {CancelSubscriptionRequest} from './models/CancelSubscriptionRequest'
import {IdCheckEvent} from './models/kyc/IdCheckEvent'
import {IdCheckFailed} from './models/kyc/IdCheckFailed'
import {IdCheckPassed} from './models/kyc/IdCheckPassed'
import {AlterPolicyRequest} from './models/AlterPolicyRequest'

import {SanctionsCheckRequest} from './models/kyc/SanctionsCheckRequest'
import {SanctionsCheckEvent} from './models/kyc/SanctionsCheckEvent'
import {SanctionsCheckFailed} from './models/kyc/SanctionsCheckFailed'
import {SanctionsCheckPassed} from './models/kyc/SanctionsCheckPassed'
import {IntercomEventRequest} from './models/IntercomEventRequest'
import {SanctionsCheckFlagged} from './models/kyc/SanctionsCheckFlagged'
import {IdCheckFlagged} from './models/kyc/IdCheckFlagged'
import {IdCheckRequest} from './models/kyc/IdCheckRequest'
import { WelcomeSmeAdminEmailRequest } from './models/WelcomeSmeAdminEmailRequest'
import { WelcomeSmeAdminEmailSent } from './models/WelcomeSmeAdminEmailSent'
import { OwnerSanctionsCheckRequest } from './models/kyc/company/OwnerSanctionsCheckRequest'
import { PolicyDocChangesRequest } from './models/PolicyDocChangesRequest'
import { SmeEmployerPolicyDocChangesRequest } from './models/SmeEmployerPolicyDocChangesRequest'
import { SmeEmployerPolicyDocSent } from './models/SmeEmployerPolicyDocSent'
import { UserCancellationEmailRequest } from './models/UserCancellationEmailRequest'
import { UserCancellationEmailSent } from './models/UserCancellationEmailSent'
import { SmeCancellationEmailRequest } from './models/SmeCancellationEmailRequest'
import { SmeCancellationEmailSent } from './models/SmeCancellationEmailSent'

export const Events = {
    AlterPolicyRequest,
    PolicyReposRequest,
    PolicyReposCreated,
    PolicyDocRequest,
    PolicyDocChangesRequest,
    SmeEmployerPolicyDocChangesRequest,
    PolicyDocSent,
    SmeEmployerPolicyDocSent,

    WelcomeSmeAdminEmailRequest,
    WelcomeSmeAdminEmailSent,

    UserCancellationEmailRequest,
    UserCancellationEmailSent,
    SmeCancellationEmailRequest,
    SmeCancellationEmailSent,

    IdCheckEvent,
    IdCheckFlagged,
    IdCheckFailed,
    IdCheckPassed,
    IdCheckRequest,

    SanctionsCheckEvent,
    SanctionsCheckFailed,
    SanctionsCheckFlagged,
    SanctionsCheckPassed,
    SanctionsCheckRequest,

    OwnerSanctionsCheckRequest,

    IntercomEventRequest,

    RawStripeEvent,
    PaymentFailed,
    PaymentReceived,
    PaymentRefunded,
    SubscriptionRequest,
    SubscriptionCreated,
    CancelSubscriptionRequest,
    SubscriptionCancelled,
} as const


export type EventType = keyof typeof Events
export const EventTypes = keysOf<EventType>()

export type EventTypeFrom<ET extends EventType> = InstanceType<(typeof Events)[ET]>
