import {buildMachine, ON, StateEvents, StatePaths, TAGS} from '../../to-abstract/solid-state'

const paymentMachineDef = {
    'Idle': {
        [ON]: {
            listPayments: () => paymentMachineDef.ListingPayments,
        }
    },
    'ListingPayments': {
        [TAGS]: 'busy',
        [ON]: {
            success: () => paymentMachineDef.Idle,
            failure: () => paymentMachineDef.Idle,
        },
    },
} as const

export type PaymentMachineDef = typeof paymentMachineDef
export type PaymentStates = StatePaths<PaymentMachineDef>
export type PaymentEvents = StateEvents<PaymentMachineDef>


export function createPaymentMachine() {
    return buildMachine(paymentMachineDef)
}