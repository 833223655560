// deprecated

import {createContext, createResource, useContext, Show} from 'solid-js'

import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {configureInfra, ServicePatch} from '../service/infra/configureInfra'
import {Spinner} from '../components/Spinner/Spinner'

const InfraContextKey = createContext<InfraContext>()

export type InfraControllerProps = {
    children: any
}

type InfraContext = {
    auth: typeof Auth,
    api: typeof API,
    servicePatch: ServicePatch
}

// deprecated
export function InfraController(props: InfraControllerProps) {
    const [infraContext] = createResource(configureInfra)

    return (
        <Show when={!infraContext.loading} fallback={<Spinner isOpen/>}>
            <InfraContextKey.Provider value={infraContext()}>
                {props.children}
            </InfraContextKey.Provider>
        </Show>
    )
}

export function useAuthInfra() {
    return useContext(InfraContextKey).auth
}

export function useApiInfra() {
    return useContext(InfraContextKey).api
}

export function useServicePatch() {
    return useContext(InfraContextKey).servicePatch
}

export function useIsReady() {
    return !!useContext(InfraContextKey)
}
