import { PropertiesOnly } from "@peachy/utility-kit-pure"
import { BenefitType, PlanYearId } from "../types"
import { PlanYearBenefitAmountTotals } from "./PlanYearBenefitAmountTotals"
import { PlanYearExcessAmountTotals } from "./PlanYearExcessAmountTotals"
import { assign } from "lodash-es"

export class ApprovedClaimCosts {
    readonly planYearBenefitApprovals: PlanYearBenefitAmount[]
    readonly planYearExcessUsage: PlanYearExcessAmount[]
    
    private readonly planYearBenefitTotals: PlanYearBenefitAmountTotals
    private readonly planYearExcessTotals: PlanYearExcessAmountTotals

    constructor(props: Pick<ApprovedClaimCosts, 'planYearBenefitApprovals' | 'planYearExcessUsage'>) {
        this.planYearBenefitTotals = new PlanYearBenefitAmountTotals(props.planYearBenefitApprovals)
        this.planYearExcessTotals = new PlanYearExcessAmountTotals(props.planYearExcessUsage)
        return assign(this, props)
    }
    
    getApprovedAmountInPenceOrUndefinedFor(planYearId: PlanYearId, benefitType: BenefitType) {
        return this.planYearBenefitTotals.getTotalOrUndefinedFor(planYearId, benefitType)
    }

    getExcessUsageInPenceFor(planYearId: PlanYearId, excessId: string) {
        return this.planYearExcessTotals.getTotalFor(planYearId, excessId)
    }
    

    get overallTotalApprovedInPence() {
        return this.planYearBenefitTotals.overallTotal
    }
    
    private get overallTotalExcessUsedInPence() {
        return this.planYearExcessTotals.overallTotal
    }

    get postExcessOverallTotalApprovedInPence() {
        return this.overallTotalApprovedInPence - this.overallTotalExcessUsedInPence
    }

}

export class PlanYearBenefitAmount {

    readonly planYearId: string
    readonly benefitType: BenefitType
    readonly amountInPence: number

    constructor(props: PropertiesOnly<PlanYearBenefitAmount>) {
        this.benefitType = props.benefitType
        this.planYearId = props.planYearId
        this.amountInPence = props.amountInPence
    }
}

export class PlanYearExcessAmount {
    
    readonly planYearId: string
    readonly excessId: string
    readonly amountInPence: number

    constructor(props: PropertiesOnly<PlanYearExcessAmount>) {
        return assign(this, props)
    }
}