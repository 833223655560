import {ListPaymentsResponse, PaymentsApi} from '@peachy/web-dashboard-pure'
import {createPaymentMachine, PaymentStates} from './PaymentMachine'
import {createEffect, createSignal} from 'solid-js'

export type PaymentService = {
    listPayments(startAt: number, endAt: number): boolean
    getPaymentList(): ListPaymentsResponse

    isInState(...s: PaymentStates[]) : boolean
    isBusy(): boolean
    error(): string
}


export function createPaymentService(paymentApi: PaymentsApi): PaymentService {
    const paymentMachine = createPaymentMachine()
    const [error, setError] = createSignal('')
    const [payments, setPayments] = createSignal<ListPaymentsResponse>()

    createEffect(() => {
        paymentMachine.currentState()
        setError('')
    })

    return {
        listPayments(startAt: number, endAt: number): boolean {
            return paymentMachine.listPayments(async () => {
                const payments = await paymentApi.listPayments({startAt, endAt})
                paymentMachine.success()
                setPayments(payments)
            })
        },

        getPaymentList(): ListPaymentsResponse {
            return payments()
        },

        isInState(...s: PaymentStates[]): boolean {
            return paymentMachine.isInState(...s)
        },

        isBusy(): boolean {
            return paymentMachine.hasTag('busy')
        },
        error(): string {
            return error()
        }
    }
}
