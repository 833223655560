import { PropertiesOnly } from "@peachy/utility-kit-pure"
import { assign } from "lodash-es"
import { TerminologyItem } from "@peachy/nhs-pure"

export class ClaimActivitySubmissionReason {
    
    readonly id: string
    readonly symptoms: TerminologyItem[]
    readonly disorder?: TerminologyItem
    readonly onsetDate: Date

    constructor(props: PropertiesOnly<ClaimActivitySubmissionReason>) {
        assign(this, props)
    }
}