import xss from 'xss'
import {isArray, isObject, isString} from './type-check-kit'

const DEFAULT_INDENT = 2

export function toObjectIfPossible(thing: any, otherwise?: any) {
    let resolvedContent = otherwise ?? thing
    if (looksLikeJsonString(thing)) {
        try {
            resolvedContent = JSON.parse(thing)
        } catch (e) {
            // do nothing
        }
    }
    return resolvedContent
}

export function looksLikeJsonString(content: any) {
    return (
        isString(content) &&
        (content.trim().startsWith('[') || content.trim().startsWith('{'))
    )
}

export function stringifyIfItCanBe(thing: any, indent?: number|string) {
    return (isObject(thing) || isArray(thing)) ? JSON.stringify(thing, null, indent) : thing
}

export function cleanParseJson<T>(json: string): T {
    // sanitize
    return JSON.parse(xss(json))
}

export function toCleanJson(thing: any): string {
    return xss(JSON.stringify(thing))
}

export function sanitize<T>(thing: T): T {
    return JSON.parse(xss(JSON.stringify(thing)))
}

export function safeStringify(thing: any, indent = DEFAULT_INDENT) {
    try {
        return stringifyIfItCanBe(thing, indent)
    } catch (e) {
        const justTheKeysThen = Object.keys(thing)
        return `[KEYS ONLY] ${justTheKeysThen}`
    }
}