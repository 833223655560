import { sumBy } from 'lodash-es'
import { BenefitType, PlanYearBenefitAmountMap } from '../types'
import {PlanYearBenefitAmount } from './ApprovedClaimCosts' 
import { NestByPathFn, groupReduceByNested } from '@peachy/utility-kit-pure'

export class PlanYearBenefitAmountTotals {
    
    private readonly totalsByPlanYearBenefit: PlanYearBenefitAmountMap
    readonly overallTotal: number

    constructor(planYearBenefitAmounts: PlanYearBenefitAmount[]) {
        const amountInPenceSumReducer = (acc: number, it: PlanYearBenefitAmount) => acc + it.amountInPence
        const byPlanYearBenefit: NestByPathFn<PlanYearBenefitAmount> = it => [it.planYearId, it.benefitType]

        this.totalsByPlanYearBenefit = groupReduceByNested(planYearBenefitAmounts ?? [], byPlanYearBenefit, amountInPenceSumReducer, 0) as PlanYearBenefitAmountMap
        this.overallTotal = sumBy(planYearBenefitAmounts ?? [], it => it.amountInPence ?? 0)
    }

    getTotalFor(planYearId: string, benefitType: BenefitType) {
        return this.getTotalOrUndefinedFor(planYearId, benefitType) ?? 0
    }
    
    getTotalOrUndefinedFor(planYearId: string, benefitType: BenefitType) {
        return this.totalsByPlanYearBenefit.get(planYearId)?.get(benefitType)
    }

}
