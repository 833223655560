import {createSignal, Show} from 'solid-js'
import {useIamService} from '../../controllers/DashboardServiceController'
import {PasswordBox} from '../../components/TextBox/PasswordBox'
import {IamError} from '../components/IamError'
import {PasswordPolicy} from '../components/PasswordPolicy'
import {isValidPassword} from '../../to-abstract/validate'

export function CompleteNewPassword() {
    const iamService = useIamService()

    const [password, setPassword] = createSignal('')

    const setNewPassword = () => {
        if (isValidPassword(password())) iamService.completeNewPassword(password())
    }

    return <>
        <h3>Change password</h3>
        <IamError/>
        <PasswordBox value={password()} onChange={setPassword} onEnter={setNewPassword} placeholder={'new password'}/>
        <PasswordPolicy/>

        <button onClick={setNewPassword} disabled={!isValidPassword(password())}>Change</button>
        <a onClick={iamService.cancelNewPassword}>Cancel &rarr; </a>
    </>

}
