import {createSignal} from 'solid-js'
import {Tabs} from '../components/Tabs/Tabs'
import styles from './Scratch.module.css'

import {QuoteDemo} from './QuoteDemo/QuoteDemo'
import {ReissueDocs} from './ReissueDocs/ReissueDocs'

type Tabs = 'Reissue Policy Docs'



export function Scratch() {

    const currentTabSignal = createSignal<Tabs>('Reissue Policy Docs')

    return (
        <div class={styles.Tabs}>

            <Tabs currentTab={currentTabSignal} >{() => ({
                'Reissue Policy Docs': ReissueDocs,
            })}</Tabs>

        </div>
    )
}


