// TODO


export interface ResolvablePromise<T> extends Promise<T> {
    resolve: (value: T, timeout?: number) => Promise<void>;
    reject: (error: any, timeout?: number) => Promise<void>;
}

export function resolvablePromise<T>(): ResolvablePromise<T> {
    let resolve: (value: any) => void
    let reject: (value: any) => void

    const promise = new Promise<T>((res, rej) => {
        resolve = res
        reject = rej
    }) as ResolvablePromise<T>

    promise.resolve = async (value: T, timeout: number = 0) => {
        return new Promise((res, rej) => {
            setTimeout(() => {
                resolve(value)
                setTimeout(() => res())
            }, timeout)
        })
    }

    promise.reject = (error, timeout: number = 0) => {
        return new Promise((res, rej) => {
            setTimeout(() => {
                reject(error)
                setTimeout(() => res())
            }, timeout)
        })
    }
    return promise
}

export function onNextTick(f: () => void) {
    new Promise<void>((res, rej) => {
        res()
    }).then(f)
}

export async function concurrentlyIgnoringErrors<T>(promises: Promise<T>[]): Promise<T[]> {
    const executionResult = await polyfilPromiseAllSettled(promises)
    // @ts-ignore
    return executionResult.filter(it => it.status != 'rejected').map(it => it.value)
}

// Promise.allSettled not available in version of react-native we can use with Expo...
async function polyfilPromiseAllSettled<T>(promises: Promise<T>[]): Promise<{ status: 'fulfilled' | 'rejected', reason?: any, value?: T }[]> {
    const mappedPromises = promises.map(promise =>
        promise.then(value => ({status: 'fulfilled', value}))
            .catch(reason => ({status: 'rejected', reason})),
    )
    // @ts-ignore
    return Promise.all(mappedPromises)
}

export async function repeat(times: number, task: (i: number) => void | Promise<void>) {
    for (let i = 0; i < times; i++) {
        await task(i)
    }
}
