import { AnEvent } from '../event-kit'
import { ChangedLife } from './ChangedLife'

export class SmeEmployerPolicyDocChangesRequest extends AnEvent {
    public accountId: string
    public addedLives?: ChangedLife[]
    public removedLives?: ChangedLife[]

    constructor(from: SmeEmployerPolicyDocChangesRequest) {
        super()
        Object.assign(this, from)
    }
}