import {TextBox} from '@peachy/client-kit'
import styles from './AccountDetails.module.css'
import {DraftAccount, isValidProperty} from '@peachy/core-domain-pure'
import {IsValidMarker} from '../IsValidMarker/IsValidMarker'
import {useGroupSubscriptionRequestStore} from '../GroupSubscriptionPanel'


export function AccountDetails() {

    const store = useGroupSubscriptionRequestStore()

    const fieldValidationClass = (field: keyof DraftAccount) => {
        return isValidProperty(store.getAccountDetails(), field, null, DraftAccount)
            ? styles.validField
            : styles.invalidField
    }

    return (
        <div class={styles.AccountDetails}>
            <h3><IsValidMarker isValid={store.hasValidAccountDetails()}/>Account Details </h3>
            <TextBox value={store.getAccountDetails().name} onChange={(value) => store.updateAccountName(value)} class={fieldValidationClass('name')}>
                <h6>Account name:</h6>
            </TextBox>
            <TextBox value={store.getAccountDetails().contactName} onChange={(value) => store.updateContactName(value)} class={fieldValidationClass('contactName')}>
                <h6>Contact name:</h6>
            </TextBox>
            <TextBox value={store.getAccountDetails().contactEmail} onChange={(value) => store.updateContactEmail(value)} class={fieldValidationClass('contactEmail')}>
                <h6>Contact email:</h6>
            </TextBox>
        </div>
    )
}

