import styles from './ReissueDocs.module.css'
import {TextBox} from '@peachy/client-kit'
import {createResource, createSignal} from 'solid-js'
import {makeApiClient} from '@peachy/core-domain-client'
import {DocAdminApiDef} from '@peachy/web-dashboard-pure'
import {getZoneInfo} from '@peachy/zone-config-pure'
import Auth from '@aws-amplify/auth'
import {Spinner} from '../../components/Spinner/Spinner'
import {NOOP} from '@peachy/utility-kit-pure'
import { Signer } from '@aws-amplify/core'

export function ReissueDocs() {

    const [isSpinning, setSpinning] = createSignal(false)
    const [error, setError] = createSignal('')


    const [docAdminApi] = createResource(async () => {
        return makeApiClient(
            DocAdminApiDef,
            getZoneInfo().servicePatchUrl,
            Signer,
            Auth,
        )
    })

    const [policyId, setPolicyId] = createSignal('')

    const reissueDocs = async (policyId: string) => {
        try {
            setSpinning(true)
            await docAdminApi().reissuePolicyDocs({policyId})
        } catch (err){
            setError(err)
        } finally {
            setSpinning(false)
        }
    }


    return (
        <div class={styles.pane}>
            <TextBox value={policyId()} placeholder={'Policy ID'}  onInput={setPolicyId}>
            </TextBox>
            {docAdminApi.loading
                ? <button>...</button>
                : <button onclick={() => reissueDocs(policyId())}>Reissue</button>
            }
            <p>{error()}</p>
            <Spinner isOpen={isSpinning()} onDismiss={NOOP}/>
        </div>
    )
}