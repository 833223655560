import {createSignal} from 'solid-js'

type E = {
    [_ in keyof any]: never
}

export const ON: unique symbol = Symbol('ON')
export const INITIAL: unique symbol = Symbol('INITIAL')
export const TAGS: unique symbol = Symbol('TAGS')
const _STATE_PATH: unique symbol = Symbol('STATE_PATH')
const _PARENT: unique symbol = Symbol('PARENT')

type StateDef = {
    [ON]?: {
        [k: string]: () => StateDef
    }
    [k: string]: StateDef | E
    [_STATE_PATH]?: string
    [_PARENT]?: StateDef
    [INITIAL]?: StateDef
    [TAGS]?: string | string[]
}


type Join<A extends string, B extends string> = A extends undefined ? B : `${A}.${B}`

export type StatePaths<SN extends StateDef,
    Prev extends string = undefined,
    Path extends string = undefined,
    > = {
    [K in keyof SN & string]: SN[K] extends E
        ? Prev | Path | Join<Path, K>
        : StatePaths<SN[K], Prev | Path, Join<Path, K>>
}[keyof SN & string];


export type StateEvents<SN extends StateDef> = {
    [K in keyof SN]: K extends symbol
        ? keyof SN[K]
        : StateEvents<SN[K]>
}[keyof SN & (string | symbol)]



export type Machine<T extends StateDef> = {
    currentState: () => StatePaths<T>
    isInState: (...s: StatePaths<T>[]) => boolean
    hasTag: (t: string) => boolean
    canHandle: (e: StateEvents<T>) => boolean
} & {
    [_ in StateEvents<T>]: EventTrigger
}


export type EventAction = () => void
export type EventTrigger = (handler?: EventAction) => boolean

// -----------------------------------------

function gatherEvents(
    stateDef: StateDef,
    events: string[] = [],
    path: string = ''
) {
    stateDef[_STATE_PATH] = path
    const eventDef = stateDef[ON] ?? {}
    events.push(...Object.keys(eventDef))

    Object.keys(stateDef).forEach(subStateName => {
        const subState: StateDef = stateDef[subStateName]
        subState[_PARENT] = stateDef
        if (!stateDef[INITIAL]) {
            stateDef[INITIAL] = subState
        }
        const morePath = path ? `${path}.${subStateName}` : subStateName
        gatherEvents(subState, events, morePath)
    })
    return events
}


function initialDeepState(state: StateDef) {
    let deepState = state
    while (deepState[INITIAL]) {
        deepState = deepState[INITIAL]
    }
    return deepState
}


export function buildMachine<SD extends StateDef>(stateDef: SD): Machine<SD> {

    const events = gatherEvents(stateDef)

    const [currentState, setCurrentState] = createSignal(initialDeepState(stateDef))




    const machine: any = {
        currentState() {
            return currentState()[_STATE_PATH]
        },
        isInState(...s: StatePaths<SD>[]) {
            // @ts-ignore
            return s.some(s => currentState()[_STATE_PATH] === s || currentState()[_STATE_PATH].startsWith(`${s}.`))
        },
        hasTag(s: string) {
            let testState = currentState()
            while (testState) {
                if (testState[TAGS] === s || testState[TAGS]?.includes(s)) {
                    return true
                }
                testState = testState[_PARENT]
            }
            return false
        },
        canHandle(e: StateEvents<SD>) {
            let testState = currentState()
            while (testState) {
                const eventDef = testState[ON]?.[e as string]
                if (eventDef) {
                    return true
                }
                testState = testState[_PARENT]
            }
            return false
       }
    }



    for (const event of events) {
        machine[event] = (handler?: () => void) => {
            let testState = currentState()
            while (testState) {
                const eventDef = testState[ON]?.[event]
                if (eventDef) {
                    setCurrentState(initialDeepState(eventDef()))
                    try {
                        handler?.()
                    } catch (e) {
                        // do nothing
                    }
                    return true
                }
                testState = testState[_PARENT]
            }
            return false
        }
    }
    return machine as Machine<SD>
}
