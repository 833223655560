
import styles from './Spinner.module.css'
import {classList} from '@peachy/utility-kit-pure'
import {Modal, ModalProps} from '@peachy/client-kit'

export function Spinner(props: ModalProps) {
    const classes = classList(styles.Spinner)
    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} class={classes} blurBackground>
            <div></div>
            <div></div>
            <div></div>
        </Modal>
    )

}