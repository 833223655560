import ListSelector, { ListSelectorProps } from '../ListSelector/ListSelector'

import styles from './PopupListSelector.module.css'
import { Modal } from '../Modal/Modal'
import { classList } from '@peachy/utility-kit-pure'


export type PopupListSelectorProps<T> = ListSelectorProps<T> & {
    locatorElement?: HTMLElement
    center?: boolean
    appearance?: PopupListSelectorAppearance
}


export type PopupListSelectorAppearance = {
    modal?: string
    list?: string
}


export function PopupListSelector<T>(props: PopupListSelectorProps<T>) {

    const modalClasses = classList(styles.PopupListSelector, props.appearance?.modal)
    const listClasses = classList(styles.list, props.appearance?.list)

    return (
        <Modal isOpen={props.enabled} locatorElement={props.locatorElement} class={modalClasses} onDismiss={props.onDismiss} center={props.center ?? true}>
            <ListSelector
                list={props.list}
                selection={props.selection}
                onSelect={props.onSelect}
                enabled={props.enabled}
                optionTextFn={props.optionTextFn}
                optionComparisonValueFn={props.optionComparisonValueFn}
                appearance={{
                    list: listClasses,
                    listItem: props.appearance?.listItem,
                    selectedItem: props.appearance?.selectedItem
                }}
            />
        </Modal>
    )
}