export type EqualityFunction = (a: unknown, b: unknown) => boolean;

export const defaultEqualityFunction: EqualityFunction = (a, b) => a === b

export type IdentityFunction = (x: unknown) => unknown;

export const defaultIdentityFunction: IdentityFunction = (x) => x

export function lcs(
    seqA: any[],
    seqB: any[],
    getIdentity: IdentityFunction = defaultIdentityFunction
): [any[], number[][]] {
    const table = lcsTable(seqA, seqB, getIdentity)

    const lcs = []
    const indexes = []

    let ai = seqA.length
    let bi = seqB.length
    let countdown = table[ai][bi] - 1

    while (countdown >= 0) {
        const itemA = seqA[ai - 1]
        const itemB = seqB[bi - 1]

        if (getIdentity(itemA) === getIdentity(itemB)) {
            lcs[countdown] = itemA
            bi--
            ai--
            indexes[countdown--] = [ai, bi]
        } else {
            const lengthA = table[ai - 1][bi]
            const lengthB = table[ai][bi - 1]

            if (lengthA > lengthB) {
                ai--
            } else {
                bi--
            }
        }
    }

    return [lcs, indexes]
}

export function lcs3d(
    seqA: any[],
    seqB: any[],
    seqC: any[],
    getIdentity: IdentityFunction = defaultIdentityFunction

): [any[], number[][]] {
    const table = lcsTable3d(seqA, seqB, seqC, getIdentity)

    const lcs = []
    const indexes = []

    let ai = seqA.length
    let bi = seqB.length
    let ci = seqC.length
    let countdown = table[ai][bi][ci] - 1

    while (countdown >= 0) {
        const itemA = seqA[ai - 1]
        const itemB = seqB[bi - 1]
        const itemC = seqC[ci - 1]

        if (
            getIdentity(itemA) === getIdentity(itemB) &&
            getIdentity(itemA) === getIdentity(itemC)
        ) {
            lcs[countdown] = itemA
            ai--
            bi--
            ci--
            indexes[countdown--] = [ai, bi, ci]
        } else {

            const lengthA = table[ai - 1][bi][ci]
            const lengthB = table[ai][bi - 1][ci]
            const lengthC = table[ai][bi][ci - 1]

            if (lengthA < lengthB) {
                if (lengthB < lengthC) {
                    ci--
                } else {
                    bi--
                }
            } else {
                if (lengthA < lengthC) {
                    ci--
                } else {
                    ai--
                }
            }
        }
    }
    return [lcs, indexes]
}


export function lcsTable(
    seqA: any[],
    seqB: any,
    getIdentity: IdentityFunction = defaultIdentityFunction
) {
    const table: number[][] = [new Array(seqB.length + 1).fill(0)]
    seqA.forEach((_) => table.push([0]))

    let ai: number
    let bi: number

    for (ai = 1; ai <= seqA.length; ai++) {
        for (bi = 1; bi <= seqB.length; bi++) {
            const itemA = seqA[ai - 1]
            const itemB = seqB[bi - 1]

            if (getIdentity(itemA) === getIdentity(itemB)) {
                table[ai][bi] = 1 + table[ai - 1][bi - 1]
            } else {
                table[ai][bi] = Math.max(table[ai - 1][bi], table[ai][bi - 1])
            }
        }
    }
    return table
}

export function lcsTable3d(
    seqA: any[],
    seqB: any[],
    seqC: any[],
    getIdentity: IdentityFunction = defaultIdentityFunction
) {
    const table: number[][][] = createMatrix(
        seqA.length + 1, seqB.length + 1, seqC.length + 1,
        0
    )

    let ai: number
    let bi: number
    let ci: number

    for (ai = 1; ai <= seqA.length; ai++) {
        for (bi = 1; bi <= seqB.length; bi++) {
            for (ci = 1; ci <= seqC.length; ci++) {
                const itemA = seqA[ai - 1]
                const itemB = seqB[bi - 1]
                const itemC = seqC[ci - 1]

                if (
                    getIdentity(itemA) === getIdentity(itemB) &&
                    getIdentity(itemA) === getIdentity(itemC)
                ) {
                    table[ai][bi][ci] = 1 + table[ai - 1][bi - 1][ci - 1]
                } else {
                    table[ai][bi][ci] = Math.max(
                        table[ai - 1][bi][ci],
                        table[ai][bi - 1][ci],
                        table[ai][bi][ci - 1]
                    )
                }
            }
        }
    }
    return table
}

export function lcsBreakdown(a: any[], b: any[]) {
    const [_, lcsIndexes] = lcs(a, b)

    let i,
        j = 0

    const breakdown = []
    let lcsBuffer = []

    for (let [ai, bi] of lcsIndexes) {
        const sliceA = a.slice(i, ai)
        const sliceB = b.slice(j, bi)

        if (sliceA.length || sliceB.length) {
            breakdown.push([lcsBuffer])
            breakdown.push([sliceA, sliceB])
            lcsBuffer = []
        }
        [i, j] = [ai + 1, bi + 1]
        lcsBuffer.push(a[ai])
    }

    if (lcsBuffer.length) {
        breakdown.push([lcsBuffer])
    }
    return breakdown
}

export function lcsBreakdown3d(a: any[], b: any[], c: any[]) {
    const [_, lcsIndexes] = lcs3d(a, b, c)

    let i,
        j,
        k = 0
    const breakdown = []
    let lcsBuffer = []

    for (let [ai, bi, ci] of lcsIndexes) {
        const sliceA = a.slice(i, ai)
        const sliceB = b.slice(j, bi)
        const sliceC = c.slice(k, ci)

        if (sliceA.length || sliceB.length || sliceC.length) {
            breakdown.push([lcsBuffer])
            breakdown.push([sliceA, sliceB, sliceC])
            lcsBuffer = []
        }
        [i, j, k] = [ai + 1, bi + 1, ci + 1]
        lcsBuffer.push(a[ai])
    }

    if (lcsBuffer.length) {
        breakdown.push([lcsBuffer])
    }
    return breakdown
}




function createMatrix(xd: number,yd: number,zd: number, fill: number) {
    const matX: number[][][] = []
    for (let x = 0; x < xd; x++) {
        const matY: number[][] = []
        for (let y = 0; y < yd; y++) {
            const matZ: number[] = []
            for (let z = 0; z < zd; z++) {
                matZ.push(fill)
            }
            matY.push(matZ)
        }
        matX.push(matY)
    }
    return matX
}
