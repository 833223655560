import {Address, BenefitType, BenefitTypes} from '@peachy/repo-domain'
import {TerminologyItem} from '@peachy/nhs-pure'
import {InferredValue} from './ClaimChecklistModel'

export type InferredCoverCheckValues = Pick<InferredClaimAssessmentValues, 'inferredSubmissionReasons' | 'inferredRequestedTreatments'>
export type InferredClaimValues = Pick<InferredClaimAssessmentValues, 'inferredSubmissionReasons' | 'inferredLinkedClaimActivityIds' | 'inferredInvoiceLineItems' | 'inferredHospitalAdmissions'>

export type InferredClaimAssessmentValues = {
    inferredSubmissionReasons?: InferredClaimActivitySubmissionReason[]
    inferredRequestedTreatments?: InferredCoverCheckRequestedTreatment[]
    inferredLinkedClaimActivityIds?: InferredValue<string>[]
    inferredInvoiceLineItems?: InferredClaimInvoiceLineItem[]
    inferredHospitalAdmissions?: InferredHospitalAdmission[]
}

export type InferredClaimActivitySubmissionReason = {
    id: string
    symptoms: InferredValue<TerminologyItem>[]
    disorder?: InferredValue<TerminologyItem>
    onsetDate: InferredValue<Date>
}

export type InferredCoverCheckRequestedTreatment = {
    id: string
    procedure: InferredValue<TerminologyItem>
    reasonId: InferredValue<string>
    benefitType: InferredValue<BenefitType>
    approved?: InferredValue<boolean>
}

export type InferredClaimInvoiceLineItem = {
    id: string
    procedure: InferredValue<TerminologyItem>
    treatmentAddress?: InferredValue<Partial<Address>>
    treatmentDate: InferredValue<Date>
    treatmentPaymentDate: InferredValue<Date>
    benefitType: InferredValue<BenefitType>
    hospitalAdmissionId?: InferredValue<string>
    invoiceAmountInPence: InferredValue<number>
    eligibleAmountInPence: InferredValue<number>
    reasonId: InferredValue<string>
}

export type InferredHospitalAdmission = {
    id: string
    admissionDate: InferredValue<Date>
    dischargeDate: InferredValue<Date>
}

export function isClaim(inferredValues: InferredClaimAssessmentValues): boolean {
    return !!inferredValues.inferredLinkedClaimActivityIds
}

export function getInferredBenefits(inferredValues: InferredClaimAssessmentValues): InferredValue<BenefitType>[] {
    const inferredBenefits: InferredValue<BenefitType>[] = []
    const pushIfNotIncluded = (benefit: InferredValue<BenefitType>) => {
        if (!!benefit && !inferredBenefits.some(it => it.value == benefit.value)) {
            inferredBenefits.push(benefit)
        }
    }
    if (isClaim(inferredValues)) {
        inferredValues.inferredInvoiceLineItems.map(it => it.benefitType).forEach(it => pushIfNotIncluded(it))
    } else {
        inferredValues.inferredRequestedTreatments.map(it => it.benefitType).forEach(it => pushIfNotIncluded(it))
    }
    return inferredBenefits
}