import { Optional } from '@peachy/utility-kit-pure'
import { splitProps, JSX, For } from 'solid-js'

type SelectProps<T> = SelectCommonProps<T> & {
    selected?: string
    onChange?: (selected?: T) => void
}
export function Select<T>(props: SelectProps<T>) {
    const onChange = (selected: T) => {
        props.onChange?.(selected)
    }
    return <SelectSingleOrMultiple {...props} onChange={onChange}/>
}

type SelectMultipleProps<T> = SelectCommonProps<T> & {
    selected?: string[]
    onChange?: (selected?: T[]) => void
}
export function SelectMultiple<T>(props: SelectMultipleProps<T>) {
    const onChange = (_: T, selected: T[]) => {
        props.onChange?.(selected)
    }
    return <SelectSingleOrMultiple {...props} multiple onChange={onChange}/>
}

type SpecialOption = {text: string, value?: string}
type SelectCommonProps<T> = Omit<JSX.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'| 'multiple'> & {
    options: T[]
    optionValueFn?: (option: T) => string 
    optionTextFn?: (option: T) => string
    pleaseChoose?: string
}
type SelectSingleAndMultipleProps<T> = SelectCommonProps<T> & {
    selected?: string | string[]
    onChange?: (selected: Optional<T>, multiSelected?: T[]) => void
    multiple?: boolean
}
function SelectSingleOrMultiple<T>(allProps: SelectSingleAndMultipleProps<T>) {
    const [props, passThroughProps] = splitProps(allProps, [
        'options',
        'selected',
        'optionTextFn', 
        'optionValueFn', 
        'onChange',
        'pleaseChoose'
    ])

    const pleaseChoose: SpecialOption = props.pleaseChoose ? {text: props.pleaseChoose, [Symbol.for('specialOption')]: true} : undefined
    const allOptions = () => [pleaseChoose, ...props.options].filter(it => !!it)

    const isSpecialOption = (it: any): it is SpecialOption => it?.[Symbol.for('specialOption')]
    
    const valueFn = (it: T | SpecialOption) => isSpecialOption(it) ? it.value : props.optionValueFn ? props.optionValueFn(it) : String(it)
    const textFn = (it: T | SpecialOption) => isSpecialOption(it) ? it.text : props.optionTextFn ? props.optionTextFn(it) : valueFn(it)
    const isSelectedFn = (option: T | SpecialOption) => option && props.selected && [props.selected].flat().includes(valueFn(option))

    const onChange = (e: Event) => {
        const selectedValues = Array.from(e.currentTarget.selectedOptions).map((option) => option.value)
        const selectedOptions = props.options.filter(it => selectedValues.includes(valueFn(it)))
        if (passThroughProps.multiple) {
            props?.onChange?.(selectedOptions[0], selectedOptions)
        } else {
            props?.onChange?.(selectedOptions[0])
        }
    }
    
    return <select {...passThroughProps} onChange={onChange}>
        <For each={allOptions()}>{ option => 
            <option 
                value={valueFn(option)} 
                selected={isSelectedFn(option)}>
                {textFn(option)}
            </option>
        }</For>
    </select>
}

