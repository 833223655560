import {classList, Draft} from '@peachy/utility-kit-pure'
import {GenderType, isValidProperty, Life, validate} from '@peachy/core-domain-pure'
import {createSignal, Show} from 'solid-js'
import styles from './LifeRow.module.css'
import {AddressLookup, DateBox, TextBox} from '@peachy/client-kit'
import {addYears} from "date-fns";
import {PeachyDatePicker} from "../../../../../components/DatePicker/PeachyDatePicker";


export type LifeRowProps = {
    life: Draft<Life>
    isOnlyLife: boolean
    updateLife: (update: Draft<Life>) => void
    removeLife: () => void,
}

export function LifeRow(props: LifeRowProps) {

    const oldestLife = addYears(new Date(), -56)
    const youngestLife = addYears(new Date(), -18)
    const defaultBirthday = addYears(new Date(), -30)

    const [showDatePicker, setShowDatePicker] = createSignal(false)

    const life = props.life
    const updateLife = props.updateLife
    const removeLife = props.removeLife

    const isValid = () => !validate(life, null, Life)

    const fieldValidationClass = (field: keyof Draft<Life>) => {
        return isValidProperty(life, field, null, Life)
            ? styles.validField
            : styles.invalidField
    }

    const validMarkClasses = () => classList('fa-solid fa-check', styles.validationMark, isValid() && styles.isValid)

    const dateFieldClasses = () => classList(styles.dateField, fieldValidationClass('dateOfBirth'))


    return (
        <tr>
            <td>
                <i class={validMarkClasses()}/>
            </td>
            <td>
                <TextBox placeholder={'First name'} value={life.firstname ?? ''}
                         onChange={firstname => updateLife({firstname})}
                         class={fieldValidationClass('firstname')}
                />
            </td>
            <td>
                <TextBox placeholder={'Last name'} value={life.lastname ?? ''}
                         onChange={lastname => updateLife({lastname})}
                         class={fieldValidationClass('lastname')}
                />
            </td>
            <td>
                <TextBox placeholder={'Email'} value={life.email ?? ''}
                         onChange={email => updateLife({email})}
                         class={fieldValidationClass('email')}
                />

            </td>
            <td>
                <AddressLookup initialSearchTerm={life.address?.display ?? ''}
                               onSelectAddress={address => updateLife({address, postcode: address.postcode})}
                               appearance={{
                                   lookupField: fieldValidationClass('address'),
                                   list: styles.addressList,
                                   listItem: styles.addressItem,
                                   selectedItem: styles.selectedAddress
                                }}
                />

            </td>
            <td>
                <DateBox placeholder={'DOB dd/mm/yyyy'}
                         selectedDate={life.dateOfBirth}
                         onDateEntered={dateOfBirth => updateLife({dateOfBirth})}
                         class={dateFieldClasses()}
                >
                    <i onClick={() => setShowDatePicker(true)} class={'fa-light fa-calendar'}/>
                </DateBox>
                <PeachyDatePicker
                    isOpen={showDatePicker()}
                    onDismiss={() => setShowDatePicker(false)}
                    onSelect={dateOfBirth => updateLife({dateOfBirth: dateOfBirth.getTime()})}
                    minDate={oldestLife}
                    maxDate={youngestLife}
                    selectedDate={defaultBirthday}
                    headerText={'Birthdate'}
                    submitText={'Done'}
                />
            </td>
            <td>
                <Show when={!props.isOnlyLife}>
                    <button tabindex={-1} onClick={removeLife}><i class={'fa-solid fa-xmark'}/></button>
                </Show>
            </td>
        </tr>
    )
}


type GenderBoxProps = {
    onChange: (gender: GenderType) => void
    value: GenderType
    class?: string
}

function GenderBox(props: GenderBoxProps) {

    const [fieldValue, setFieldValue] = createSignal(props.value ?? '')

    const onChange = (value: string) => {
        const key = value[0]
        setFieldValue(key)
        requestAnimationFrame(() => {
            if (['m', 'M'].includes(key)) {
                setFieldValue('MALE')
                props.onChange('MALE')
            } else if (['f', 'F'].includes(key)) {
                setFieldValue('FEMALE')
                props.onChange('FEMALE')
            } else if (['o', 'O', 'b', 'B'].includes(key)) {
                setFieldValue('BLENDED')
                props.onChange('BLENDED')
            } else {
                setFieldValue('')
                props.onChange(null)
            }
        })
    }

    return <TextBox placeholder={'Gender f/m/o'} value={fieldValue()}
                    onChange={onChange}
                    class={props.class}
    />
}





